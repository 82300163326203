import { ChangeEvent, useState } from 'react';
import { Modal, PrimaryButton, TextField } from 'components/Common';
import { getFilterColumn } from 'services/api/api';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';

import { IHeaderList, ITransactionTableFilterModal } from '../Transaction.type';

const TransactionTableFilterModal = ({
    setIsOpen,
    className,
    tableFilterColumn,
}: ITransactionTableFilterModal) => {
    const { dispatch } = useAuthContext();
    const [modifiedTableFilterColumn, setModifiedTableFilterColumn] =
        useState<IHeaderList[]>(tableFilterColumn);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        // Update the modified state based on the checkbox change
        setModifiedTableFilterColumn((prevValues: IHeaderList[]) => {
            let updatedValues = prevValues.map((value: IHeaderList) =>
                value.key === event.target.name
                    ? { ...value, isChecked: event.target.checked }
                    : value
            );

            if (event.target.name === 'all') {
                updatedValues = updatedValues.map((value) => ({
                    ...value,
                    isChecked:
                        value.key !== 'all'
                            ? event.target.checked
                            : value.isChecked,
                }));
            } else {
                // Update 'All' checkbox state based on other checkboxes
                const allCheckbox = updatedValues.find(
                    (value) => value.key === 'all'
                );
                if (allCheckbox) {
                    allCheckbox.isChecked = !updatedValues.find(
                        (value) => value.key !== 'all' && !value.isChecked
                    );
                }
            }

            return updatedValues;
        });
    };

    const handleSaveButtonClick = () => {
        // Update the original state with the modified state
        const checkedItemsKeys = modifiedTableFilterColumn
            .filter((item) => item.isChecked)
            .map((item) => item.key);

        getFilterColumn({ filteredColumn: checkedItemsKeys })
            .then((result) =>
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                })
            )
            .catch((err) => console.log(err));
        // Close the modal or perform any other actions
        setIsOpen(false);
    };

    return (
        <Modal
            icon
            setIsOpen={setIsOpen}
            classname={className}
            title="Column Filter List"
        >
            <>
                <div className="mt-5 grid grid-cols-4 gap-5">
                    {modifiedTableFilterColumn.map((value) => (
                        <div key={value.key} className="flex">
                            <TextField
                                isCheckbox
                                checked={value.isChecked}
                                type="checkbox"
                                name={value.key}
                                onChange={(event) =>
                                    handleCheckboxChange(event)
                                }
                            />
                            <div>{value.value}</div>
                        </div>
                    ))}
                </div>
                <div className="mt-4 text-center">
                    <PrimaryButton
                        type="button"
                        name="Save"
                        color="#2E672F"
                        variant="filled"
                        isDrawerButton
                        className="items-center p-4 font-medium"
                        onClick={() => handleSaveButtonClick()}
                    />
                </div>
            </>
        </Modal>
    );
};
export default TransactionTableFilterModal;
