import { useEffect, useState } from 'react';
import { getGroupData } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGroup } from 'data/common';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { useAuthContext } from 'context/Auth/AuthContext';
import { GroupsHeader } from './GroupsHeader';
import { GroupsTable } from './GroupsTable';
import { AddGroupsDrawer } from './GroupsDrawer/AddGroupDrawer';
import { EditGroupsDrawer } from './GroupsDrawer/EditGroupDrawer';

const Groups = () => {
    const [allGroups, setAllGroups] = useState<IGroup[]>([]);
    const [userCompany, setUserCompany] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [checkedGroups, setCheckedGroups] = useState<string[]>([]);
    const [editGroupDetails, setEditGroupDetails] = useState<IGroup | null>(
        null
    );
    const [isEditGroupDrawer, setIsEditGroupDrawer] = useState<boolean>(false);
    const [loading, setIsLoading] = useState<boolean>(false);
    const { auth } = useAuthContext();

    const getGroups = () => {
        setIsLoading(true);
        getGroupData({
            companyId: auth?.selectedCompany?._id,
            brandId: auth?.selectedBrand?._id,
        })
            .then((res) => {
                setAllGroups(res?.groups);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getGroups();
    }, [auth?.selectedBrand, auth?.selectedCompany]);

    const openDrawer = () => {
        setIsEditGroupDrawer(false);
        setEditGroupDetails(null);

        if (isOpen) {
            setUserCompany('');
        }
        setIsOpen(!isOpen);
    };

    return (
        <div className="px-8 py-5">
            <GroupsHeader
                checkedGroups={checkedGroups}
                getGroups={getGroups}
                setCheckedGroups={setCheckedGroups}
                openDrawer={openDrawer}
            />
            {loading ? (
                <LoadingSpinner />
            ) : (
                <GroupsTable
                    allGroups={allGroups}
                    setCheckedGroups={setCheckedGroups}
                    checkedGroups={checkedGroups}
                    setAllGroups={setAllGroups}
                    setEditGroupDetails={setEditGroupDetails}
                    setIsEditGroupDrawer={setIsEditGroupDrawer}
                    setIsOpen={setIsOpen}
                    setUserCompany={setUserCompany}
                />
            )}
            {isEditGroupDrawer ? (
                <EditGroupsDrawer
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    loading={loading}
                    userCompany={userCompany}
                    getGroups={getGroups}
                    setIsOpen={setIsOpen}
                    isEditGroupDrawer={isEditGroupDrawer}
                    editGroup={editGroupDetails}
                />
            ) : (
                <AddGroupsDrawer
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    loading={loading}
                    userCompany={userCompany}
                    getGroups={getGroups}
                    setIsOpen={setIsOpen}
                />
            )}
        </div>
    );
};
export default Groups;
