import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import classNames from 'classnames';
import OtpInput from 'react-otp-input';
import { z, ZodType } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation } from 'react-router';
import { PrimaryButton, SvgIcon, TextField } from 'components/Common';
import { LoginFormData } from 'data/types/auth';
import { signIn, verifyOtp } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IVerifyOTP } from 'data/types/request';
import { AuthenticationModal } from 'components/Modal';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import RFTextField from 'components/Common/RFTextField/RFTextField';

export const loginValidation: ZodType<LoginFormData> = z
    .object({
        email: z.string().email({ message: 'Email is required' }),
        password: z.string({ required_error: 'Password is required' }),
    })
    .required();

const LoginForm = () => {
    const navigate = useNavigate();
    const { dispatch } = useAuthContext();
    const [open, setOpen] = useState<boolean>(false);
    const [qrCode, setQrCode] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const location = useLocation();
    const redirectPath = location.state?.path || '/payment';
    const digitLeftCount = 6 - otp.length;

    const { control, handleSubmit, reset } = useForm<LoginFormData>({
        resolver: zodResolver(loginValidation),
        defaultValues: {},
    });

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload = {
            email: formData?.email,
            password: formData?.password,
        };
        signIn(payload)
            .then((result) => {
                localStorage.setItem('verifiedToken', result.token);
                // For First time login
                setQrCode(result?.qr as string);
                reset({});
                setOpen(true);
                showToast('Successfully', 'success');
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    });

    const onOTPVerify = () => {
        if (!otp) {
            setOtpError('Please Enter your OTP');
            return;
        }
        if (otp.length < 6) {
            setOtpError('Enter 6 digit OTP');
            return;
        }
        const payload: IVerifyOTP = { code: otp };

        verifyOtp(payload)
            .then((result) => {
                if (result?.token) {
                    localStorage.setItem('token', result?.token);
                    localStorage.removeItem('verifiedToken');
                }
                if (result?.qr) {
                    setQrCode(result?.qr);
                } else {
                    setQrCode('');
                }
                dispatch({
                    type: AppActionsEnum.SET_CURRENT_USER,
                    payload: {
                        permissions: result?.permissions || [],
                        brandPermissions: result?.brandPermissions || [],
                        authUser: result.user,
                        isLoggedIn: true,
                        role: result.user?.role,
                    },
                });
                setOtpError('');
                showToast('successfully verified', 'success');
                setOpen(false);
                navigate(redirectPath, { replace: true });
            })
            .catch((err) => {
                showToast(err.message, 'error');
            });
    };

    return (
        <div className="mx-auto flex flex-col items-start justify-center w-[500px] max-[900px]:w-[300px]">
            {qrCode ? (
                <div className="flex flex-col items-center gap-5">
                    <div>
                        <SvgIcon
                            className="mb-11 flex flex-col items-center"
                            icon="GOOGLE_LOGO"
                        />
                        <div className="mt-1 flex justify-center text-xl font-extrabold text-green-700">
                            Two-Factor Authentication - A Breeze!
                        </div>
                        <div>
                            Download the free{' '}
                            <span className="font-extrabold text-green-200">
                                Google Authenticator{' '}
                            </span>{' '}
                            App, add a new account, then scan the Barcode to set
                            up your account.
                        </div>
                    </div>
                    <img src={qrCode} alt="code" height="50%" width="50%" />
                    <OtpInput
                        value={otp}
                        onChange={(value: string) => setOtp(value)}
                        numInputs={6}
                        renderSeparator={
                            <span style={{ marginLeft: '13px' }} />
                        }
                        renderInput={(props: any) => (
                            <input
                                id="inputOtp"
                                {...props}
                                style={{
                                    width: '53px',
                                    height: '55px',
                                    color: 'text-green-600',
                                    background: '#F7F7F7',
                                    border:
                                        otpError && otp.length < 6
                                            ? '1px solid red'
                                            : otp
                                            ? props.value
                                                ? '2px solid #9ED79F'
                                                : 'none'
                                            : 'none',
                                    borderRadius: 15,
                                    fontSize: '30px',
                                    fontWeight: 500,
                                    textAlign: 'center',
                                    outline: props.value
                                        ? '3px solid #9ED79F'
                                        : '',
                                }}
                            />
                        )}
                    />
                    <PrimaryButton
                        onClick={onOTPVerify}
                        type="submit"
                        name={
                            digitLeftCount === 0
                                ? `Let's go`
                                : `${digitLeftCount} Digits Left`
                        }
                        color="#2E672F"
                        variant="filled"
                        className={classNames(
                            'ml-5 mt-5 flex justify-center items-center w-[88%] h-10 rounded-xl bg-zinc-300 text-base font-bold text-gray-400',
                            {
                                'bg-green-700 text-white': digitLeftCount === 0,
                            }
                        )}
                    />
                </div>
            ) : (
                <>
                    <div className="flex w-full items-center font-extrabold text-green-100 text-[32px]">
                        Unlock the Possibilities of Your Business with Mozarto!
                    </div>
                    <div className="font-normal text-[16px]">
                        Enter your credentials below to get started
                    </div>
                    <form onSubmit={onSubmit} className="w-full">
                        <div className="flex flex-col pt-12 w-[100%]">
                            <RFTextField
                                control={control}
                                name="email"
                                fullWidth
                                label="Enter Email"
                                type="email"
                                asterisk
                            />
                            <div className="mt-2">
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            asterisk
                                            className={classNames(
                                                'text-gray-400',
                                                !fieldState.error?.message
                                                    ? 'text-gray-400 bg-gray-200'
                                                    : 'bg-red-300 border-0 '
                                            )}
                                            isError={
                                                !!fieldState.error?.message
                                            }
                                            errorMessage={
                                                fieldState.error?.message ?? ''
                                            }
                                            iconPosition="right"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="mt-0 flex items-center justify-end text-[12px]">
                                Forgot password{' '}
                                <Link
                                    to="/forgot-password"
                                    className="border-b-0 pl-1 font-extrabold text-green-200"
                                >
                                    Request here
                                </Link>
                            </div>

                            <PrimaryButton
                                type="submit"
                                name="Submit"
                                color="#2E672F"
                                variant="filled"
                                className="hover:bg-[#2E672F] hover:border-green-600 focus:border-green-600 mt-10 w-full font-medium"
                            />
                            <div className="flex items-center justify-start pt-3 text-[12px]">
                                Don’t have an account?{' '}
                                <a className="pl-1 font-extrabold text-green-200">
                                    Contact us
                                </a>
                            </div>
                        </div>
                    </form>
                    {open && (
                        <AuthenticationModal
                            setIsOpen={setOpen}
                            handleVerifyOTP={onOTPVerify}
                            setOtp={setOtp}
                            otp={otp}
                            otpError={otpError}
                        />
                    )}
                </>
            )}
        </div>
    );
};
export default LoginForm;
