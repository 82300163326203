import React, { ChangeEvent, useState } from 'react';
import { Select } from 'components/Common';
import {
    PaymentProviderConstant,
    PaymentProvider,
} from 'data/constants/payment.constants';

import Trust from '../Merchant/Trust';
import Cleo from '../Merchant/Cleo';
import Paymodum from '../Merchant/Paymodum';

const Merchant = () => {
    const [transactionMethod, setTransactionMethod] = useState<string>(
        PaymentProviderConstant.TRUST
    );

    return (
        <div className="py-14 px-8 flex justify-center">
            <div className="w-full">
                <div className="flex justify-between">
                    <div className="font-extrabold text-2xl text-[#131119] self-center">
                        Merchants
                    </div>
                    <div className="flex items-center gap-1">
                        {' '}
                        <div className="font-bold text-sm">
                            Select Payment Method :
                        </div>
                        <div className="w-[328px]">
                            <Select
                                isGroup
                                bordered
                                isCompanyAdmin
                                value={transactionMethod}
                                options={PaymentProvider}
                                handleOnSelect={(
                                    e: ChangeEvent<HTMLSelectElement>
                                ) => {
                                    setTransactionMethod(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="pt-8">
                    {transactionMethod === PaymentProviderConstant.TRUST && (
                        <Trust />
                    )}
                    {transactionMethod === PaymentProviderConstant.CLEO && (
                        <Cleo />
                    )}
                    {transactionMethod === PaymentProviderConstant.PAYMODUM && (
                        <Paymodum />
                    )}
                </div>
            </div>
        </div>
    );
};
export default Merchant;
