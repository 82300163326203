import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { Layout } from 'components/Common';
import Merchant from 'components/Payment/Merchant';
import MerchantPayout from 'components/Payout/MerchantPayout';

const Cashier = () => {
    const location = useLocation();
    const payment = useCallback(() => {
        switch (location.pathname) {
            case '/cashier/merchant-payin':
                return <Merchant />;
            case '/cashier/merchant-payout':
                return <MerchantPayout />;
            default:
                return <Merchant />;
        }
    }, [location.pathname]);
    return <Layout>{payment()}</Layout>;
};
export default Cashier;
