import React from 'react';
import { useController, Control } from 'react-hook-form';
import classNames from 'classnames';
import TextField, { TextFieldProps } from '../TextField/input';

export type RFTextFieldProps = TextFieldProps & {
    control: Control<any, any>;
    name: string;
    onChange?: (value: string) => void;
    placeholder?: string;
};

const RFTextField: React.FunctionComponent<RFTextFieldProps> = ({
    control,
    name,
    onChange,
    placeholder,
    ...rest
}) => {
    const {
        field: { onChange: onChangeRF, onBlur, value },
        fieldState,
    } = useController({
        name,
        control,
    });

    return (
        <TextField
            name={name}
            value={value ?? ''}
            onChange={(event) => {
                onChangeRF?.(event);
                onChange?.(event);
            }}
            placeholder={placeholder ?? ''}
            onBlur={onBlur}
            className={classNames(
                'text-gray-400',
                !fieldState.error?.message
                    ? 'text-gray-400 bg-gray-200'
                    : 'bg-red-300 border-0 '
            )}
            isError={!!fieldState.error?.message}
            errorMessage={fieldState.error?.message ?? ''}
            {...rest}
        />
    );
};

export default RFTextField;
