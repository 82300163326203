import { Navigate, Route, Routes } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';
import './App.css';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from './context/Auth/AuthContext';
import { SuperAdminRoute } from './routes/SuperAdminRoutes';
import { UserRoute } from './routes/UserRoute';
import { UserRoles } from './data/constants/auth.constants';
import Login from './pages/auth/Login';
import ValidateSignupToken from './pages/auth/ValidateSignupToken';
import ValidateResetToken from './pages/auth/ValidateResetToken';
import ForgotPasswordPage from './pages/auth/ForgotPassword';
import ResetPasswordPage from './pages/auth/ResetPassword';
import NewAdminPassword from './pages/auth/NewAdminPassword';
import { Loader } from './components/Common';
import 'react-toastify/dist/ReactToastify.css';
import { AdminRoutes } from './routes/AdminRoutes';

function App(): JSX.Element {
    const { auth } = useAuthContext();
    const [routes, setRoutes] = useState<JSX.Element>();

    const updateRoutesBasedOnUserState = (
        isUserLoggedIn: boolean | null,
        userRole: UserRoles | null
    ) => {
        // While we fetch user login state on firebase load at refresh, show loading
        if (isUserLoggedIn === null) {
            return (
                <div className="flex items-center justify-center w-[100vw] h-[100vh]">
                    {' '}
                    <Loader />
                </div>
            );
        }

        if (isUserLoggedIn) {
            if (userRole === UserRoles.SUPER_ADMIN) {
                return <SuperAdminRoute />;
            }
            if (userRole === UserRoles.ADMIN) {
                return <AdminRoutes />;
            }
            if (userRole === UserRoles.USER) {
                return <UserRoute />;
            }
        } else
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />{' '}
                    <Route path="/" element={<Login />} />
                    <Route
                        path="/validate-signup-token"
                        element={<ValidateSignupToken />}
                    />
                    <Route
                        path="/validate-reset-token"
                        element={<ValidateResetToken />}
                    />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPasswordPage />}
                    />
                    <Route
                        path="/reset-password"
                        element={<ResetPasswordPage />}
                    />
                    <Route
                        path="/create-new-password"
                        element={<NewAdminPassword />}
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            );
    };

    useEffect(() => {
        setRoutes(updateRoutesBasedOnUserState(auth?.isLoggedIn, auth?.role));
    }, [auth?.isLoggedIn, auth?.role]);

    return (
        <>
            {routes}
            <ToastContainer
                style={{
                    background: 'transparent',
                    marginLeft: '130px',
                }}
                toastStyle={{
                    padding: '20px',
                }}
                position="top-center"
                transition={Bounce}
                limit={3}
            />
        </>
    );
}

export default App;
