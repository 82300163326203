import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import './table.css';

export interface IColumnType<T> {
    key: string;
    title?: string | JSX.Element;
    width?: string | number;
    render?: (column: IColumnType<T>, item: T) => void;
}

interface Props<T> {
    data: T[];
    columns: any;
    className?: string;
    isSidebarTable?: boolean;
    isAuditHistoryTable?: boolean;
    isTransactionDetailsTable?: boolean;

    handleRowClick?: (item: any, isOpen: boolean) => void;
    isAdminUserModal?: boolean;
    colSpan: number;
}
export default function Table<T>({
    data,
    columns,
    className,
    isAdminUserModal,
    isSidebarTable,
    isAuditHistoryTable,
    isTransactionDetailsTable,
    handleRowClick,
    colSpan,
}: Props<T>): JSX.Element {
    return (
        <table
            className={`${isSidebarTable ? '' : 'tableSection'} ${
                className as string
            }`}
        >
            <thead className="sticky top-0 z-10">
                <TableHeader
                    isAuditHistoryTable={isAuditHistoryTable}
                    isSidebarTable={isSidebarTable}
                    columns={columns}
                />
            </thead>
            <tbody className="relative">
                <TableRow
                    isAdminUserModal={isAdminUserModal}
                    handleRowClick={(e) => {
                        if (handleRowClick) {
                            handleRowClick(e, true);
                        }
                    }}
                    isTransactionDetailsTable={isTransactionDetailsTable}
                    isAuditHistoryTable={isAuditHistoryTable}
                    isSidebarTable={isSidebarTable}
                    data={data}
                    columns={columns}
                    colSpan={colSpan}
                />
            </tbody>
        </table>
    );
}
