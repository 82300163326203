import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Modal, PrimaryButton, StatusBadge } from 'components/Common';
import { ModalConstants } from 'data/constants/modal.constants';
import { formatNumber, getDateFormat } from 'data/utils/common';
import { getTransactionStatus } from 'data/utils/transactions';

import RawResponse from '../../Payment/RawResponse';
import VerticalTable from '../../VerticalTable/VerticalTable';
import { getTransactionTabData } from './transactionFields';
import { ActionModal } from '../index';
import '../../AuditDrawer/AudtiDrawer.css';

interface ActionModalProps {
    setIsOpen: Dispatch<SetStateAction<boolean>>;

    isTransactionModal?: boolean;
    amount?: string;
    transaction?: any;
}

const TransactionDetailModal = ({
    transaction,
    setIsOpen,
    isTransactionModal,
}: ActionModalProps) => {
    const [openActionModal, setOpenActionModal] = useState(false);
    const [selectedTransactionTab, setSelectedTransactionTab] =
        useState<string>('Payment');

    const data = useMemo(() => {
        return getTransactionTabData(selectedTransactionTab, transaction);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction, isTransactionModal, selectedTransactionTab]);

    const getTransaction = () => {
        switch (transaction?.pspType) {
            case 'CLEO':
                return {
                    id: (transaction._id || '-') as string,
                    type:
                        transaction.method === 'CLEO_PAYOUT'
                            ? 'Withdrawal'
                            : 'Deposit',
                    status: getTransactionStatus(transaction),
                    acceptedBy: transaction.pspType,
                    amount: transaction.amount
                        ? `${formatNumber(Number(transaction.amount))} ${
                              (transaction?.currencyiso3a as string) || 'CLP'
                          }`
                        : '-',
                    applicationName: transaction?.firstName
                        ? (transaction?.firstName.concat(
                              ` ${transaction?.lastName as string}`
                          ) as string)
                        : (transaction.fullName as string),
                };
            case 'TRUST':
                return {
                    id: transaction._id as string,
                    type: 'CARD',
                    status: getTransactionStatus(transaction),
                    acceptedBy: transaction.pspType,
                    amount: transaction.baseamount
                        ? `${formatNumber(Number(transaction.baseamount))} ${
                              transaction?.currencyiso3a as string
                          }`
                        : '-',
                    applicationName:
                        (transaction?.userInfo?.firstName.concat(
                            ` ${transaction?.userInfo?.lastName as string}`
                        ) as string) || '-',
                };
            case 'PAYMODUM':
                return {
                    id: transaction._id as string,
                    type: 'CARD',
                    status: getTransactionStatus(transaction),
                    acceptedBy: transaction.pspType,
                    amount: transaction.baseamount
                        ? `${formatNumber(Number(transaction.baseamount))} ${
                              transaction?.transactionBaseCurrencyConverted as string
                          }`
                        : '-',
                    applicationName:
                        (transaction?.userInfo?.firstName.concat(
                            ` ${transaction?.userInfo?.lastName as string}`
                        ) as string) || '-',
                };
            default:
                return '-';
        }
    };

    const transactionFields: any = getTransaction();

    return (
        <Modal
            classname={`${
                isTransactionModal
                    ? '!p-0 min-[1200px]:!w-[1200px] min-w-[600px] min-[1200px]:max-h-[700px] max-h-[600px] overflow-scroll transactionModal'
                    : ''
            }`}
            isTransactionModal={isTransactionModal}
            title="Transaction Details"
            setIsOpen={setIsOpen}
            headerButton={
                <div className="flex gap-[30px]">
                    {/* <PrimaryButton
                        className="!h-[40px]"
                        // onClick={() => {
                        //     setOpenActionModal(true);
                        // }}
                        isDrawerButton
                        color="#2E672F"
                        variant="filled"
                        name="Manual Update"
                    />{' '}
                        <PrimaryButton
                        onClick={() => {}}
                        isDrawerButton
                        className="!h-[40px]"
                        color="#8FB131"
                        variant="filled"
                        name="Export"
                        /> */}
                    {openActionModal && (
                        <ActionModal
                            className="actionModal"
                            setIsOpen={setOpenActionModal}
                        />
                    )}
                </div>
            }
            icon
        >
            <div>
                <div className="flex flex-col bg-[#FAFAF5]	p-9   ">
                    <div className="flex items-start !text-start text-[#131119] font-black tracking-tight text-lg">
                        Transaction # {transactionFields?.id}
                    </div>

                    <div className="flex flex-wrap items-center gap-x-2 w-[700px]">
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="!font-black">Transaction Type:</p>
                            <p className="bg-[#73C5FF] p-2 rounded-lg !font-black">
                                <div>{transactionFields?.type}</div>
                            </p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="!font-black">Transaction Status:</p>
                            <StatusBadge
                                status={getTransactionStatus(transaction)}
                            />
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="!font-black">Accepted By:</p>
                            <p className="bg-[#FFF0BB] p-2 rounded-lg !font-black">
                                {transactionFields?.acceptedBy}
                            </p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="!font-black">Amount:</p>
                            <p>{transactionFields?.amount}</p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="!font-black">Application Name:</p>
                            <p>{transactionFields?.applicationName}</p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="!font-black">Application Key:</p>
                            <p>{transaction.externalKey}</p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="!font-black">Created:</p>
                            <p>
                                {getDateFormat(
                                    transaction?.pspType !== 'TRUST'
                                        ? ((transaction?.createdAt ||
                                              transaction?.updateAt ||
                                              transaction?.created) as string)
                                        : transaction?.createdAt ||
                                              transaction?.updatedAt ||
                                              transaction?.created
                                )}
                            </p>
                        </div>{' '}
                        <div className="flex items-center gap-x-2 gap-y-1">
                            <p className="!font-black">Modified:</p>
                            <p>
                                {getDateFormat(
                                    transaction?.pspType !== 'TRUST'
                                        ? ((transaction?.updateAt ||
                                              transaction?.createdAt ||
                                              transaction?.created) as string)
                                        : transaction?.updatedAt ||
                                              transaction?.createdAt ||
                                              transaction?.created
                                )}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="p-9 pt-2">
                    <div className="flex gap-x-5 p-7 pl-0">
                        <PrimaryButton
                            onClick={(e: any) => {
                                setSelectedTransactionTab(
                                    (e.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl',
                                selectedTransactionTab === 'Payment'
                                    ? ' !bg-[#DEECAA] px-8 !font-black !text-black'
                                    : ' !bg-transparent px-8 !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.PAYMENT}
                        />
                        <PrimaryButton
                            onClick={(e: any) => {
                                setSelectedTransactionTab(
                                    (e.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl',
                                selectedTransactionTab === 'User Information'
                                    ? ' !bg-[#DEECAA]  !font-black !text-black'
                                    : ' !bg-transparent  !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.USER_INFORMATION}
                        />{' '}
                        <PrimaryButton
                            onClick={(e: any) => {
                                setSelectedTransactionTab(
                                    (e.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl',
                                selectedTransactionTab ===
                                    'Transaction Information'
                                    ? ' !bg-[#DEECAA]  !font-black !text-black'
                                    : ' !bg-transparent  !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.TRANSACTION_INFORMATION}
                        />{' '}
                        <PrimaryButton
                            onClick={(e: any) => {
                                setSelectedTransactionTab(
                                    (e.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl',
                                selectedTransactionTab === 'KYC Status'
                                    ? ' !bg-[#DEECAA]  !font-black !text-black'
                                    : ' !bg-transparent  !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.KYC_STATUS}
                        />{' '}
                        <PrimaryButton
                            onClick={(e: any) => {
                                setSelectedTransactionTab(
                                    (e.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl',
                                selectedTransactionTab === 'Other'
                                    ? ' !bg-[#DEECAA] !font-black !text-black'
                                    : ' !bg-transparent !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.OTHER}
                        />
                        <PrimaryButton
                            onClick={(e: any) => {
                                setSelectedTransactionTab(
                                    (e.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl',
                                selectedTransactionTab === 'Financial'
                                    ? ' !bg-[#DEECAA]  !font-black !text-black'
                                    : ' !bg-transparent  !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name={ModalConstants.FINANCIAL}
                        />{' '}
                        <PrimaryButton
                            onClick={(e: any) => {
                                setSelectedTransactionTab(
                                    (e.target as HTMLElement).innerText
                                );
                            }}
                            isDrawerButton
                            className={classNames(
                                'rounded-2xl',
                                selectedTransactionTab === 'Raw Response'
                                    ? ' !bg-[#DEECAA]  !font-black !text-black'
                                    : ' !bg-transparent  !font-black !text-black'
                            )}
                            color="#8FB131"
                            variant="filled"
                            name="Raw Response"
                        />
                    </div>
                    {selectedTransactionTab === ModalConstants.RAW_RESPONSE ? (
                        <RawResponse transaction={transaction} />
                    ) : (
                        <div className="mt-2 flex w-full max-w-full items-center justify-start">
                            <VerticalTable data={data || []} />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
export default TransactionDetailModal;
