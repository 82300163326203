import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import './switch.css';

interface ISwitchProp
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    className?: string;
    isToggled?: boolean;
    handleCheck?: () => void;
    disabled?: boolean;
    isRuleEngine?: boolean;
}

const Switch = ({
    className,
    isToggled,
    handleCheck,
    disabled,
    isRuleEngine,
    ...props
}: ISwitchProp) => (
    <label
        className={`${className as string} toggle-switch ${
            isRuleEngine ? 'toggle-switch-rule' : ' '
        } `}
    >
        <input
            onClick={(e) => e.stopPropagation()}
            type="checkbox"
            onChange={handleCheck}
            className={className}
            checked={isToggled}
            disabled={disabled}
            {...props}
        />
        <span
            onClick={(e) => e.stopPropagation()}
            className={`${className as string} switch ${
                isRuleEngine ? 'switch-rule' : ''
            }`}
        />
    </label>
);

export default Switch;
