import React, { useEffect, useRef, useState } from 'react';
import UpArrow from 'assets/images/up.svg';
import DownArrow from 'assets/images/down.svg';
import { getCompanyBrands } from 'services/api/api';
import { useAuthContext } from 'context/Auth/AuthContext';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';

const MultiLevelDropdown = () => {
    const { auth, dispatch } = useAuthContext();
    const [open, setOpen] = useState<boolean>(false);
    const [companies, setCompanies] = useState<any[]>([
        { id: '', name: 'Select Company or Brands', brands: [] },
    ]);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const getAllCompanyBrands = async () => {
        const res = await getCompanyBrands();
        setCompanies(res.companies);
        dispatch({
            type: AppActionsEnum.SET_COMPANY_AND_BRANDS,
            payload: {
                companiesAndBrands: res.companies,
            },
        });
        if (auth.isSuperAdmin && res.companies) {
            setCompanies(
                [
                    auth?.isSuperAdmin && {
                        id: '',
                        name: 'Select Company or Brands',
                        brands: [],
                    },
                    ...(res.companies || ''),
                ] || []
            );
        }
    };

    useEffect(() => {
        if (auth?.isSuperAdmin || auth?.selectedCompany) return;
        dispatch({
            type: AppActionsEnum.SET_COMPANY,
            payload: {
                selectedCompany: companies[0],
            },
        });
    }, [companies, auth?.selectedCompany]);

    useEffect(() => {
        getAllCompanyBrands();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: TouchEvent | MouseEvent) => {
            const targetElement = event.target as Element;
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Node) &&
                !targetElement.closest('.dropdown-toggle')
            ) {
                setOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [wrapperRef]);

    return (
        <div className="flex justify-center relative w-80 p-3 rounded-lg bg-[#F5F5F5] h-6 z-[100]">
            <div
                className="w-full flex cursor-pointer justify-between items-center text-sm text-[#A4A4A4] dropdown-toggle"
                onMouseDown={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                }}
            >
                {auth.selectedBrand?.name ||
                    auth.selectedCompany?.name ||
                    'Select Company or Brands'}
                <img src={open ? UpArrow : DownArrow} alt="arrow" />
            </div>
            {open && (
                <div
                    className="absolute w-full max-h-80 h-auto overflow-y-auto top-14 bg-[#FAFAF5] z-50 rounded border border-[#E3E3E3] border-solid cursor-pointer"
                    ref={wrapperRef}
                >
                    <ul className="m-0 w-full p-0">
                        {companies?.map((item) => (
                            <React.Fragment key={item._id}>
                                <div
                                    role="presentation"
                                    onClick={() => {
                                        dispatch({
                                            type: AppActionsEnum.SET_COMPANY,
                                            payload: {
                                                selectedCompany:
                                                    auth.selectedCompany
                                                        ?._id === item?._id
                                                        ? null
                                                        : item,
                                            },
                                        });
                                        dispatch({
                                            type: AppActionsEnum.SET_BRAND,
                                            payload: {
                                                selectedBrand: null,
                                            },
                                        });
                                        if (!item?._id) setOpen(false);
                                    }}
                                >
                                    <div className="flex justify-between items-center py-2 px-3 border-b border-t-0 border-x-0 border-[#E3E3E3] border-solid">
                                        <div className="text-xs font-bold">
                                            {item?.name || ''}
                                        </div>
                                        <img
                                            src={
                                                auth.selectedCompany?._id ===
                                                item?._id
                                                    ? UpArrow
                                                    : DownArrow
                                            }
                                            alt="arrow"
                                        />
                                    </div>
                                </div>
                                {auth.selectedCompany?._id === item._id && (
                                    <div>
                                        {item?.brandData?.map((brand: any) => (
                                            <div
                                                key={brand?._id}
                                                onClick={() => {
                                                    dispatch({
                                                        type: AppActionsEnum.SET_BRAND,
                                                        payload: {
                                                            selectedBrand:
                                                                brand,
                                                        },
                                                    });
                                                    setOpen(false);
                                                }}
                                            >
                                                <div className="flex justify-between items-center py-2 pr-3 pl-5 border-b border-t-0 border-x-0 border-[#E3E3E3] border-solid">
                                                    <div className="text-xs">
                                                        {brand.name}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MultiLevelDropdown;
