import classNames from 'classnames';
import { PrimaryButton, TextField } from 'components/Common';

import { ITransactionHeader, PaymentStatus } from '../Transaction.type';

export const TransactionHeader = ({
    setFilterData,
    filterData,
    setIsTableFilter,
}: ITransactionHeader) => (
    <div>
        <div className="flex justify-between">
            <div className="mr-6 flex flex-wrap items-center gap-5 ml-1">
                <div className="font-extrabold text-center text-2xl text-[#131119]">
                    Transactions
                </div>
                <TextField
                    iconPosition="left"
                    icon="SEARCH_ICON"
                    className="bg-white	border border-solid border-[#C8C8C8] min-[1480px]:!w-[380px] min-w-1/2 !pl-9"
                    placeholder="Search by Tags, filters..."
                    onChange={(e) => {
                        setFilterData({
                            ...filterData,
                            search: e.target.value,
                        });
                    }}
                />
            </div>
            <div className="flex items-end">
                <PrimaryButton
                    type="button"
                    name="Column Filter"
                    color="#8FB131"
                    variant="filled"
                    isDrawerButton
                    className="font-medium p-4 items-center"
                    onClick={() => setIsTableFilter(true)}
                />
            </div>
        </div>
        <div className="flex pt-4 gap-4">
            {PaymentStatus.map((v) => (
                <PrimaryButton
                    key={`keys_${v.value}`}
                    className={classNames(
                        '!text-black !text-xs px-3 !font-extrabold !rounded-xl',
                        {
                            '!bg-[#DEECAA]':
                                v.name === 'All Payments'
                                    ? !filterData.status
                                    : filterData.status === v.value,
                            '!bg-white': filterData.status !== v.value,
                        }
                    )}
                    color="#8FB131"
                    variant="filled"
                    name={v.name}
                    onClick={() =>
                        setFilterData({
                            ...filterData,
                            status: v.value,
                        })
                    }
                />
            ))}
        </div>
    </div>
);
