import React, { useState } from 'react';
import classNames from 'classnames';
import { IIcons } from 'data/common';
import { TextField } from '../index';
import './searchMenu.css';
import SvgIcon from '../Icon/SvgIcon';

interface ISearchMenuListProps {
    className?: string;
    selectedCompanyId?: string;
    datalist: Array<{ name: string; value: string; logo?: keyof IIcons }>;
    selectedProvider?: any;
    onSelectPsp: (psp: any) => void;
}

const SearchMenuList = ({
    className,
    datalist,
    selectedCompanyId,
    onSelectPsp,
    selectedProvider,
}: ISearchMenuListProps) => {
    const [query, setQuery] = useState('');

    const handleQueryChange = (event: any) => {
        setQuery(event.target.value);
    };

    const filteredProviders = datalist.filter((provider) =>
        provider.name.toLowerCase().includes(query.toLowerCase())
    );

    return (
        <div
            className={classNames(
                className,
                'searchable-payment-providers-list w-full'
            )}
        >
            <div className="w-full bg-white z-[100]">
                <TextField
                    type="search"
                    iconPosition="left"
                    icon="SEARCH_ICON"
                    className="min-[1480px]:!w-[400px] min-w-1/2 !pl-9"
                    placeholder="Search"
                    value={query}
                    onChange={handleQueryChange}
                />
            </div>

            <div className="mt-4 searchList">
                {filteredProviders.map((provider, index) => (
                    <div
                        key={index}
                        className={`menuList ${
                            selectedProvider?.name === provider.name &&
                            selectedCompanyId
                                ? selectedCompanyId
                                    ? 'selected'
                                    : 'disabled'
                                : selectedCompanyId
                                ? ''
                                : 'disabled'
                        }`}
                        onClick={() => onSelectPsp(provider)}
                    >
                        <SvgIcon
                            key={`${provider.name}_${index}`} // Use a combination of name and index as a key
                            icon={provider?.logo as keyof IIcons}
                            className="h-[32px]"
                        />
                        <div className="flex w-full items-center justify-between text-center">
                            <span>{provider.name}</span>
                            <SvgIcon
                                className="flex"
                                style={{ cursor: 'pointer' }}
                                icon={
                                    selectedProvider?.name === provider.name &&
                                    selectedCompanyId
                                        ? 'FILL_RIGHT_ICON'
                                        : 'STATE_RIGHT_ICON'
                                }
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SearchMenuList;
