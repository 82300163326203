import React, { ChangeEvent, useEffect, useState } from 'react';
import { Select } from 'components/Common';
import {
    IConditionDataProps,
    IPreConditionData,
    IPreconditionPros,
} from '../../RuleEngine.type';
import { PreconditionDropdown } from './PreconditionDropdown';
import { PreconditionList } from '../../condition';

const Preconditions = ({
    showPreconditions,
    getPreConditionDataList,
    setGetPreConditionDataList,
}: IPreconditionPros) => {
    const [data, setData] = useState<IConditionDataProps[]>([]);

    const updateCondition = PreconditionList.map((obj) => {
        if (obj.Condition) {
            obj.name = obj.Condition;
            delete obj.Condition;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return obj;
    });

    useEffect(() => {
        if (getPreConditionDataList.length > 0) {
            const tempList: IConditionDataProps[] = getPreConditionDataList.map(
                (obj: IPreConditionData) => ({
                    title: obj?.key,
                    items: obj?.values,
                    conditionType: obj?.conditionType,
                })
            );
            setData(tempList); // Concatenate tempList with existing data
        }
    }, [getPreConditionDataList]);

    const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if (data?.find((item) => item?.title === value)) {
            return null;
        }
        const getConditionList = updateCondition.find((v) => v.name === value);
        const listType = getConditionList?.Type;
        setGetPreConditionDataList([
            ...getPreConditionDataList,
            { key: value, conditionType: listType, values: [] },
        ]);

        setData([
            ...data,
            { title: value, conditionType: listType, items: [] },
        ]);
    };

    const handleDelete = (e: string) => {
        const updateData = data.filter((v) => v?.title !== e);
        const updateList = getPreConditionDataList.filter(
            (ele) => ele.key !== e
        );
        setData(updateData);
        setGetPreConditionDataList(updateList);
    };

    return (
        <div className="bg-[#BDD864] m-4 rounded-2xl p-4">
            <span className="text-sm font-bold leading-[18px] p-0.5">
                Preconditions
            </span>
            {showPreconditions && (
                <PreconditionDropdown
                    data={data}
                    setData={setData}
                    setGetPreConditionDataList={setGetPreConditionDataList}
                    handleDelete={handleDelete}
                    getPreConditionDataList={getPreConditionDataList}
                />
            )}
            <Select
                asterisk
                isCompanyAdmin
                isGroup
                isRightMargin
                options={[
                    { name: 'Start Typing...' },
                    ...updateCondition,
                ].filter(
                    (i) =>
                        !data
                            .map((item: any) => item.title as string)
                            .includes(i.name)
                )}
                value="Start Typing..."
                handleOnSelect={(e) => handleSelect(e)}
                className="col-span-4 text-gray-400"
            />
        </div>
    );
};

export default Preconditions;
