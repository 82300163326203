import { useMemo } from 'react';
import { Menubar, SvgIcon } from 'components/Common';
import { SIDE_MENU_LIST } from 'data/constants/common.constants';
import { useAuthContext } from 'context/Auth/AuthContext';
import './sidebar.css';

const SideMenu = () => {
    const {
        auth: { authUser, permissions, isSuperAdmin },
    } = useAuthContext();

    const getSideBarItemAsPerRole = useMemo(() => {
        if (!authUser?.role) return [];
        if (!isSuperAdmin) {
            return SIDE_MENU_LIST.filter((menu) =>
                (permissions || []).some((data) => data.parentTab === menu.key)
            ).map((v) => ({
                ...v,
                subMenu: (v.subMenu || []).filter((a) =>
                    permissions.some((data) => data.value === a.key)
                ),
            }));
        }
        return SIDE_MENU_LIST;
        // eslint-disable-next-line
    }, [authUser?.role, permissions.length]);

    return (
        <div className="w-64 background-gradient">
            <div className="flex flex-col justify-between">
                <div className="p-4">
                    <SvgIcon icon="MOZARTO_ICON" />
                    <div className="mt-4">
                        <Menubar menuItem={getSideBarItemAsPerRole} />
                    </div>

                    {/* <div className="mt-3 flex items-center justify-between">
                        <span className="ml-4 text-xs font-extrabold text-green-100">
                            Sandbox Mode
                        </span>
                        <Switch className="rounded-2xl bg-white" disabled />
                    </div> */}
                </div>
                {/* <div className="p-6">
                    <div className="flex flex-col items-center rounded-2xl bg-green-600 p-4 text-white">
                        <span className="text-base font-extrabold">
                            Need more help?
                        </span>
                        <span className="text-xs font-light">
                            24/7 support is available,
                        </span>
                        <span className="text-xs font-light">
                            {' '}
                            start a chat now
                        </span>
                        <PrimaryButton
                            onClick={() => console.log('send recovery Link')}
                            type="button"
                            name="Chat Now"
                            color="#8FB131"
                            variant="filled"
                            className="bg-[#8FB131] h-[40px] font-extrabold hover:bg-[#2E672F] hover:border-green-600 focus:border-green-100 rounded-xl w-full mt-5"
                        />
                    </div>
                </div>
            </div> */}
            </div>
        </div>
    );
};

export default SideMenu;
