import { Header, SideMenu } from '../index';
import './layout.css';

export interface ILayoutProps {
    children?: JSX.Element;
}

const Layout = ({ children }: ILayoutProps) => (
    <div className="flex w-full">
        <SideMenu />
        <div>
            <Header />
            <div className="bg-gray-700 content overflow-y-scroll">
                {children}
            </div>
        </div>
    </div>
);

export default Layout;
