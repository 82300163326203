import {
    InputHTMLAttributes,
    DetailedHTMLProps,
    FC,
    MouseEvent,
    ReactNode,
    useRef,
    useEffect,
    useState,
} from 'react';
import './index.css';
import classNames from 'classnames';
import SvgIcon from '../Icon/SvgIcon';

interface Props
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    // onChange: (e: ChangeEvent<HTMLInputElement>, key?: string) => void;
    label?: ReactNode;
    icon?: any;
    iconPosition?: 'left' | 'right';
    isError?: boolean;
    isCheckbox?: boolean;
    iconOnClick?: (e: MouseEvent<HTMLElement>) => void;
    placeHolder?: string;
    max?: number;
    defaultValue?: string;
    asterisk?: boolean;
}

const Input: FC<Props> = ({
    defaultValue,
    style,
    icon,
    iconPosition,
    isCheckbox = false,
    label,
    className,
    iconOnClick,
    isError = false,
    placeHolder,
    asterisk = false,
    max,
    type,
    ...props
}: Props) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
    };

    useEffect(() => {
        // Add event listeners to track focus changes
        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);
        document.addEventListener('hover', handleFocusChange, true);

        // Clean up the event listeners when the component is unmounted
        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
            document.removeEventListener('hover', handleFocusChange, true);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className="flex flex-col">
            {!isCheckbox && (
                <label
                    className={classNames(
                        'font-semibold text-sm mb-1 text-start',
                        isError
                            ? 'text-red-200'
                            : isHovered
                            ? 'text-black'
                            : props.value
                            ? 'text-black'
                            : !isFocused
                            ? 'text-gray-500'
                            : 'text-black'
                    )}
                    htmlFor={props?.id}
                >
                    {label}
                    {asterisk && (
                        <span className="!text-red-200 text-lg pl-[2px] pt-3">
                            *
                        </span>
                    )}
                </label>
            )}
            <div
                className="w-full input-with-icon"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                {iconPosition === 'left' && (
                    <SvgIcon
                        icon={icon}
                        onClick={(e) => (iconOnClick ? iconOnClick(e) : '')}
                        style={{
                            position: 'absolute',
                            top: '55%',
                            transform: 'translateY(-50%)',
                            left: iconPosition === 'left' ? '10px' : 'auto',
                            right: iconPosition === 'left' ? 'auto' : 'auto',
                        }}
                    />
                )}
                {isCheckbox ? (
                    <label className="container">
                        {label}
                        <input
                            defaultValue={defaultValue}
                            max={max}
                            ref={inputRef}
                            placeholder={placeHolder}
                            className={className}
                            style={{
                                display: 'flex',
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#393838',
                                width: '100%',
                                padding: '10px 8px',
                                borderRadius: 6,
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            type={type ?? 'text'}
                            {...props}
                        />{' '}
                        <span
                            onClick={(e) => e.stopPropagation()}
                            className="checkmark"
                        />
                    </label>
                ) : (
                    <input
                        defaultValue={defaultValue}
                        max={max}
                        ref={inputRef}
                        placeholder={placeHolder}
                        className={className}
                        style={{
                            display: 'flex',
                            fontSize: '16px',
                            fontWeight: 400,
                            color: '#393838',
                            width: '100%',
                            padding: '10px 8px',
                            borderRadius: 6,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        type={type ?? 'text'}
                        {...props}
                    />
                )}
                {iconPosition === 'right' && (
                    <SvgIcon
                        icon={icon}
                        onClick={(e) => (iconOnClick ? iconOnClick(e) : '')}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            left: iconPosition === 'right' ? 'auto' : '10px',
                            right: iconPosition === 'right' ? '10px' : 'auto',
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Input;
