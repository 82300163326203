import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    PrimaryButton,
    Switch,
    TagInput,
    RFTextField,
    RFSelectField,
} from 'components/Common';
import DatePicker from 'components/Common/DatePicker/DatePicker';
import { getAllBrands, processTrustPayment } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IBrandData } from 'data/common';
import { Currencies } from 'data/constants/cashier.constants';
import { GenderList } from 'data/constants/gender.constants';
import { Countries } from 'data/constants/country.constants';
import { trustFormValidation } from './Merchant.schema';
import { ITrustType } from './Merchant.type';

const Trust = () => {
    const [brands, setBrands] = useState<IBrandData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { control, handleSubmit, reset } = useForm<ITrustType>({
        resolver: zodResolver(trustFormValidation),
        mode: 'onChange',
        defaultValues: {
            blocked: false,
            kycStatus: false,
        },
    });

    const getAllBrandsData = () => {
        getAllBrands({ filter: { pspType: 'Trust' } })
            .then((res) => {
                setBrands(res.brands || []);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getAllBrandsData();
    }, []);

    const handleOnSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const brand = brandList?.find((b) => b.name === formData.brandId);
        const payload = {
            tags: formData?.tags || [],
            bonusCode: formData?.bonusCode || '',
            emailVerification: formData?.emailVerification,
            verificationDate: formData?.verificationDate,
            partnerName: formData?.partnerName,
            userBalance: formData?.userBalance,
            kycStatus: formData?.kycStatus,
            blocked: formData?.blocked,
            brandId: brand?._id,
            card: {
                expiry: {
                    month: String(
                        new Date(formData.expiry).getMonth() + 1
                    ).padStart(2, '0'),
                    year: formData.expiry.split('/')[1],
                },
                pan: formData?.card_number,
                cvv: formData?.cvv,
                name_on_card: formData?.name,
            },
            currency: formData.currency,
            amount_base: parseFloat(formData.total),
            card_holder: {
                name: {
                    first: formData?.firstName,
                    last: formData?.lastName,
                },
                date_of_birth: formData?.dateOfBirth,
                gender: formData?.gender,
                contact: {
                    email: formData?.email,
                    phone: formData?.phone,
                },
                billing_address: {
                    line1: formData?.line1,
                    line2: formData?.line2 || '',
                    postal_code: formData?.postalcode,
                    city: formData?.city,
                    state: formData?.state,
                    country: formData?.country,
                },
            },
        };

        processTrustPayment(payload)
            .then((res) => {
                if (res.isSuccess) {
                    showToast(res?.message, 'success');
                    setLoading(false);
                    reset({});
                }
            })
            .catch((err) => {
                setLoading(false);
                showToast(err.message || 'something went wrong', 'error');
            });
    });

    const brandList = [{ _id: '', name: 'Select Brand' }, ...brands];

    return (
        <form className="w-full" onSubmit={handleOnSubmit}>
            <div>
                <div className="mt-3 mb-4 text-base font-bold text-black">
                    Card Details
                </div>
                <RFTextField
                    control={control}
                    name="card_number"
                    fullWidth
                    label="Card Number"
                    type="number"
                    asterisk
                    noArrows
                />
                <RFTextField
                    control={control}
                    name="name"
                    fullWidth
                    label="Name"
                    type="text"
                    asterisk
                />

                <div className="grid grid-cols-5 gap-6">
                    <div className="col-span-3">
                        <Controller
                            name="expiry"
                            control={control}
                            render={({
                                field: { onChange, ...field },
                                fieldState,
                            }) => (
                                <DatePicker
                                    label="Expiry (MM/YY)"
                                    options={{
                                        allowInput: true,
                                        dateFormat: 'm/y',
                                        minDate: new Date(),
                                    }}
                                    placeholder="Expiry (MM/YY)"
                                    onChange={(
                                        selectedDates: any,
                                        dateStr: any
                                    ) => {
                                        onChange(dateStr);
                                    }}
                                    asterisk
                                    isError={!!fieldState.error?.message}
                                    errorMessage={
                                        fieldState.error?.message ?? ''
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-2">
                        <RFTextField
                            control={control}
                            name="cvv"
                            fullWidth
                            label="CVV"
                            type="number"
                            noArrows
                            asterisk
                            maxLength={3}
                        />
                    </div>
                </div>
                <div className="mt-3 mb-4 text-base font-bold text-black">
                    Order Details
                </div>
                <div className="grid grid-cols-5 gap-6">
                    <div className="col-span-3">
                        <RFTextField
                            control={control}
                            name="total"
                            fullWidth
                            label="Amount"
                            type="number"
                            noArrows
                            asterisk
                            maxLength={3}
                        />
                    </div>
                    <div className="col-span-2">
                        <RFSelectField
                            name="currency"
                            control={control}
                            fullWidth
                            label="Currency"
                            asterisk
                            options={Currencies}
                        />
                    </div>
                </div>

                <div className="mt-3 mb-4 text-base font-bold text-black">
                    Card Holder Details
                </div>
                <div className="grid grid-cols-5 gap-y-0 gap-6">
                    <div className="col-span-3">
                        <RFTextField
                            control={control}
                            name="firstName"
                            fullWidth
                            label="First Name"
                            type="text"
                            asterisk
                        />
                    </div>
                    <div className="col-span-2">
                        <RFTextField
                            control={control}
                            name="lastName"
                            fullWidth
                            label="Last Name"
                            type="text"
                            asterisk
                        />
                    </div>
                    <div className="col-span-3">
                        <RFTextField
                            control={control}
                            name="email"
                            fullWidth
                            label="Email"
                            type="text"
                            asterisk
                        />
                    </div>
                    <div className="col-span-2">
                        <RFTextField
                            control={control}
                            name="phone"
                            fullWidth
                            label="Phone"
                            type="number"
                            noArrows
                            asterisk
                        />
                    </div>
                    <div className="col-span-3">
                        <Controller
                            name="dateOfBirth"
                            control={control}
                            render={({
                                field: { onChange, ...field },
                                fieldState,
                            }) => (
                                <DatePicker
                                    label="Date Of Birth"
                                    options={{
                                        allowInput: true,
                                        dateFormat: 'd-m-Y',
                                    }}
                                    placeholder="DD - MM - YYYY"
                                    onChange={(
                                        selectedDates: any,
                                        dateStr: any
                                    ) => {
                                        onChange(dateStr);
                                    }}
                                    dateTimePickerProps={{
                                        options: {
                                            maxDate: new Date(),
                                        },
                                    }}
                                    asterisk
                                    isError={!!fieldState.error?.message}
                                    errorMessage={
                                        fieldState.error?.message ?? ''
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className="col-span-2">
                        <RFSelectField
                            name="gender"
                            control={control}
                            fullWidth
                            label="Gender"
                            asterisk
                            options={GenderList}
                        />
                    </div>
                </div>
                <div className="mt-3 mb-4 text-base font-bold text-black">
                    Billing Address
                </div>
                <div className="grid grid-cols-2 gap-y-0 gap-6">
                    <RFTextField
                        control={control}
                        name="line1"
                        fullWidth
                        label="Address Line 1"
                        type="text"
                        asterisk
                    />

                    <RFTextField
                        control={control}
                        name="line2"
                        fullWidth
                        label="Address Line 2"
                        type="text"
                    />
                    <RFTextField
                        control={control}
                        name="postalcode"
                        fullWidth
                        label="Post Code / Zip Code"
                        type="text"
                        asterisk
                    />
                    <RFTextField
                        control={control}
                        name="city"
                        fullWidth
                        label="City"
                        type="text"
                        asterisk
                    />
                    <RFTextField
                        control={control}
                        name="state"
                        fullWidth
                        label="State"
                        type="text"
                        asterisk
                    />

                    <RFSelectField
                        name="country"
                        control={control}
                        fullWidth
                        label="Country"
                        asterisk
                        options={Countries}
                    />
                </div>
                <div className="mt-3 mb-4 text-base font-bold text-black">
                    Other Information
                </div>

                <RFTextField
                    control={control}
                    name="emailVerification"
                    fullWidth
                    label="Email Verification"
                    type="text"
                    placeholder="Email Verification"
                    asterisk
                />

                <Controller
                    name="verificationDate"
                    control={control}
                    render={({ field: { onChange, ...field }, fieldState }) => (
                        <DatePicker
                            label="Verification Date"
                            placeholder="DD - MM - YYYY"
                            onChange={(selectedDates: any, dateStr: any) => {
                                onChange(dateStr);
                            }}
                            options={{
                                allowInput: true,
                                dateFormat: 'd-m-Y',
                            }}
                            isError={!!fieldState.error?.message}
                            errorMessage={fieldState.error?.message ?? ''}
                            {...field}
                        />
                    )}
                />

                <RFTextField
                    control={control}
                    name="partnerName"
                    fullWidth
                    label="Partner Name"
                    type="text"
                    placeholder="Partner Name"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="userBalance"
                    fullWidth
                    label="User Balance"
                    type="number"
                    noArrows
                    placeholder="User Balance"
                    asterisk
                />

                <div className="flex items-center mb-4">
                    <RFSelectField
                        name="brandId"
                        control={control}
                        fullWidth
                        label="Brand"
                        asterisk
                        options={brandList?.map((v) => ({
                            name: v.name,
                            id: v._id,
                        }))}
                    />
                </div>
                <RFTextField
                    control={control}
                    name="bonusCode"
                    fullWidth
                    label="Bonus Code"
                    type="number"
                    noArrows
                    placeholder="Bonus Code"
                />

                <Controller
                    name="tags"
                    control={control}
                    render={({ field, fieldState }) => (
                        <>
                            <TagInput
                                label="Categories"
                                placeholder="Press enter to add categories"
                                tags={field.value || []}
                                onTagsChange={(tags) => {
                                    field.onChange(tags);
                                }}
                            />
                            {fieldState.error?.message ? (
                                <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                                    {fieldState.error?.message}
                                </div>
                            ) : null}
                        </>
                    )}
                />

                <div className="grid grid-cols-2 items-center mb-4 gap-10">
                    <div className="flex items-center justify-between">
                        <div className="text-sm text-gray-500 font-semibold">
                            Blocked
                        </div>
                        <Controller
                            name="blocked"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState,
                            }) => (
                                <>
                                    <Switch
                                        onChange={(e) =>
                                            onChange(e.target.checked)
                                        }
                                        isToggled={value}
                                        className="rounded-2xl bg-gray-200"
                                    />
                                    {fieldState.error?.message ? (
                                        <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                                            {fieldState.error?.message}
                                        </div>
                                    ) : null}
                                </>
                            )}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="text-sm text-gray-500 font-semibold">
                            Kyc Status
                        </div>
                        <Controller
                            name="kycStatus"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState,
                            }) => (
                                <>
                                    <Switch
                                        onChange={(e) =>
                                            onChange(e.target.checked)
                                        }
                                        isToggled={value}
                                        className="rounded-2xl bg-gray-200"
                                    />
                                    {fieldState.error?.message ? (
                                        <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                                            {fieldState.error?.message}
                                        </div>
                                    ) : null}
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-center mt-5">
                    <PrimaryButton
                        type="submit"
                        isDrawerButton
                        className={classNames('w-full max-w-[300px]')}
                        color="#2E672F"
                        variant="filled"
                        name="Submit"
                        loading={loading}
                    />
                </div>
            </div>
        </form>
    );
};

export default Trust;
