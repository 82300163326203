import React, {
    InputHTMLAttributes,
    DetailedHTMLProps,
    FC,
    MouseEvent,
    ReactNode,
    useRef,
    useEffect,
    useState,
} from 'react';
import './index.css';
import classNames from 'classnames';
import SvgIcon from '../Icon/SvgIcon';

export interface TextFieldProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    // onChange: (e: ChangeEvent<HTMLInputElement>, key?: string) => void;
    label?: ReactNode;
    icon?: any;
    iconPosition?: 'left' | 'right';
    isError?: boolean;
    fullWidth?: boolean;
    isCheckbox?: boolean;
    errorMessage?: string | number;
    iconOnClick?: (e: MouseEvent<HTMLElement>) => void;
    placeHolder?: string;
    max?: number;
    defaultValue?: string;
    asterisk?: boolean;
    noArrows?: boolean;
    mb?: number;
    pattern?: string;
}

const Input: FC<TextFieldProps> = ({
    defaultValue,
    style,
    icon,
    iconPosition,
    isCheckbox = false,
    fullWidth = false,
    label,
    className,
    iconOnClick,
    isError = false,
    placeHolder,
    asterisk = false,
    errorMessage,
    noArrows = false,
    max,
    pattern,
    type = 'text',
    mb,
    ...props
}: TextFieldProps) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [passwordType, setPasswordType] = useState('password');

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    };

    const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
    };

    useEffect(() => {
        // Add event listeners to track focus changes
        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);
        document.addEventListener('hover', handleFocusChange, true);

        // Clean up the event listeners when the component is unmounted
        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
            document.removeEventListener('hover', handleFocusChange, true);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={classNames(
                'flex flex-col mb-4',
                fullWidth && 'w-full',
                isCheckbox && 'mb-0',
                mb !== undefined ? `mb-${mb}` : 'mb-4'
            )}
        >
            {!isCheckbox && !!label && (
                <label
                    className={classNames(
                        'font-semibold text-sm mb-2 text-start',
                        isError
                            ? 'text-red-200'
                            : isHovered
                            ? 'text-black'
                            : props.value
                            ? 'text-black'
                            : !isFocused
                            ? 'text-gray-500'
                            : 'text-black'
                    )}
                    htmlFor={props?.id}
                >
                    {label}
                    {asterisk && (
                        <span className="!text-red-200 text-lg pl-[2px] pt-3">
                            *
                        </span>
                    )}
                </label>
            )}
            <div
                className={`${
                    isCheckbox ? 'w-[22px]' : 'w-full'
                } input-with-icon relative flex items-center`}
            >
                {iconPosition === 'left' && (
                    <SvgIcon
                        icon={icon}
                        onClick={(e) => (iconOnClick ? iconOnClick(e) : '')}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-37%)',
                            left: iconPosition === 'left' ? '10px' : 'auto',
                            right: iconPosition === 'left' ? 'auto' : 'auto',
                        }}
                    />
                )}
                {isCheckbox ? (
                    <label className="container">
                        {label}
                        <input
                            onClick={(e) => e.stopPropagation()}
                            placeholder={placeHolder}
                            className={classNames(
                                className,
                                'flex p-3 text-sm w-full'
                            )}
                            style={{
                                color: '#393838',
                                borderRadius: 6,
                            }}
                            type="checkbox"
                            pattern={pattern}
                            {...props}
                        />{' '}
                        <span
                            onClick={(e) => e.stopPropagation()}
                            className="checkmark"
                        />
                    </label>
                ) : (
                    <input
                        defaultValue={defaultValue}
                        max={max}
                        ref={inputRef}
                        placeholder={placeHolder}
                        className={classNames(
                            className,
                            'flex p-3 text-sm w-full',
                            noArrows ? 'inputField' : ''
                        )}
                        pattern={pattern}
                        style={{
                            color: '#393838',
                            borderRadius: 6,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        type={type === 'password' ? passwordType : type}
                        {...props}
                    />
                )}
                {iconPosition === 'right' && (
                    <SvgIcon
                        icon={
                            type !== 'password'
                                ? icon
                                : passwordType === 'password'
                                ? 'HIDDEN_PASSWORD'
                                : 'SHOW_PASSWORD'
                        }
                        onClick={(e) =>
                            iconOnClick ? iconOnClick(e) : togglePassword()
                        }
                        style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            left: iconPosition === 'right' ? 'auto' : '10px',
                            right: iconPosition === 'right' ? '10px' : 'auto',
                        }}
                    />
                )}
            </div>
            {errorMessage && (
                <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default Input;
