import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { Drawer, PrimaryButton, Table } from 'components/Common';
import { ICompanyData, IGroup, IPermissionData } from 'data/common';
import {
    getAllBrands,
    getAllPageAreas,
    getCompanies,
    getGroupData,
    updateUser,
    getPermissions,
} from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGetAllBrandData, IGetAllPageAreasData } from 'data/types/response';
import { useAuthContext } from 'context/Auth/AuthContext';
import LoadingSpinner from 'components/Common/Loader/Loader';

import {
    UserDrawerAccessTableColumns,
    UserDrawerPageAreaTableColumns,
} from './UserDrawerTableUtill';
import { UserDrawerForm } from './UserDrawerForm';
import { IUserDrawerProps, IUserType } from '../User.type';
import { formSchemaValidation } from '../User.schema';

export const EditUserDrawer = ({
    isOpen,
    openDrawer,
    isEditUser,
    userData,
    setPageAreaData,
    pageAreaData,
    accessAreaData,
    setAccessAreaData,
    getUsers,
    setIsOpen,
}: IUserDrawerProps) => {
    const { auth } = useAuthContext();

    const [userLoading, setUserLoading] = useState<boolean>(false);
    const [pageAreaLoading, setPageAreaLoading] = useState<boolean>(false);
    const [brandLoading, setBrandLoading] = useState<boolean>(false);
    const [allBrandData, setAllBrandData] = useState<IGetAllBrandData[] | null>(
        null
    );
    const [allCompanies, setAllCompanies] = useState<ICompanyData[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<IGroup>();
    const [permissionsData, setPermissionsData] =
        useState<IPermissionData | null>(null);
    const [allPageAreaData, setAllPageAreaData] = useState<
        IGetAllPageAreasData[] | null
    >(null);
    const [groups, setGroups] = useState<IGroup[]>([]);

    const { control, handleSubmit, reset, watch } = useForm<IUserType>({
        resolver: zodResolver(
            formSchemaValidation(auth.isSuperAdmin, auth.isAdmin)
        ),
        mode: 'onChange',
        defaultValues: {},
    });

    const selectedCompanyId = auth.isSuperAdmin
        ? watch('company')
        : auth?.authUser?.company;

    const getAllCompanies = () => {
        const companyId: string = auth.selectedCompany?._id || '';
        const query: string = companyId ? `id=${companyId}&key=Company` : '';
        getCompanies(query)
            .then((res) => {
                setAllCompanies(res?.companies || []);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getGroupData({ companyId: watch('company') })
            .then((res) => {
                setGroups?.(res?.groups);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    }, [watch('company'), isEditUser]);

    const getGroupPermissions = () => {
        getPermissions(userData?.permissionId || '')
            .then((result) => {
                setPermissionsData(result);
                if (setPageAreaData) {
                    setPageAreaData(result?.permission?.pageAreasPermissions);
                }
                if (setAccessAreaData) {
                    setAccessAreaData(result?.permission?.brandsPermissions);
                }
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (auth.isSuperAdmin) {
            getAllCompanies();
        }
    }, [auth.isSuperAdmin]);

    useEffect(() => {
        if (!userData) return;
        const resetValue = {
            id: userData._id,
            name: userData.name,
            email: userData.email,
            phone: userData.phone,
            job: userData.job,
            role: userData.role,
            permissionId: userData.permissionId,
            brandsPermissions: accessAreaData,
            pageAreasPermissions: pageAreaData,
            group: userData?.group === null ? '2' : userData?.group,
            company: auth?.isAdmin
                ? auth?.authUser?.company || ''
                : userData?.company || '',
        };
        reset(resetValue);
    }, [reset, userData]);

    useEffect(() => {
        setPageAreaLoading(true);
        setBrandLoading(true);
        if (userData?.permissionId) {
            getGroupPermissions();
        }
        if (isOpen) {
            getAllBrands(
                selectedCompanyId
                    ? {
                          filter: {
                              companyId: selectedCompanyId,
                          },
                      }
                    : {}
            )
                .then((result) => {
                    setAllBrandData(result?.brands);
                    setBrandLoading(false);
                })
                .catch((err) => {
                    setBrandLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });

            getAllPageAreas()
                .then((result) => {
                    setAllPageAreaData(result?.pageAreas);
                    setPageAreaLoading(false);
                })
                .catch((err) => {
                    setPageAreaLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        }
    }, [isOpen, selectedGroup, watch('company'), userData?.company]);

    const isDisabledCreateUser = useMemo(
        () =>
            !groups?.filter((item) => item?.name !== 'Select Group')?.length ||
            (!pageAreaLoading && !allPageAreaData?.length) ||
            (!brandLoading && !allBrandData?.length),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [groups, pageAreaLoading, brandLoading]
    );

    const isDisabled = useMemo(() => {
        if (selectedGroup?.name !== 'No-Groups') {
            return true;
        }
        if (selectedGroup?.name === 'No-Groups') {
            return false;
        }
        if (selectedGroup?.name === 'Select Group') {
            if (setAccessAreaData) {
                setAccessAreaData([]);
            }
            if (setPageAreaData) {
                setPageAreaData([]);
            }
            return true;
        }

        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroup?.name]);

    useEffect(() => {
        if (selectedGroup?.name === 'No-Groups') {
            if (setPageAreaData) {
                setPageAreaData([]);
            }
            if (setAccessAreaData) {
                setAccessAreaData([]);
            }
        }
    }, [selectedGroup]);

    const companyOptionList = useMemo(() => {
        if (allCompanies) {
            return [{ _id: '', name: 'Select Company' }, ...allCompanies];
        }
        return allCompanies;
    }, [allCompanies]);

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === '2') {
            setPermissionsData(null);
            if (setAccessAreaData) {
                setAccessAreaData([]);
            }
            if (setPageAreaData) {
                setPageAreaData([]);
            }
        }
        const group = groups?.find((item) => item._id === e.target.value);
        if (e.target.value === '1') {
            if (setAccessAreaData) {
                setAccessAreaData([]);
            }
            if (setPageAreaData) {
                setPageAreaData([]);
            }
        }

        setSelectedGroup(group);
    };

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        if (!userData) return;
        const payload = {
            id: userData._id,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            job: formData.job,
            role: formData.role,
            permissionId: userData.permissionId,
            brandsPermissions: accessAreaData,
            pageAreasPermissions: pageAreaData,
            company: auth?.isAdmin
                ? auth?.authUser?.company || ''
                : formData?.company || '',
        };
        updateUser(
            userData._id,
            watch('group') === '2' || watch('group') === null
                ? { ...payload, isCustomGroup: true }
                : {
                      ...payload,
                      isCustomGroup: false,
                      group: formData?.group,
                  }
        )
            .then(() => {
                getUsers();
                setUserLoading(false);
                setIsOpen(false);
                reset({});
                showToast('Successfully Updated');
            })
            .catch((err) => {
                setUserLoading(false);
                showToast(err?.message || 'something went wrong', 'error');
            });
    });

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={() => openDrawer()}
            drawerDirection="right"
            className={classNames(
                'bg-pink-100  max-h-[100vh] relative',
                userLoading ? '' : 'overflow-y-scroll'
            )}
        >
            <form onSubmit={onSubmit}>
                <div className="p-5">
                    <UserDrawerForm
                        companyOptionList={companyOptionList}
                        groups={groups}
                        handleOnSelect={handleOnSelect}
                        isEditUser={isEditUser}
                        openDrawer={() => openDrawer()}
                        userData={userData}
                        control={control}
                        watch={watch}
                    />
                    <div className="mt-3">
                        <div>
                            <div>
                                {brandLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Controller
                                        name="brandsPermissions"
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                value,
                                                ...field
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <>
                                                    <Table
                                                        colSpan={3}
                                                        isSidebarTable
                                                        data={
                                                            allBrandData || []
                                                        }
                                                        columns={UserDrawerAccessTableColumns(
                                                            isDisabled,
                                                            selectedGroup,
                                                            accessAreaData,
                                                            permissionsData,
                                                            setAccessAreaData
                                                        )}
                                                        className="w-full"
                                                        {...field}
                                                    />
                                                    <div className="mb-1 text-sm font-medium text-red-200">
                                                        {fieldState.error
                                                            ?.message
                                                            ? fieldState.error
                                                                  ?.message
                                                            : ''}
                                                    </div>
                                                </>
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <div>
                                {pageAreaLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <div>
                                        <Controller
                                            name="brandsPermissions"
                                            control={control}
                                            render={({
                                                field: {
                                                    onChange,
                                                    value,
                                                    ...field
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <>
                                                        <Table
                                                            isSidebarTable
                                                            data={
                                                                allPageAreaData ||
                                                                []
                                                            }
                                                            columns={UserDrawerPageAreaTableColumns(
                                                                isDisabled,
                                                                selectedGroup,
                                                                pageAreaData,
                                                                permissionsData,
                                                                setPageAreaData
                                                            )}
                                                            {...field}
                                                            className="w-full"
                                                            colSpan={3}
                                                        />
                                                        <div className="mb-1 text-sm font-medium text-red-200">
                                                            {fieldState.error
                                                                ?.message
                                                                ? fieldState
                                                                      .error
                                                                      ?.message
                                                                : ''}
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky bottom-0 z-10 mt-2 flex bg-white p-5">
                    <PrimaryButton
                        type="submit"
                        isDrawerButton
                        className={classNames(
                            'w-full',
                            isDisabledCreateUser ? '!opacity-30 ' : ''
                        )}
                        color="#2E672F"
                        variant="filled"
                        name={isEditUser ? 'Update User' : 'Create new user'}
                    />
                </div>
            </form>
        </Drawer>
    );
};
