import { Dispatch, SetStateAction } from 'react';
import { Switch, TextField } from 'components/Common';
import { getDateFormat, getFirstDateFormat } from 'data/utils/common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { updateUserStatus } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IColumnType } from 'components/Common/Table/Table';
import { ICreateUserData } from 'data/common';
import { IUserData } from '../User.type';
import { GetStatus } from '../User';

export const UserTableColumn = (
    isAllChecked: boolean,
    setCheckedUsers: Dispatch<SetStateAction<string[]>>,
    checkedUsers: Array<string>,
    updatedUserStatus: (id: string, status: boolean) => void,
    companyUsers: Array<IUserData>,
    setUserData: Dispatch<SetStateAction<ICreateUserData | null>>,
    setIsEditUser: Dispatch<SetStateAction<boolean>>,
    setIsOpen: Dispatch<SetStateAction<boolean>>
): IColumnType<ICreateUserData>[] => [
    {
        key: 'checked',
        width: 20,
        title: (
            <div className="flex justify-center">
                <TextField
                    onChange={() => {
                        if (isAllChecked) {
                            setCheckedUsers([]);
                        } else {
                            setCheckedUsers(
                                companyUsers?.map((item) => item?._id)
                            );
                        }
                    }}
                    isCheckbox
                    checked={isAllChecked}
                    type="checkbox"
                />
            </div>
        ),
        render: (_, i) => (
            <div className="flex justify-center">
                <TextField
                    isCheckbox
                    checked={
                        checkedUsers?.find((item) => item === i._id) === i._id
                    }
                    onChange={() => {
                        if (checkedUsers?.includes(i._id) || isAllChecked) {
                            setCheckedUsers(
                                checkedUsers?.filter((item) => item !== i?._id)
                            );
                        } else {
                            setCheckedUsers([...checkedUsers, i?._id]);
                        }
                    }}
                    type="checkbox"
                />
            </div>
        ),
    },
    {
        key: 'mid',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">MID</div>
            </div>
        ),
        width: 50,
    },
    {
        key: 'name',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Name</div>
            </div>
        ),
        width: 200,
    },
    {
        key: 'email',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Email</div>
            </div>
        ),
        width: 200,
    },

    {
        key: 'lastLogin',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Last Logged In</div>
            </div>
        ),
        render: (_, i) => (
            <div>
                {i?.lastLogin ? getDateFormat(i.lastLogin) : "Hasn't logged in"}
            </div>
        ),
    },
    {
        key: 'status',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Status</div>
            </div>
        ),
        render: (_, i) => <div>{GetStatus(i?.isDeleted)}</div>,
    },
    {
        key: 'updatedBy',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Last Modified By</div>
            </div>
        ),
        render: (_, i) => <div>{i.updatedBy}</div>,
    },
    {
        key: 'createdAt',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Created</div>
            </div>
        ),
        render: (_, i) => <div>{getFirstDateFormat(i.createdAt)}</div>,
        width: 200,
    },
    {
        key: 'edit',
        title: 'Edit',
        width: 40,
        render: (_, i) => (
            <SvgIcon
                className="cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation();
                    if (i?.isDeleted) return;
                    setIsEditUser(true);
                    setUserData(i);
                    setIsOpen(true);
                }}
                icon="EDIT_ICON"
            />
        ),
    },
    {
        key: 'disable',
        title: 'Disable',
        width: 40,
        render: (_, i) => (
            <Switch
                handleCheck={() => {
                    updateUserStatus(i._id, { isActive: !i.isDeleted })
                        .then((res) => {
                            if (res.isSuccess) {
                                updatedUserStatus(i._id, !i.isDeleted);
                                showToast(
                                    res.message || 'Successfully updated'
                                );
                            }
                        })
                        .catch((err) => {
                            showToast(
                                err?.errors?.[0]?.message ||
                                    'something went wrong',
                                'error'
                            );
                        });
                }}
                isToggled={i.isDeleted}
                className="rounded-2xl bg-gray-200"
            />
        ),
    },
];
