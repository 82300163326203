import { IColumnType } from 'components/Common/Table/Table';
import { getFirstDateFormat } from 'data/utils/common';
import { IAuditData } from '../Audit.type';

export const AuditTableColumn = (): IColumnType<IAuditData>[] => [
    {
        key: '_id',
        title: (
            <div className="flex items-center justify-center">
                <div className="ml-2">ID</div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'level',
        title: (
            <div className="flex justify-center">
                <div>Level</div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'source',
        title: (
            <div className="flex justify-center">
                <div>Source</div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'createdAt',
        title: (
            <div className="flex items-center justify-center">
                <div className="ml-2">Created</div>
            </div>
        ),
        width: 80,
        render: (_, i) => <div>{getFirstDateFormat(i?.createdAt)}</div>,
    },
    {
        key: 'userName',
        title: (
            <div className="flex items-center justify-center">
                <div className="ml-2">User</div>
            </div>
        ),
        width: 100,
    },
    {
        key: 'merchantId',
        title: (
            <div className="flex items-center justify-center">
                <div className="ml-2">MID</div>
            </div>
        ),
        width: 80,
    },
    {
        key: 'updatedBy',
        title: (
            <div className="flex items-center justify-center">
                <div className="ml-2">Page Modified</div>
            </div>
        ),
        width: 80,
    },
    {
        key: 'page',
        title: (
            <div className="flex justify-center">
                <div>Page Name</div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'ipAddress',
        title: (
            <div className="flex justify-center">
                <div>IP Address</div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'httpMethod',
        title: (
            <div className="flex justify-center">
                <div>HTTP Method</div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'responseCode',
        title: (
            <div className="flex justify-center">
                <div>ResponseCode</div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'url',
        title: (
            <div className="flex justify-center">
                <div>URL</div>
            </div>
        ),
        width: 70,
    },
    {
        key: 'action',
        title: (
            <div className="flex">
                <div>Comment</div>
            </div>
        ),
        width: 300,
    },
];
