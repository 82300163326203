import { z, ZodType } from 'zod';
import { IPayModumePayOutType } from './MerchantPayout.type';

export const payModumFormValidation: ZodType<IPayModumePayOutType> = z
    .object({
        userBalance: z
            .string({
                required_error: 'Please enter your total balance',
            })
            .trim()
            .min(1, 'Please enter your total balance'),
        country: z
            .string({ required_error: 'Please select your country' })
            .trim()
            .min(1, 'Please select your country'),
        kycStatus: z.boolean(),
        blocked: z.boolean(),
        brandId: z
            .string({
                required_error: 'Please enter your brandId number',
            })
            .trim()
            .min(1, 'Please enter your brandId number'),
        card_number: z
            .string({
                required_error: 'Please enter your card number',
            })
            .trim()
            .min(1, 'Please enter your card number')
            .max(16, 'Card number must be a maximum of 16 '),
        tags: z.array(z.string()).optional().nullable(),
        bonusCode: z.string().optional().nullable(),
        name: z
            .string({
                required_error: 'Please enter your card holder name',
            })
            .trim()
            .min(1, 'Please enter your card holder name'),
        expiry: z
            .string({
                required_error: 'Please enter your card expiry date',
            })
            .trim()
            .min(1, 'Please enter your card expiry date'),
        cvv: z
            .string({ required_error: 'Please enter your cvv number' })
            .trim()
            .min(1, 'Please enter your cvv number')
            .max(3, 'CVV number must be a maximum of 3'),
        total: z
            .string({ required_error: 'Please enter your amount' })
            .trim()
            .min(1, 'Please enter your amount'),
        currency: z
            .string({ required_error: 'Please select your currency' })
            .trim()
            .min(1, 'Please select your currency'),
        firstName: z
            .string({ required_error: 'Please enter your first name' })
            .trim()
            .min(1, 'Please enter your first name'),
        lastName: z
            .string({ required_error: 'Please enter your last name' })
            .trim()
            .min(1, 'Please enter your last name'),
        phone: z
            .string({ required_error: 'Please enter your phone number' })
            .min(6, 'phone number allows the min 6 digit'),
        dateOfBirth: z
            .string({
                required_error: 'Please enter your birth date',
            })
            .trim()
            .min(1, 'Please enter your birth date'),
        gender: z
            .string({ required_error: 'Please select your gender' })
            .trim()
            .min(1, 'Please select your gender'),
        line1: z
            .string({ required_error: 'Please enter your address' })
            .trim()
            .min(1, 'Please enter your address'),
        postalcode: z
            .string({
                required_error: 'Please enter your postal code',
            })
            .trim()
            .min(1, 'Please enter your postal code'),
        email: z
            .string({ required_error: 'Please enter your email' })
            .trim()
            .min(1, 'Please enter your email')
            .email({ message: 'Email is invalid type' }),
        emailVerification: z
            .string({ required_error: 'Please enter your email' })
            .trim()
            .min(1, 'Please enter your email ')
            .email({ message: 'Email is invalid type' }),
        verificationDate: z
            .string({
                required_error: 'Please enter your verification date',
            })
            .trim()
            .min(1, 'Please enter your verification date'),
        partnerName: z
            .string({
                required_error: 'Please enter your partner name',
            })
            .trim()
            .min(1, 'Please enter your partner name'),
        state: z
            .string({ required_error: 'Please select your state' })
            .trim()
            .min(1, 'Please select your state'),
        city: z
            .string({ required_error: 'Please select your city' })
            .trim()
            .min(1, 'Please select your city'),
        paymentMethod: z
            .string({
                required_error: 'Please select your card type',
            })
            .trim()
            .min(1, 'Please select your card type'),
        providerTransactionId: z
            .string({
                required_error: 'Please select your provider Id',
            })
            .trim()
            .min(1, 'Please select your provider Id'),
    })
    .required()
    .refine((data) => parseFloat(data.userBalance) > parseFloat(data.total), {
        message: 'User balance must be more than total amount',
        path: ['userBalance'],
    });
