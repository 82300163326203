export enum UserRoles {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export enum BulkActionTypes {
    DELETE = 'Delete',
    DISABLE = 'Disable',
    BULK_ACTION = 'Bulk Changes',
}
