import { useState } from 'react';
import { get } from 'data/utils/common';
import { IColumnType } from './Table';
import './table.css';

interface Props<T> {
    data: T[];
    columns: IColumnType<T>[];
    isSidebarTable?: boolean;
    isAuditHistoryTable?: boolean;
    isAdminUserModal?: boolean;
    isTransactionDetailsTable?: boolean;
    handleRowClick: (item: any) => void;
    colSpan: number;
}

export function TableRow<T>({
    data,
    columns,
    isAdminUserModal,
    isSidebarTable,
    isAuditHistoryTable,
    isTransactionDetailsTable,
    handleRowClick,
    colSpan,
}: Props<T>): JSX.Element {
    const [, setOpenAdminModal] = useState<boolean>(false);

    return (
        <>
            {!!data?.length &&
                data.map((item: any, itemIndex) => (
                    <tr
                        key={`table-body-${itemIndex}`}
                        className={`border border-solid border-l-0 border-t-0 last:border-b-0 border-r-0 border-[#E3E3E3] tableCell ${
                            isAuditHistoryTable
                                ? 'border border-solid border-l-0 border-t-0 last:border-b-0 border-r-0 border-[#BDD864] bg-[#F8FBEA] tableCell'
                                : isAdminUserModal || isTransactionDetailsTable
                                ? 'cursor-pointer'
                                : ''
                        } `}
                    >
                        {columns.map((column, columnIndex) => {
                            const value = get(item, column.key, '');
                            const disableColumn =
                                column.key === 'edit' ||
                                column.key === 'disable' ||
                                column.key === 'delete';

                            return (
                                <td
                                    onClick={() => {
                                        if (!disableColumn) {
                                            setOpenAdminModal(true);
                                            handleRowClick(item);
                                        }
                                    }}
                                    key={`table-row-cell-${columnIndex}`}
                                    className={`!text-center ${
                                        isSidebarTable
                                            ? 'border-none pl-7 !text-start'
                                            : isAuditHistoryTable
                                            ? 'border border-solid border-l-0  border-opacity-0 border-t-0 last:border-r-0 !border-[#BDD864] !text-center tableRow'
                                            : `border border-solid border-l-0 border-t-0 last:border-r-0 text-center${
                                                  column.render
                                                      ? '!text-center'
                                                      : '!text-start'
                                              }  border-b-0 border-[#E3E3E3] tableRow`
                                    } `}
                                >
                                    {column.render
                                        ? column.render(column, item)
                                        : value}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            {!data?.length && (
                <tr>
                    <td colSpan={colSpan} className="py-4 text-center">
                        No Data Found
                    </td>
                </tr>
            )}
        </>
    );
}
