import { IIcons } from 'data/common';
import { z, ZodType } from 'zod';
import {
    ConfigurationType,
    ICleoConfigurationType,
} from './configuration.type';

export const validate = (key: string, value: string) => {
    switch (key) {
        case 'username':
            if (!value) {
                return 'Please enter apiKey';
            }
            return '';
        case 'password':
            if (!value) {
                return 'Please enter apiKey';
            }
            return '';
        case 'apiKey':
            if (!value) {
                return 'Please enter apiKey';
            }
            return '';
        case 'apiUrl':
            if (!value) {
                return 'Please enter apiUrl';
            }
            return '';
        case 'apiTest':
            if (!value) {
                return 'Please enter apiTest';
            }
            return '';
        case 'comment':
            if (!value) {
                return 'Please enter comment';
            }
            return '';
        default:
            return '';
    }
};

export const ProvidersList: Array<{
    name: string;
    value: string;
    logo?: keyof IIcons;
}> = [
    { name: 'Cleo', value: 'CLEO', logo: 'CLEO' },
    { name: 'Trust Payments', value: 'TRUST', logo: 'CLEO' },
    { name: 'PayModum', value: 'PAYMODUM', logo: 'CLEO' },
];

export const cleoFormSchemaValidation: ZodType<ICleoConfigurationType> = z
    .object({
        apiKey: z
            .string({ required_error: 'Please enter your apiKey' })
            .trim()
            .min(1, 'Please enter your apiKey'),
        apiUrl: z
            .string({ required_error: 'Please enter your apiUrl' })
            .trim()
            .min(1, 'Please enter your apiUrl'),
        comment: z
            .string({ required_error: 'Please enter your comment' })
            .optional()
            .nullable(),
        apiTest: z
            .string({ required_error: 'Please enter your apiTest' })
            .trim()
            .min(1, 'Please enter your apiTest'),
        username: z
            .string({ required_error: 'Please enter your username' })
            .trim()
            .min(1, 'Please enter your username'),
        password: z
            .string({ required_error: 'Please enter your password' })
            .trim()
            .min(1, 'Please enter your password'),
    })
    .required();

export const formSchemaValidation: ZodType<ConfigurationType> = z
    .object({
        apiUrl: z
            .string({ required_error: 'Please enter your apiUrl' })
            .trim()
            .min(1, 'Please enter your apiUrl'),
        comment: z
            .string({ required_error: 'Please enter your comment' })
            .optional()
            .nullable(),
        username: z
            .string({ required_error: 'Please enter your username' })
            .trim()
            .min(1, 'Please enter your username'),
        password: z
            .string({ required_error: 'Please enter your password' })
            .trim()
            .min(1, 'Please enter your password'),
    })
    .required();
