import React, { useEffect, useState } from 'react';
import { deletedGroupRule, getAllGroupRulesByCompany } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import RuleEngineDrawer from './GroupRuleEngine/RuleEngineDrawer';
import GroupRuleEngine from './GroupRuleEngine';
import CardPaymentRuleEngine from './CardPaymentRuleEngine';

const RuleEngine = () => {
    const { auth } = useAuthContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeGroup, setActiveGroup] = useState<string>('');
    const [isActiveGroup, setIsActiveGroup] = useState<boolean>(false);
    const [groupRuleData, setGroupRuleData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string>(
        auth?.isSuperAdmin
            ? auth.selectedCompany?._id
            : auth?.authUser?.company || auth?.selectedCompany?._id
    );

    const openDrawer = () => {
        setIsOpen(!isOpen);
    };

    const handleDeleteGroupRule = () => {
        if (!activeGroup) return;
        setIsActiveGroup(true);
        deletedGroupRule({ groupId: activeGroup })
            .then(() => {
                setIsActiveGroup(false);
                showToast('Successfully deleted', 'success');
                getGroupRules();
            })
            .catch((err) => {
                setIsActiveGroup(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    const getGroupRules = () => {
        setLoading(true);
        if (!selectedCompanyId) {
            setLoading(false);
            return;
        }
        const payload = {
            companyId: selectedCompanyId,
            brandId: auth?.selectedBrand?._id,
        };
        if (!auth?.selectedBrand?._id) {
            delete payload.brandId;
        }

        getAllGroupRulesByCompany(payload)
            .then((res) => {
                const sortedData = res?.groupRuleDataByCompany.sort(
                    (a: any, b: any) => a.order - b.order
                );
                setGroupRuleData(sortedData);
                setActiveGroup(
                    auth?.activeGroup || res?.groupRuleDataByCompany?.[0]?._id
                );
                setIsActiveGroup(false);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setIsActiveGroup(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (!selectedCompanyId) return;
        getGroupRules();
    }, [selectedCompanyId, auth?.selectedBrand]);

    useEffect(() => {
        let companyId = '';
        if (auth.isSuperAdmin) {
            companyId = auth.selectedCompany?._id;
        } else {
            companyId = auth?.authUser?.company || '';
        }
        setSelectedCompanyId(companyId);
    }, [auth?.selectedCompany, auth?.authUser, isOpen, isActiveGroup]);

    return (
        <div className="flex items-start">
            <div>
                <GroupRuleEngine
                    setIsOpen={setIsOpen}
                    setActiveGroup={setActiveGroup}
                    activeGroup={activeGroup}
                    setGroupRuleData={setGroupRuleData}
                    groupRuleData={groupRuleData}
                    loading={loading}
                />
            </div>
            {isOpen && (
                <RuleEngineDrawer
                    openDrawer={openDrawer}
                    isOpen={isOpen}
                    groupRuleData={groupRuleData}
                    getGroupRules={getGroupRules}
                />
            )}
            <div className="w-full">
                <CardPaymentRuleEngine
                    groupId={activeGroup}
                    isActiveGroup={isActiveGroup}
                    handleDeleteGroupRule={handleDeleteGroupRule}
                    groupRuleData={groupRuleData}
                />
            </div>
        </div>
    );
};
export default RuleEngine;
