import { Dispatch, SetStateAction } from 'react';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import { Modal, PrimaryButton, SvgIcon } from 'components/Common';

interface IModalProps {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    handleVerifyOTP: () => void;
    otp: string;
    setOtp: Dispatch<SetStateAction<string>>;
    otpError: string;
}

const AuthenticationModal = ({
    setIsOpen,
    handleVerifyOTP,
    setOtp,
    otp,
    otpError,
}: IModalProps) => {
    const digitLeft = 6 - otp.length;

    return (
        <Modal title={<SvgIcon icon="GOOGLE_LOGO" />} setIsOpen={setIsOpen}>
            <div className="p-2 pt-0">
                <div className="mt-1 flex justify-center text-xl font-extrabold text-green-700">
                    Two-Factor Authentication - A Breeze!
                </div>
                <div className="flex items-start justify-center text-sm font-extrabold">
                    Enter 6-digit code from your two factor authentication APP.
                </div>
                <div className="mt-8 flex flex-col">
                    <OtpInput
                        value={otp}
                        onChange={(value: string) => setOtp(value)}
                        numInputs={6}
                        renderSeparator={
                            <span style={{ marginLeft: '13px' }} />
                        }
                        renderInput={(props) => (
                            <input
                                id="inputOtp"
                                {...props}
                                style={{
                                    width: '53px',
                                    height: '55px',
                                    color: 'text-green-600',
                                    background: '#F7F7F7',
                                    border:
                                        otpError && otp.length < 6
                                            ? '1px solid red'
                                            : otp
                                            ? props.value
                                                ? '2px solid #9ED79F'
                                                : 'none'
                                            : 'none',
                                    borderRadius: 15,
                                    fontSize: '30px',
                                    fontWeight: 500,
                                    textAlign: 'center',
                                    outline: props.value
                                        ? '3px solid #9ED79F'
                                        : '',
                                }}
                            />
                        )}
                    />
                    {otpError && otp.length < 6 && (
                        <div className="mt-1 flex justify-center text-sm text-red-500">
                            {otpError}
                        </div>
                    )}
                </div>

                <PrimaryButton
                    onClick={() => handleVerifyOTP()}
                    type="submit"
                    name={
                        digitLeft === 0
                            ? `Let's go`
                            : `${digitLeft} Digits Left`
                    }
                    color="#2E672F"
                    variant="filled"
                    className={classNames(
                        'flex justify-center items-center w-full h-10 rounded-xl  text-base font-bold mt-10',
                        digitLeft !== 0
                            ? '!bg-zinc-300 !text-gray-400'
                            : 'bg-green-700 text-white'
                    )}
                />
                <div className="mt-6 mb-3 flex items-center justify-center text-[12px]">
                    Need help? We can help
                    <a
                        className="pl-1 font-bold text-green-600 hover:text-green-600"
                        href="/"
                    >
                        Contact us
                    </a>
                </div>
            </div>
        </Modal>
    );
};
export default AuthenticationModal;
