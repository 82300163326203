import { Dispatch, SetStateAction } from 'react';
import { BulkActionTypes } from 'data/constants/auth.constants';
import { ITransactions } from 'data/types/response';

export const PaymentStatus = [
    { name: 'All Payments', value: '' },
    { name: 'Suspended', value: 'SUSPENDED' },
    { name: 'Pending', value: 'PENDING' },
    { name: 'Success', value: 'SUCCESS' },
    { name: 'Expired', value: 'EXPIRED' },
    { name: 'Failed', value: 'FAILED' },
    { name: 'Error', value: 'ERROR' },
    { name: 'Captured', value: 'CAPTURED' },
];

export const PaymentApprovalsStatus = [
    { name: 'All Payments', value: '' },
    { name: 'Pending', value: 'PENDING' },
    { name: 'Paid', value: 'PAID' },
    { name: 'Error', value: 'ERROR' },
    { name: 'Failed', value: 'FAILED' },
];

export const Options = [
    { id: '', name: BulkActionTypes.BULK_ACTION },
    { id: 'v3', name: BulkActionTypes.DISABLE },
];

export interface IFilterType {
    status: string;
    search: string;
}

export interface ITransactionHeader {
    setFilterData: Dispatch<SetStateAction<IFilterType>>;
    filterData: IFilterType;
    setCheckedTransaction?: Dispatch<SetStateAction<Array<string>>>;
    checkedTransaction?: Array<string>;
    setIsTableFilter: Dispatch<SetStateAction<boolean>>;
}

export interface ITransactionTable {
    loading: boolean;
    currentPage: number;
    transactionData: Array<ITransactions>;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    tableFilterColumn: Array<IHeaderList>;
}

export interface IHeaderList {
    value: string;
    key: string;
    isChecked: boolean | undefined;
}

export interface ITransactionTableFilterModal {
    className: string;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    tableFilterColumn: Array<IHeaderList>;
}
