import { Control } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { BulkActionTypes } from 'data/constants/auth.constants';
import { IBrandsPermissions, IGroup, IPagePermissions } from 'data/common';

export const BulkOptions = [
    { id: '', name: BulkActionTypes.BULK_ACTION },
    { id: 'v3', name: BulkActionTypes.DISABLE },
];

export interface IGroupsHeaderProps {
    getGroups: () => void;
    checkedGroups: Array<string>;
    setCheckedGroups: Dispatch<SetStateAction<Array<string>>>;
    openDrawer: () => void;
}

export interface IGroupsDrawerProps {
    isOpen: boolean;
    openDrawer: () => void;
    loading: boolean;
    isEditGroupDrawer?: boolean;
    editGroup?: IGroup | null;
    userCompany: string;
    setGroupLoading?: Dispatch<SetStateAction<boolean>>;
    getGroups: () => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface IGroupType {
    groupName: string;
    companyId?: string | null;
    pageAreasPermissions: Array<IPagePermissions>;
    brandsPermissions: Array<IBrandsPermissions>;
}

export interface IGroupFormProps {
    onSubmit: () => void;
    loading: boolean;
    control: Control<IGroupType>;
    isEditGroupDrawer?: boolean;
    openDrawer: () => void;
    isSuperAdmin: boolean;
    userCompany?: string;
    isOpen: boolean;
    watch: any;
}

export interface IGroupTableProps {
    allGroups: Array<IGroup>;
    checkedGroups: Array<string>;
    setAllGroups: Dispatch<SetStateAction<Array<IGroup>>>;
    setCheckedGroups: Dispatch<SetStateAction<Array<string>>>;
    setEditGroupDetails: Dispatch<SetStateAction<IGroup | null>>;
    setUserCompany: Dispatch<SetStateAction<string>>;
    setIsEditGroupDrawer: Dispatch<SetStateAction<boolean>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}
