import React, { useEffect, useRef, useState } from 'react';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/Auth/AuthContext';
import { avatar } from 'assets';
import { showToast } from 'data/utils/toast';
import MultiLevelDropdown from 'components/MultiLevelDropdown/MultiLevelDropdown';
import { Dropdown } from '../index';
import SvgIcon from '../Icon/SvgIcon';

dayjs.extend(localizedFormat);

const Header = () => {
    const { auth } = useAuthContext();
    const isSuperAdmin = auth.isSuperAdmin;
    const navigate = useNavigate();
    const [openDropDown, setOpenDropDown] = useState(false);
    const [systemTime, setSystemTime] = useState(new Date());
    const [localTime, setLocalTime] = useState(new Date());
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            // If the dropdown is open and the click is outside the dropdown or on the dropdown itself, close it
            if (
                openDropDown &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropDown(false);
            }
        };

        // Add event listener when dropdown is open
        if (openDropDown) {
            document.addEventListener('mousedown', handleDocumentClick);
        }

        // Remove event listener when component is unmounted or dropdown is closed
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [openDropDown]);

    useEffect(() => {
        // Update system time every second
        const systemTimer = setInterval(() => {
            setSystemTime(new Date());
        }, 1000);

        // Update local time every second
        const localTimer = setInterval(() => {
            const now = new Date();
            const localOffset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
            setLocalTime(new Date(now.getTime() + localOffset));
        }, 1000);

        return () => {
            clearInterval(systemTimer);
            clearInterval(localTimer);
        };
    }, []);

    return (
        <div className="flex bg-white border border-x-0 border-t-0 border-b border-solid border-gray-200 gap-4 px-8 py-5 header justify-end">
            {(auth?.selectedBrand?.mid || auth?.selectedCompany?.mid) && (
                <CopyToClipboard
                    text={
                        auth?.selectedBrand?.mid ||
                        auth?.selectedCompany?.mid ||
                        ''
                    }
                    onCopy={(_text: any, result: any) => {
                        if (result) {
                            showToast('Copied', 'info');
                        }
                    }}
                >
                    <div className="flex justify-center items-center relative text-sm text-[#A4A4A4] min-w-40 w-auto p-3 rounded-lg bg-[#F5F5F5] cursor-pointer">
                        {auth?.selectedBrand?.mid ||
                            auth?.selectedCompany?.mid ||
                            ''}
                    </div>
                </CopyToClipboard>
            )}
            {isSuperAdmin && <MultiLevelDropdown />}
            {!auth?.isSuperAdmin && <MultiLevelDropdown />}
            <div className="flex items-center gap-6">
                <SvgIcon className="flex" icon="CLOCK_ICON" />
                <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-400">
                        Local time
                    </span>
                    <span className="text-sm font-medium text-green-200">
                        {dayjs(localTime).format('LTS')}
                    </span>
                </div>
                <div className="h-6 border border-y-0 border-l-0 border-solid border-gray-300" />
                <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-400">
                        System time
                    </span>
                    <span className="text-sm font-medium text-green-200">
                        {dayjs(systemTime).format('LTS')}
                    </span>
                </div>
                <div className="h-6 border border-y-0 border-l-0 border-solid border-gray-300" />
                <div
                    ref={dropdownRef}
                    className="flex items-center"
                    onClick={() => {
                        // Toggle the state based on the previous state
                        setOpenDropDown(
                            (prevOpenDropDown) => !prevOpenDropDown
                        );
                    }}
                >
                    <img src={avatar} alt="img" width="100%" height="100%" />
                    {openDropDown && (
                        <Dropdown
                            className="top-[70px] right-5 z-[100]"
                            submenus={[{ title: 'Logout', id: 1 }]}
                            handleClick={(e: any) => {
                                if (e === 'Logout') {
                                    localStorage.removeItem('token');
                                    navigate('/');
                                }
                                setOpenDropDown(false);
                            }}
                            dropdown={openDropDown}
                        />
                    )}
                    <SvgIcon icon="ARROW_DOWN" className="flex" />
                </div>
            </div>
        </div>
    );
};
export default Header;
