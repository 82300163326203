import { Layout } from 'components/Common';
import AuditPage from 'components/Audit';

const Audit = () => {
    return (
        <Layout>
            <AuditPage />
        </Layout>
    );
};
export default Audit;
