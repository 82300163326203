import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Loader, PrimaryButton, SvgIcon } from 'components/Common';
import ReactFlow, {
    addEdge,
    Background,
    Connection,
    ConnectionLineType,
    ConnectionMode,
    Edge,
    MarkerType,
    Panel,
    Position,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from 'reactflow';
import {
    getGroupRule,
    getViewRule,
    saveRule,
    updateRule,
} from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { ISaveRule } from 'data/types/request';
import RuleBuilderDrawer from './RuleBuilderDrawer/RuleBuilderDrawer';
import SimpleFloatingEdge from './SimpleFloatingEdges/SimpleFloatingEdge';
import { CustomNode } from './CustomeNode/CustomNode';
import { INodeType } from './RuleBuilder.type';
import 'reactflow/dist/style.css';
import './RuleBuilderDrawer/ruleBuilderDrawer.css';

const nodeTypes = {
    custom: CustomNode,
};
const edgeTypes = {
    floating: SimpleFloatingEdge,
};

const offsetY = 300; // Adjust this offset as needed

let nodeIdCounter = 0;

const RuleBuilder = () => {
    const navigate = useNavigate();

    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);

    const reactFlow = useReactFlow();
    const { zoomIn, zoomOut } = useReactFlow();
    const [searchParams] = useSearchParams();

    const dataId = searchParams.get('dataId');
    const GroupId = searchParams.get('groupsId');
    const order = searchParams.get('order');

    const [loading, setLoading] = useState<boolean>(false);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [isConditionOpen, setIsConditionOpen] = useState<boolean>(false);
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
    const [ruleTitle, setRuleTitle] = useState<string>('');
    const [selectNode, setSelectNode] = useState<string>('');
    const [captureElementNode, setCaptureElementNode] = useState(null);
    const [groupId, setGroupId] = useState<string | undefined>('');
    const [isLoading, setIsLoading] = useState(false);
    const [groupCardData, setGroupCardData] = useState<any>([]);

    const onConnect = useCallback((params: Edge<any> | Connection) => {
        // Here, you can handle the connection logic
        connectingNodeId.current = null;
        setEdges((existingEdges) =>
            addEdge(
                {
                    ...params,
                    type: 'floating',
                    interactionWidth: 100,
                    markerStart: { type: MarkerType.ArrowClosed },
                    markerEnd: { type: MarkerType.Arrow },
                },
                existingEdges
            )
        );
    }, []);

    const onConnectStart = useCallback((event: any, { nodeId }: any) => {
        connectingNodeId.current = nodeId;
        if (!connectingNodeId.current) return;
        const targetIsPane =
            event.target.classList.contains('react-flow__handle');

        if (targetIsPane) {
            setIsOpenDrawer(true);
        }
    }, []);

    const generateTitle = (index: number) => {
        // Add 1 to the index since indexing usually starts from 0
        const titleIndex = index + 1;

        // Generate the title based on the index
        return `R_${titleIndex < 10 ? `0${titleIndex}` : `${titleIndex}`}`;
    };

    useEffect(() => {
        if (!selectNode) return;
        const filterNode = nodes.filter((v) => v.id !== selectNode);
        reactFlow.setEdges((li) => li.filter((edge) => edge.id !== selectNode));
        reactFlow.setEdges((li) =>
            li.filter((edge) => edge.target !== selectNode)
        );
        setNodes(filterNode);
        setSelectNode('');
    }, [selectNode]);

    const getRuleData = () => {
        if (!GroupId) return;
        getGroupRule(GroupId)
            .then((res) => {
                const sortedData = res?.rules.sort(
                    (a: any, b: any) => a.order - b.order
                );
                setGroupCardData(sortedData);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };
    useEffect(() => {
        getRuleData();
    }, []);

    useEffect(() => {
        const ruleTitleNumber = groupCardData.map((item: any) =>
            parseInt(item?.name?.split('_')[1])
        );

        const maxNumber = Math.max(...ruleTitleNumber);
        const minNumber = Math.min(...ruleTitleNumber);

        const allNumbers = Array.from(
            { length: maxNumber - minNumber + 1 },
            (_, i) => i + minNumber
        );

        const missingRuleNumbers = allNumbers.filter(
            (num) => !ruleTitleNumber.includes(num)
        );

        const addRuleNumber =
            missingRuleNumbers.length > 0
                ? Number(missingRuleNumbers[0]) - 1
                : Number(order);
        const ruleName = generateTitle(addRuleNumber);
        setRuleTitle(ruleName);
    }, [groupCardData]);

    const lastNode = nodes.at(-1);
    const handleOnDelete = (event: any) => {
        setSelectNode(event);
        setCaptureElementNode(null);
    };

    const onAdd = useCallback(
        (text: string, type: string) => {
            nodeIdCounter += 1; // Increment the counter by 1
            if (nodes.some((node) => node.data.label === text)) {
                // Show an error message or take appropriate action
                showToast('Same rule already exist', 'error');
                // You can return here or handle it as per your application logic
                return;
            }
            const nodeId = nodes.length === 0 ? 1 : nodes.length;
            if (!!text && !!type) {
                const newNode: INodeType = {
                    key: String(nodeId),
                    id: String(nodeId),
                    data: {
                        label: text,
                        type,
                        id: String(nodeId),
                        items: undefined,
                        operation: undefined,
                        onClick: handleOnDelete,
                    },
                    position: {
                        x:
                            lastNode?.position?.x !== undefined
                                ? Number(lastNode.position.x) + 250
                                : nodeIdCounter * 250,
                        y: offsetY,
                    },
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    type: 'custom',
                    extent: connectingNodeId.current ? 'parent' : undefined,
                    isParent: !!connectingNodeId.current,
                };
                setIsConditionOpen(true);
                setCaptureElementNode(newNode as any);
                setIsOpenDrawer(true);
                setNodes((nds) => nds.concat({ ...newNode }));
                setEdges((eds: Edge<any>[]) => {
                    const newEdge: Edge<any> = {
                        id: String(nodeId),
                        source: connectingNodeId.current ?? '0',
                        target: String(nodeId),
                        type: 'floating',
                        interactionWidth: 2,
                        markerEnd: { type: MarkerType.Arrow, color: '#000000' },
                        markerStart: {
                            type: MarkerType.ArrowClosed,
                            color: '#000000',
                        },
                        style: { stroke: '#000000' },
                    };
                    return [...eds, newEdge];
                });
            }
        },
        [nodes]
    );

    const handleAdd = () => {
        if (nodes.length === 0) {
            const defaultNode = {
                key: '0',
                id: '0',
                type: 'input',
                sourcePosition: Position.Right,
                data: {
                    label: 'Start',
                    id: 0,
                },
                position: { x: 800, y: 300 },
            };
            setNodes((nds) => nds.concat(defaultNode));
        }
        setIsOpenDrawer(true);
    };

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (event.key === '=' || event.key === '+') {
                handleAdd();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [nodes, setNodes]);

    const openDrawer = () => {
        setIsOpenDrawer(!isOpenDrawer);
        setIsConditionOpen(false);
    };

    const onNodeDragStop = useCallback(
        (_: any, node: { id: string; position: { x: any; y: any } }) => {
            setNodes((prevNodes) =>
                prevNodes.map((n) => {
                    if (n.id === node.id) {
                        return {
                            ...n,
                            position: {
                                x: node.position.x,
                                y: node.position.y,
                            },
                        };
                    }
                    return n;
                })
            );
        },
        [setNodes]
    );

    const onNodeClick = (event: any, node: any) => {
        if (selectNode) return;
        setCaptureElementNode(node);
        setIsOpenDrawer(true);
        setIsConditionOpen(true);
    };

    const getRule = () => {
        if (!dataId) {
            setGroupId(GroupId as string);
            return;
        }
        setLoading(true);
        getViewRule(dataId)
            .then((res: any) => {
                const parsedData = JSON.parse(res?.rule?.data);
                setRuleTitle(res?.rule?.name);
                setNodes(parsedData?.nodes);
                setEdges(parsedData?.edges);
                setGroupId(res?.rule?.groupId);
                setLoading(false);
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getRule();
    }, []);

    const handleSaveRule = () => {
        if (nodes.length === 0) {
            showToast('Please select your build rule first!', 'error');
            return;
        }
        if (!ruleTitle) {
            showToast('Please enter your rule title.', 'error');
            return;
        }
        const combineData = { nodes, edges };
        const pspType = (nodes || []).find((v) => v.data.label === 'PSP');
        const { items = 'CLEO' } = pspType?.data || {};
        const dataToSave: ISaveRule = {
            name: ruleTitle,
            action: items,
            data: JSON.stringify(combineData),
            groupId,
            order: parseInt(order as string),
        };
        if (!groupId) return;
        setIsLoading(true);
        if (GroupId) {
            saveRule(dataToSave)
                .then((res: any) => {
                    showToast(res?.message, 'success');
                    setIsLoading(false);
                    navigate('/admin/rule-engine');
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        } else if (dataId) {
            delete dataToSave.order;
            updateRule(dataToSave, dataId)
                .then((res: any) => {
                    showToast(res?.message, 'success');
                    setIsLoading(false);
                    navigate('/admin/rule-engine');
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        }
    };

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <div className="flex !bg-[#2E672F] p-8 gap-10 items-center justify-between relative !z-10">
                <div className="flex items-center gap-6">
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() => navigate('/admin/rule-engine')}
                    >
                        <SvgIcon className="!h-6" icon="ARROW_RIGHT" />
                        <div className="text-xl leading-7	font-bold text-[#8FB131]">
                            Back
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <input
                            readOnly
                            className="text-lg bg-transparent !border-none focus:!border-none p-0 hover:!border-none focus:bg-[#2E672F] hover:bg-[#2E672F] font-bold text-white leading-[22px]"
                            value={ruleTitle}
                            maxLength={50}
                        />
                    </div>
                </div>

                <Panel
                    className="m-0 flex items-center gap-6 p-6"
                    position="top-right"
                >
                    <div className="flex items-center gap-6 text-center">
                        <div className="flex items-center gap-2 p-1 text-center">
                            <div className="text-xs text-white">Zoom</div>
                            <SvgIcon
                                className="h-8"
                                icon="ZOOM_IN"
                                onClick={() => zoomIn()}
                            />
                            <SvgIcon
                                className="h-8"
                                icon="ZOOM_OUT"
                                onClick={() => zoomOut()}
                            />
                        </div>

                        <PrimaryButton
                            loading={isLoading}
                            disabled={isLoading}
                            color="#8FB131"
                            variant="filled"
                            name="Save and Update"
                            isDrawerButton
                            onClick={handleSaveRule}
                        />
                    </div>
                </Panel>
            </div>
            {loading ? (
                <div className="mt-5 flex justify-center simple-floatingedges">
                    <Loader />
                </div>
            ) : (
                <div className="simple-floatingedges" ref={reactFlowWrapper}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onNodeDragStop={onNodeDragStop}
                        onConnect={onConnect}
                        edgeTypes={edgeTypes}
                        nodeTypes={nodeTypes}
                        onConnectStart={onConnectStart}
                        connectionLineType={ConnectionLineType.SmoothStep}
                        connectionMode={ConnectionMode.Loose}
                        onNodeClick={onNodeClick}
                        className="react-flow-subflows-example h-[calc(100vh-100px)]"
                    >
                        {nodes.length < 1 && (
                            <Panel
                                position="top-center"
                                className="react-flow-start"
                            >
                                <div className="flex flex-col items-center gap-6 text-center">
                                    <div
                                        onClick={() => handleAdd()}
                                        className="flex w-full items-center justify-center gap-2 rounded bg-black p-3 text-center text-white max-w-[220px]"
                                    >
                                        <div className="text-center text-xs	font-bold">
                                            Start
                                        </div>
                                        <SvgIcon
                                            icon="ADD_ICON"
                                            className="cursor-pointer h-[20px]"
                                        />
                                    </div>
                                    <div className="text-lg font-bold leading-[22px]">
                                        Hit the “+” button to start building
                                        your first rule logic
                                    </div>
                                </div>
                            </Panel>
                        )}
                        {isOpenDrawer && (
                            <div className="flex justify-center h-[600px]">
                                <RuleBuilderDrawer
                                    isOpen={isOpenDrawer}
                                    openDrawer={openDrawer}
                                    isCondition={isConditionOpen}
                                    onAdd={onAdd}
                                    captureElementNode={captureElementNode}
                                    setIsOpenDrawer={setIsOpenDrawer}
                                    setCaptureElementNode={
                                        setCaptureElementNode
                                    }
                                    setIsConditionOpen={setIsConditionOpen}
                                />
                            </div>
                        )}
                        <Background />
                    </ReactFlow>
                </div>
            )}
        </div>
    );
};

export default RuleBuilder;
