import { ReactNode } from 'react';
import './index.css';

interface TooltipProps {
    text?: string;
    children: ReactNode;
    className?: string | undefined;
}

const Tooltip = ({ text = '', children, className = '' }: TooltipProps) => {
    return (
        <div className={`tooltip-container ${className}`}>
            {children}
            <div className="tooltip-text">{text}</div>
        </div>
    );
};

export default Tooltip;
