import { SvgIcon } from 'components/Common';

const SelectProviderPage = () => {
    return (
        <div className="flex items-center justify-center text-center h-[calc(100vh-121px)]">
            <div className="flex flex-col gap-6">
                <SvgIcon icon="SELECT_PROVIDER" />
                <div className="text-4xl font-extrabold text-green-600">
                    Select provider
                </div>
            </div>
        </div>
    );
};
export default SelectProviderPage;
