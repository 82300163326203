import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';
import { PrimaryButton, TextField, Select } from 'components/Common';
import { validate } from 'data/utils/common';
import { processPayment } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import DatePicker from 'components/Common/DatePicker/DatePicker';
import { Countries } from 'data/constants/country.constants';
import { GenderList } from 'data/constants/gender.constants';

const TripleZero = () => {
    const [data, setData] = useState<{
        [key: string]: string;
    }>({
        card_number: '',
        name: '',
        expiry: '',
        cvv: '',
        total: '',
        currency: '',
        merchant: '',
        prefix: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        dateOfBirth: '',
        gender: '',
        line1: '',
        line2: '',
        postalcode: '',
        city: '',
        region: '',
        country: '',
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [validationErrors, setValidationError] = useState<{
        [p: string]: string | number;
    }>({});

    const onHandlerChange = (
        e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const onSubmit = () => {
        const errors: {
            [p: string]: string | number;
        } = {};
        Object.keys(data).forEach((key: string) => {
            const isErrors = validate(key, data[key]);
            if (isErrors?.length) {
                errors[key] = isErrors;
            }
        });

        if (Object.keys(errors)?.length) {
            setValidationError(errors as any);
        } else {
            setLoading(true);
            const payload = {
                card: {
                    expiry: {
                        month: String(
                            new Date(data.expiry).getMonth() + 1
                        ).padStart(2, '0'),
                        year: data.expiry.split('/')[1],
                    },
                    pan: data?.card_number,
                    cvv: data?.cvv,
                    name_on_card: data?.name,
                },
                order: {
                    total: {
                        amount: data?.total,
                        currency: data?.currency,
                    },
                    merchant_ref: data?.merchant,
                },
                card_holder: {
                    name: {
                        prefix: data?.prefix,
                        first: data?.firstName,
                        last: data?.lastName,
                    },
                    date_of_birth: data?.dateOfBirth,
                    gender: data?.gender,
                    contact: {
                        email: data?.email,
                        phone: data?.phone,
                    },
                    billing_address: {
                        line1: data?.line1,
                        line2: data?.line2 || '',
                        postal_code: data?.postalcode,
                        city: data?.city,
                        region: data?.region,
                        country: data?.country,
                    },
                },
            };

            processPayment(payload)
                .then((res) => {
                    showToast(res?.message, 'success');
                    setLoading(false);
                    setData({
                        card_number: '',
                        name: '',
                        expiry: '',
                        cvv: '',
                        total: '',
                        currency: '',
                        merchant: '',
                        prefix: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        dateOfBirth: '',
                        gender: '',
                        line1: '',
                        line2: '',
                        postalcode: '',
                        city: '',
                        region: '',
                        country: '',
                    });
                })
                .catch((err) => {
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        }
    };

    return (
        <div>
            <div className="mt-3 mb-4 text-base font-bold text-black">
                Card Details
            </div>
            <TextField
                fullWidth
                name="card_number"
                label="Card Number"
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onHandlerChange(e);
                }}
                className={classNames(
                    'text-gray-400',
                    validationErrors?.card_number
                        ? 'bg-red-300 border-0 '
                        : 'text-gray-400 bg-gray-200'
                )}
                isError={!!validationErrors?.card_number}
                errorMessage={validationErrors?.card_number ?? ''}
                value={data.card_number}
            />
            <TextField
                fullWidth
                label="Name"
                name="name"
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onHandlerChange(e);
                }}
                className={classNames(
                    'text-gray-400',
                    validationErrors?.name
                        ? 'bg-red-300 border-0 '
                        : 'text-gray-400 bg-gray-200'
                )}
                isError={!!validationErrors?.name}
                errorMessage={validationErrors?.name ?? ''}
                value={data.name}
            />
            <div className="grid grid-cols-5 gap-6">
                <div className="col-span-3">
                    <DatePicker
                        label="Expiry (MM/YY)"
                        options={{
                            allowInput: true,
                            dateFormat: 'm/y',
                        }}
                        onChange={(selectedDates: any, dateStr: any) => {
                            setData({
                                ...data,
                                expiry: dateStr,
                            });
                        }}
                        isError={!!validationErrors?.expiry}
                        name=""
                    />
                </div>
                <div className="col-span-2">
                    <TextField
                        fullWidth
                        label="CVV"
                        name="cvv"
                        type="number"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onHandlerChange(e);
                        }}
                        className={classNames(
                            'text-gray-400',
                            validationErrors?.cvv
                                ? 'bg-red-300 border-0 '
                                : 'text-gray-400 bg-gray-200'
                        )}
                        isError={!!validationErrors?.cvv}
                        errorMessage={validationErrors?.cvv ?? ''}
                        value={data.cvv}
                    />
                </div>
            </div>
            <div className="mt-3 mb-4 text-base font-bold text-black">
                Order Details
            </div>
            <div className="grid grid-cols-5 gap-6">
                <div className="col-span-3">
                    <TextField
                        label="Total"
                        name="total"
                        type="number"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onHandlerChange(e);
                        }}
                        className={classNames(
                            'text-gray-400',
                            validationErrors?.total
                                ? 'bg-red-300 border-0 '
                                : 'text-gray-400 bg-gray-200'
                        )}
                        isError={!!validationErrors?.total}
                        errorMessage={validationErrors?.total ?? ''}
                        value={data.total}
                    />
                </div>
                <div className="col-span-2">
                    <TextField
                        label="Currency"
                        name="currency"
                        type="text"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onHandlerChange(e);
                        }}
                        className={classNames(
                            'text-gray-400',
                            validationErrors?.currency
                                ? 'bg-red-300 border-0 '
                                : 'text-gray-400 bg-gray-200'
                        )}
                        isError={!!validationErrors?.currency}
                        errorMessage={validationErrors?.currency ?? ''}
                        value={data.currency}
                    />
                </div>
            </div>
            <TextField
                fullWidth
                label="Merchant ref"
                name="merchant"
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onHandlerChange(e);
                }}
                value={data.merchant}
                isError={!!validationErrors?.merchant}
                errorMessage={validationErrors?.merchant ?? ''}
                className={classNames(
                    'text-gray-400',
                    validationErrors?.merchant
                        ? 'bg-red-300 border-0 '
                        : 'text-gray-400 bg-gray-200'
                )}
            />
            <div className="mt-3 mb-4 text-base font-bold text-black">
                Card Holder Details
            </div>
            <div className="grid grid-cols-5 gap-y-0 gap-6">
                <TextField
                    label="Title"
                    name="prefix"
                    type="text"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onHandlerChange(e);
                    }}
                    value={data.prefix}
                    className={classNames(
                        'text-gray-400',
                        validationErrors?.prefix
                            ? 'bg-red-300 border-0 '
                            : 'text-gray-400 bg-gray-200'
                    )}
                />
                <div className="col-span-2">
                    <TextField
                        name="firstName"
                        type="text"
                        label="First Name"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onHandlerChange(e);
                        }}
                        value={data.firstName}
                        isError={!!validationErrors?.firstName}
                        errorMessage={validationErrors?.firstName ?? ''}
                        className={classNames(
                            'text-gray-400',
                            validationErrors?.firstName
                                ? 'bg-red-300 border-0 '
                                : 'text-gray-400 bg-gray-200'
                        )}
                    />
                </div>
                <div className="col-span-2">
                    <TextField
                        name="lastName"
                        type="text"
                        label="Last Name"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onHandlerChange(e);
                        }}
                        value={data.lastName}
                        isError={!!validationErrors?.lastName}
                        errorMessage={validationErrors?.lastName ?? ''}
                        className={classNames(
                            'text-gray-400',
                            validationErrors?.lastName
                                ? 'bg-red-300 border-0 '
                                : 'text-gray-400 bg-gray-200'
                        )}
                    />
                </div>
                <div className="col-span-3">
                    <TextField
                        name="email"
                        type="text"
                        label="Email"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onHandlerChange(e);
                        }}
                        value={data.email}
                        isError={!!validationErrors?.email}
                        errorMessage={validationErrors?.email ?? ''}
                        className={classNames(
                            'text-gray-400',
                            validationErrors?.email
                                ? 'bg-red-300 border-0 '
                                : 'text-gray-400 bg-gray-200'
                        )}
                    />
                </div>
                <div className="col-span-2">
                    <TextField
                        name="phone"
                        type="text"
                        label="Phone"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onHandlerChange(e);
                        }}
                        value={data.phone}
                        isError={!!validationErrors?.phone}
                        errorMessage={validationErrors?.phone ?? ''}
                        className={classNames(
                            'text-gray-400',
                            validationErrors?.phone
                                ? 'bg-red-300 border-0 '
                                : 'text-gray-400 bg-gray-200'
                        )}
                    />
                </div>
                <div className="col-span-3">
                    <DatePicker
                        label="Date Of Birth"
                        placeholder="DD - MM - YYYY"
                        options={{
                            allowInput: true,
                            dateFormat: 'd-m-Y',
                        }}
                        onChange={(selectedDates: any, dateStr: any) => {
                            setData({
                                ...data,
                                verificationDate: dateStr,
                            });
                        }}
                        name="dateOfBirth"
                        isError={!!validationErrors?.dateOfBirth}
                    />
                </div>
                <div className="col-span-2">
                    <Select
                        isGroup
                        value={data?.gender}
                        options={GenderList}
                        handleOnSelect={(e: ChangeEvent<HTMLSelectElement>) => {
                            if (e.target.value === 'Select Gender') {
                                setData({
                                    ...data,
                                    gender: '',
                                });
                            } else {
                                setData({
                                    ...data,
                                    gender: e.target.value,
                                });
                            }
                        }}
                        isError={!!validationErrors?.gender}
                        errorMessage={validationErrors?.gender ?? ''}
                        label="Gender"
                        className={classNames(
                            'text-gray-400',
                            validationErrors?.gender
                                ? 'bg-red-300 border-0 '
                                : 'text-gray-400 bg-gray-200'
                        )}
                    />
                </div>
            </div>
            <div className="mt-3 mb-4 text-base font-bold text-black">
                Billing Address
            </div>
            <div className="grid grid-cols-2 gap-y-0 gap-6">
                <TextField
                    name="line1"
                    type="text"
                    label="Address Line 1"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onHandlerChange(e);
                    }}
                    value={data.line1}
                    isError={!!validationErrors?.line1}
                    errorMessage={validationErrors?.line1 ?? ''}
                    className={classNames(
                        'text-gray-400',
                        validationErrors?.line1
                            ? 'bg-red-300 border-0 '
                            : 'text-gray-400 bg-gray-200'
                    )}
                />
                <TextField
                    name="line2"
                    type="text"
                    label="Address Line 2"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onHandlerChange(e);
                    }}
                    value={data.line2}
                    isError={!!validationErrors?.line2}
                    errorMessage={validationErrors?.line2 ?? ''}
                    className={classNames(
                        'text-gray-400',
                        validationErrors?.line2
                            ? 'bg-red-300 border-0 '
                            : 'text-gray-400 bg-gray-200'
                    )}
                />
                <TextField
                    name="postalcode"
                    type="text"
                    label="Post Code / Zip Code"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onHandlerChange(e);
                    }}
                    value={data.postalcode}
                    isError={!!validationErrors?.postalcode}
                    errorMessage={validationErrors?.postalcode ?? ''}
                    className={classNames(
                        'text-gray-400',
                        validationErrors?.postalcode
                            ? 'bg-red-300 border-0 '
                            : 'text-gray-400 bg-gray-200'
                    )}
                />
                <TextField
                    name="city"
                    type="text"
                    label="City"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onHandlerChange(e);
                    }}
                    value={data.city}
                    isError={!!validationErrors?.city}
                    errorMessage={validationErrors?.city ?? ''}
                    className={classNames(
                        'text-gray-400',
                        validationErrors?.city
                            ? 'bg-red-300 border-0 '
                            : 'text-gray-400 bg-gray-200'
                    )}
                />
                <TextField
                    name="region"
                    type="text"
                    label="Region"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onHandlerChange(e);
                    }}
                    isError={!!validationErrors?.region}
                    errorMessage={validationErrors?.region ?? ''}
                    value={data.region}
                    className={classNames(
                        'text-gray-400',
                        validationErrors?.region
                            ? 'bg-red-300 border-0 '
                            : 'text-gray-400 bg-gray-200'
                    )}
                />
                <Select
                    isGroup
                    value={data.country}
                    isError={!!validationErrors?.country}
                    errorMessage={validationErrors?.country ?? ''}
                    options={Countries}
                    handleOnSelect={(e: ChangeEvent<HTMLSelectElement>) => {
                        if (e.target.value === 'Select Country') {
                            setData({
                                ...data,
                                country: '',
                            });
                        } else {
                            setData({
                                ...data,
                                country: e.target.value,
                            });
                        }
                    }}
                    label="Country"
                    className={classNames(
                        'text-gray-400',
                        validationErrors?.country
                            ? 'bg-red-300 border-0 '
                            : 'text-gray-400 bg-gray-200'
                    )}
                />
            </div>
            <div className="flex items-center justify-center mt-5">
                <PrimaryButton
                    onClick={onSubmit}
                    isDrawerButton
                    className={classNames('w-full max-w-[300px]')}
                    color="#2E672F"
                    variant="filled"
                    name="Submit"
                    loading={loading}
                />
            </div>
        </div>
    );
};

export default TripleZero;
