/* eslint-disable */
import React, { useRef } from 'react';
import DraggableList from 'react-draggable-list';
import LoadingSpinner from 'components/Common/Loader/Loader';
import { updateGroupOrder } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import GroupCard from 'components/Common/GroupCard/GroupCard';
import { IGroupRuleEngineProps } from '../RuleEngine.type';

const GroupRuleEngine = ({
    setIsOpen,
    activeGroup,
    setActiveGroup,
    setGroupRuleData,
    groupRuleData,
    loading,
}: IGroupRuleEngineProps) => {
    const containerRef = useRef<any>();
    const onListChange = (newList: any) => {
        newList.forEach((item: any, index: number) => {
            item.order = index;
        });
        const dataToUpdate = {
            groups: [...newList],
        };
        updateGroupOrder(dataToUpdate)
            .then((res) => {
                console.log(res.message);
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
        setGroupRuleData(newList);
    };

    return (
        <div className="overflow-y-scroll">
            <div className="relative shrink-0 bg-white px-5 pb-5 w-[328px]">
                <span className="sticky top-0 z-50 flex items-center justify-between bg-white">
                    <p className="font-bold text-[18px] leading-[22px]">
                        Groups
                    </p>
                    <button
                        type="button"
                        color="#2E672F"
                        className="text-xs text-[#2E672F] font-bold bg-transparent border-none cursor-pointer p-0"
                        onClick={() => {
                            setIsOpen(true);
                        }}
                    >
                        + Add New Group
                    </button>
                </span>

                {loading ? (
                    <div className="mt-5">
                        <LoadingSpinner />
                    </div>
                ) : (
                    <div ref={containerRef} style={{ touchAction: 'pan-y' }}>
                        <DraggableList
                            itemKey="_id"
                            template={GroupCard as any}
                            list={groupRuleData}
                            commonProps={{
                                setActiveGroup,
                                activeGroup,
                                groupRuleData,
                                setGroupRuleData,
                            }}
                            onMoveEnd={(newList: any) => onListChange(newList)}
                            container={() => containerRef?.current as any}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default GroupRuleEngine;
