import React from 'react';
import { SvgIcon, RFSelectField } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import RFTextField from 'components/Common/RFTextField/RFTextField';
import { IUserDrawerFormProps, UserRolesOption } from '../../User.type';

export const UserDrawerForm = ({
    isEditUser,
    openDrawer,
    userData,
    handleOnSelect,
    companyOptionList,
    groups,
    control,
    watch,
}: IUserDrawerFormProps) => {
    const { auth } = useAuthContext();

    const modifiedArray = [
        {
            createdAt: '',
            name: 'Select Group',
            companyId: '',
            updatedAt: '',
            _id: '1',
            permissionId: 'isPermission',
            __v: 0,
            mid: '',
            isDeleted: false,
            permission: {
                _id: 'isPermission',
                pageAreasPermissions: [],
                brandsPermissions: [],
                createdAt: '',
                updatedAt: '',
                __v: 0,
            },
        },
        ...groups,
        {
            isDeleted: false,
            createdAt: '',
            name: 'No-Groups',
            companyId: '',
            updatedAt: '',
            _id: '2',
            mid: '',
            permissionId: userData?.isCustomGroup ? userData?.permissionId : '',
            __v: 0,
            permission: {
                _id: 'No-Groups',
                pageAreasPermissions: [],
                brandsPermissions: [],
                createdAt: '',
                updatedAt: '',
                __v: 0,
            },
        },
    ];

    return (
        <div>
            <div className="flex items-center justify-between">
                <div className="my-0 text-xl font-black text-green-100">
                    {isEditUser ? 'Update User' : 'Add New User'}
                </div>
                <SvgIcon
                    className="cursor-pointer"
                    icon="CLOSE_BUTTON"
                    onClick={openDrawer}
                />
            </div>
            <div className="mt-5">
                <RFTextField
                    control={control}
                    name="name"
                    fullWidth
                    label="Full Name"
                    type="text"
                    asterisk
                    placeholder="Enter Username"
                />
            </div>
            <div className="mt-5">
                <RFTextField
                    control={control}
                    name="email"
                    fullWidth
                    label="Enter Email"
                    type="email"
                    asterisk
                    placeholder="Enter Email"
                />
            </div>
            <div className="mt-5">
                <RFTextField
                    control={control}
                    name="phone"
                    fullWidth
                    label="Phone"
                    type="number"
                    noArrows
                    asterisk
                    placeholder="Phone"
                />
            </div>
            <div className="mt-5">
                <RFTextField
                    control={control}
                    name="job"
                    fullWidth
                    label="Job"
                    type="text"
                    asterisk
                    placeholder="Select job Title"
                />
            </div>
            {(auth.isSuperAdmin || auth.isAdmin) && (
                <div className="mt-5">
                    <RFSelectField
                        name="role"
                        control={control}
                        fullWidth
                        asterisk
                        label="User Role"
                        options={UserRolesOption || []}
                        isCompanyAdmin
                        isGroup
                    />
                </div>
            )}
            {auth.isSuperAdmin && (
                <div className="mt-5">
                    <RFSelectField
                        name="company"
                        control={control}
                        fullWidth
                        asterisk
                        label="Select Your Company"
                        options={companyOptionList || []}
                        isCompanyAdmin
                        isGroup
                    />
                </div>
            )}
            <div className="mt-5">
                <RFSelectField
                    name="group"
                    control={control}
                    fullWidth
                    asterisk
                    onChange={(e) => handleOnSelect(e)}
                    label="Select Your Group"
                    options={modifiedArray || []}
                    isCompanyAdmin
                    disabled={auth.isSuperAdmin && !watch('company')}
                    isGroup
                />
            </div>
        </div>
    );
};
