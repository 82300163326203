export enum GenderConstant {
    SELECT_GENDER = 'Select Gender',
    MALE = 'Male',
    FEMALE = 'Female',
    OTHER = 'Other',
}

export const GenderList = [
    {
        _id: '',
        name: GenderConstant.SELECT_GENDER,
    },
    { _id: GenderConstant.MALE, name: GenderConstant.MALE },
    { _id: GenderConstant.FEMALE, name: GenderConstant.FEMALE },
    {
        _id: GenderConstant.OTHER,
        name: GenderConstant.OTHER,
    },
];
