import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { IIcons } from 'data/common';
import { ICONS } from './Icons';

interface ISvgIconProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
    /**
     * key of the icon
     */
    icon: keyof IIcons;
    fill?: string;
    key?: string;
}

const SvgIcon = ({
    fill,
    icon,
    key,
    ...restProps
}: ISvgIconProps): JSX.Element => (
    <i key={key} {...restProps}>
        {ICONS[`${icon}`]}
    </i>
);

export default SvgIcon;
