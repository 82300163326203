import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { SearchMenuList, Select } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { getPaymentProvider } from 'services/api/api';
import classNames from 'classnames';
import TrustPaymentForm from './ConfigurationList/TrustPayment';
import SelectProviderPage from './ConfigurationList/SelectProvider';
import { ProvidersList } from './configurationUtills';
import PayModumForm from './ConfigurationList/Paymodum';
import CleoPaymentForm from './ConfigurationList/CleoPayment';

const Configuration = () => {
    const { auth } = useAuthContext();

    const [selectedPspData, setSelectedPspData] = useState({});
    const [pspConfigurationData, setPspConfigurationData] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState<any>({
        name: 'Cleo',
        value: 'CLEO',
    });
    const [selectedCompanyId, setSelectedCompanyId] = useState<any>(
        auth?.isSuperAdmin
            ? auth?.selectedCompany?._id || ''
            : auth?.authUser?.company
    );
    const companies = auth.companiesAndBrands || [];

    useEffect(() => {
        if (auth?.isSuperAdmin) {
            setSelectedCompanyId(auth?.selectedCompany?._id || '');
        } else {
            setSelectedCompanyId(auth?.authUser?.company);
        }
    }, [auth?.selectedCompany?._id]);

    const fetchConfigurationList = async () => {
        const { configurations } = await getPaymentProvider(selectedCompanyId);
        setPspConfigurationData(configurations);
        const filterPaymentProvider = configurations.find(
            (paymentProvider: any) => {
                return paymentProvider.type === selectedProvider?.value;
            }
        );
        setSelectedPspData(filterPaymentProvider);
    };

    useEffect(() => {
        if (selectedCompanyId) {
            fetchConfigurationList();
        }
    }, [selectedCompanyId]);

    const onSelectPsp = (psp: any) => {
        const filterPaymentProvider = pspConfigurationData.find(
            (paymentProvider: any) => {
                return paymentProvider.type === psp?.value;
            }
        );
        setSelectedPspData(filterPaymentProvider || {});
        setSelectedProvider({ name: psp.name, value: psp.value });
    };

    const configurationList = useCallback(() => {
        switch (selectedProvider?.name) {
            case 'Cleo':
                return (
                    <CleoPaymentForm
                        title="Cleo"
                        selectedPspData={selectedPspData}
                        fetchConfigurationList={fetchConfigurationList}
                        selectedCompanyId={selectedCompanyId}
                    />
                );
            case 'Trust Payments':
                return (
                    <TrustPaymentForm
                        title="Trust Payments"
                        selectedPspData={selectedPspData}
                        fetchConfigurationList={fetchConfigurationList}
                        selectedCompanyId={selectedCompanyId}
                    />
                );
            case 'PayModum':
                return (
                    <PayModumForm
                        title="Pay Modum"
                        selectedPspData={selectedPspData}
                        fetchConfigurationList={fetchConfigurationList}
                        selectedCompanyId={selectedCompanyId}
                    />
                );
            default:
                return <SelectProviderPage />;
        }
    }, [selectedProvider, selectedPspData]);

    return (
        <div className="flex w-full">
            <div className="px-6 bg-white w-[250px] h-[calc(100vh-100px)] overflow-y-scroll">
                <div className="relative">
                    <div className="fixed bg-white py-2 text-lg font-bold w-[250px] z-[110]">
                        Payment Providers
                    </div>
                </div>
                {auth?.isSuperAdmin && (
                    <div className="relative pt-[44px]">
                        <Select
                            isGroup
                            bordered
                            value={selectedCompanyId}
                            label="Select company"
                            options={[
                                {
                                    id: '',
                                    name: 'Select company',
                                },
                                ...companies,
                            ]}
                            handleOnSelect={(
                                e: ChangeEvent<HTMLSelectElement>
                            ) => {
                                if (e.target.value === 'Select company') {
                                    setSelectedCompanyId('');
                                } else {
                                    setSelectedCompanyId(e.target.value);
                                }
                            }}
                        />
                    </div>
                )}
                <div
                    className={classNames(
                        'relative',
                        auth?.isSuperAdmin ? 'pt-[20px]' : 'pt-[44px]'
                    )}
                >
                    <SearchMenuList
                        selectedCompanyId={selectedCompanyId}
                        datalist={ProvidersList}
                        onSelectPsp={onSelectPsp}
                        selectedProvider={selectedProvider}
                        className="w-full top-180"
                    />
                </div>
            </div>
            {selectedCompanyId ? (
                <div className="w-[calc(100%-250px)] h-[calc(100vh-100px)] overflow-y-scroll">
                    {configurationList()}
                </div>
            ) : (
                <div className="mt-5 px-5 text-green-100">
                    Please select company first
                </div>
            )}
        </div>
    );
};

export default Configuration;
