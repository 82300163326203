import { ChangeEvent } from 'react';
import { PrimaryButton, Select, Tooltip } from 'components/Common';
import { bulkActionForBrand } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { BulkActionTypes } from 'data/constants/auth.constants';
import { BrandsHeaderProps } from '../Brads.type';

const Options = [
    { id: '', name: BulkActionTypes.BULK_ACTION },
    { id: 'disable', name: BulkActionTypes.DISABLE },
];

export const BrandsHeader = ({
    getAllBrandsData,
    setCheckedBrands,
    checkedBrands,
    openDrawer,
}: BrandsHeaderProps) => {
    return (
        <div className="mb-8 flex w-full items-center justify-between max-[970px]:flex-col">
            <div className="font-extrabold text-2xl text-[#131119]">
                Sub Brands
            </div>
            <div className="flex items-center gap-4">
                <Tooltip
                    text={
                        checkedBrands?.length === 0
                            ? 'Please select first the Mids from the table that you want to edit'
                            : ''
                    }
                    className="w-[290px]"
                >
                    <Select
                        isDisabled={!checkedBrands?.length}
                        isGroup
                        options={Options}
                        handleOnSelect={(e: ChangeEvent<HTMLSelectElement>) => {
                            if (e.target.value !== '1') {
                                bulkActionForBrand({
                                    brandIds: checkedBrands,
                                    type: e.target.value?.toUpperCase(),
                                })
                                    .then((res) => {
                                        if (res.isSuccess) {
                                            getAllBrandsData();
                                            showToast(res.message);
                                            setCheckedBrands([]);
                                        }
                                    })
                                    .catch((err) => {
                                        showToast(
                                            err?.errors?.[0]?.message ||
                                                'something went wrong',
                                            'error'
                                        );
                                    });
                            }
                        }}
                        className="w-full max-w-[290px]"
                    />
                </Tooltip>
                <div className="w-[144px]">
                    <PrimaryButton
                        onClick={openDrawer}
                        type="button"
                        name="Add Sub Brand"
                        color="#8FB131"
                        variant="filled"
                        className="w-full font-medium"
                    />
                </div>
            </div>
        </div>
    );
};
