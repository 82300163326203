import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { Layout } from 'components/Common';
import User from 'components/Admin/User/User';
import Groups from 'components/Admin/Groups/Groups';
import Companies from 'components/Admin/Companies/Companies';
import Brands from 'components/Admin/Brands/Brands';
import Configuration from 'components/Payment/Configuration';
import RuleEngine from 'components/Admin/RuleEngine/RuleEngine';
import ApiKeys from 'components/Admin/ApiKeys/ApiKeys';

const AdminPage = () => {
    const location = useLocation();

    const admin = useCallback(() => {
        switch (location.pathname) {
            case '/admin/existing-users':
                return <User />;
            case '/admin/groups':
                return <Groups />;
            case '/admin/companies':
                return <Companies />;
            case '/admin/brands':
                return <Brands />;
            case '/admin/api-keys':
                return <ApiKeys />;
            case '/admin/configuration':
                return <Configuration />;
            case '/admin/rule-engine':
                return <RuleEngine />;
            default:
                return <User />;
        }
    }, [location.pathname]);

    return <Layout>{admin()}</Layout>;
};
export default AdminPage;
