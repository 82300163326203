import React, { ChangeEvent, useState } from 'react';
import Select from 'components/Common/Select/Select';
import {
    PaymentProvider,
    PaymentProviderConstant,
} from '../../data/constants/payment.constants';

import TripleZero from './Triple000';
import Trust from './Trust';
import Cleo from './Cleo';
import Paymodum from './Paymodum';

const MerchantPayout = () => {
    const [transactionMethod, setTransactionMethod] = useState<string>(
        PaymentProviderConstant.CLEO
    );

    return (
        <div className=" py-14 px-8 flex flex-col justify-center">
            <div className="w-full flex justify-between">
                <div className="font-extrabold text-2xl text-[#131119] self-center">
                    Merchants Payout
                </div>
                <div className="flex items-center gap-1">
                    <div className="font-bold text-sm">
                        Select Payment Method :
                    </div>
                    <div className="w-[328px]">
                        <Select
                            isGroup
                            isCompanyAdmin
                            value={transactionMethod}
                            options={PaymentProvider.filter(
                                (item) =>
                                    item.name !== PaymentProviderConstant.TRUST
                            )}
                            handleOnSelect={(
                                e: ChangeEvent<HTMLSelectElement>
                            ) => {
                                setTransactionMethod(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="pt-8">
                {transactionMethod === PaymentProviderConstant.TRIPLE000 && (
                    <TripleZero />
                )}
                {transactionMethod === PaymentProviderConstant.TRUST && (
                    <Trust />
                )}
                {transactionMethod === PaymentProviderConstant.CLEO && <Cleo />}
                {transactionMethod === PaymentProviderConstant.PAYMODUM && (
                    <Paymodum />
                )}
            </div>
        </div>
    );
};
export default MerchantPayout;
