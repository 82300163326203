import React, { useEffect, useMemo, useState } from 'react';
import { getTransactions } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { ITransactions } from 'data/types/response';
import { useAuthContext } from 'context/Auth/AuthContext';
import { TransactionHeader } from './TransactionHeader';
import { TransactionTable } from './TransactionTable';
import TransactionTableFilterModal from './TransactionTableFilterModal';
import { headerList } from './TransactionTable/TransactionTableUtill';

const PaymentTransactions = () => {
    const { auth } = useAuthContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [transactionData, setTransactionData] = useState<ITransactions[]>([]);
    const [filterData, setFilterData] = useState<{
        status: string;
        search: string;
    }>({
        status: '',
        search: '',
    });
    const [isTableFilter, setIsTableFilter] = useState<boolean>(false);

    const updatedHeaderList = useMemo(() => {
        return auth.authUser?.filteredColumn?.length !== 0
            ? headerList.map((item) => ({
                  ...item,
                  isChecked: auth.authUser?.filteredColumn.includes(item.key),
              }))
            : headerList;
    }, [auth.authUser?.filteredColumn]);

    const fetchTransactionData = () => {
        setCurrentPage(1);
        setIsLoading(true);
        const payload: any = { ...filterData };
        if (auth.selectedBrand?._id) {
            payload.brandIds = [auth?.selectedBrand?._id];
        } else if (auth.selectedCompany?._id) {
            const selectedCompany = (auth?.companiesAndBrands || []).find(
                (company: any) => company._id === auth.selectedCompany?._id
            );
            payload.brandIds =
                (selectedCompany.brandData || []).map(
                    ({ _id }: { _id: string }) => _id
                ) || [];
        }
        getTransactions(payload)
            .then((res) => {
                setTransactionData(
                    (res.transactions || []).sort(
                        (a, b) =>
                            new Date(b?.createdAt).getTime() -
                            new Date(a?.createdAt).getTime()
                    ) || []
                );
                setIsLoading(!auth.isSuperAdmin);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        fetchTransactionData();
    }, [filterData, auth?.selectedBrand, auth?.selectedCompany]);

    return (
        <div className="px-5 pt-10">
            <TransactionHeader
                setFilterData={setFilterData}
                filterData={filterData}
                setIsTableFilter={setIsTableFilter}
            />

            {isTableFilter && (
                <TransactionTableFilterModal
                    className="actionModal"
                    setIsOpen={setIsTableFilter}
                    tableFilterColumn={updatedHeaderList}
                />
            )}

            <TransactionTable
                tableFilterColumn={updatedHeaderList}
                loading={loading}
                currentPage={currentPage}
                transactionData={transactionData}
                setCurrentPage={setCurrentPage}
            />
        </div>
    );
};
export default PaymentTransactions;
