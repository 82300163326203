import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { z, ZodType } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Success from 'assets/images/Success.svg';
import { forgotPassword } from 'services/api/api';
import { IForgotPasswordRes } from 'data/types/response';
import { IForgotPasswordReq } from 'data/types/request';
import { showToast } from 'data/utils/toast';
import { PrimaryButton } from 'components/Common';
import RFTextField from '../../Common/RFTextField/RFTextField';

export const forgotPasswordValidation: ZodType<IForgotPasswordReq> = z
    .object({
        email: z.string().email({ message: 'Email is required' }),
    })
    .required();

const ForgotPassword = () => {
    const [isForgotPasswordData, setIsForgotPasswordData] =
        useState<IForgotPasswordReq | null>();
    const [isRecoveryLink, setIsRecoveryLink] = useState(false);

    const { control, handleSubmit, reset } = useForm<IForgotPasswordReq>({
        resolver: zodResolver(forgotPasswordValidation),
        defaultValues: {},
    });

    const onSubmit = handleSubmit((formData) => {
        forgotPassword(formData)
            .then((result: IForgotPasswordRes) => {
                showToast(result.message, 'success');
                setIsForgotPasswordData(formData);
                reset({});
                setIsRecoveryLink(true);
            })
            .catch((err: any) => {
                setIsRecoveryLink(false);
                showToast(err.message, 'error');
            });
    });

    const handleOnSubmit = () => {
        if (isForgotPasswordData && isForgotPasswordData.email) {
            forgotPassword(isForgotPasswordData)
                .then((result: IForgotPasswordRes) => {
                    showToast(result.message, 'success');
                })
                .catch((err: any) => {
                    setIsRecoveryLink(false);
                    showToast(err.message, 'error');
                });
        }
    };

    return isRecoveryLink ? (
        <div className="flex flex-col justify-center items-center mx-auto w-[320px]">
            <img
                src={Success}
                className="loginLogo"
                alt="logo"
                width="100px"
                height="100px"
            />
            <div className="flex items-center text-[32px] justify-center text-green-100 font-bold w-full">
                Recovery email sent
            </div>
            <div className="text-sm font-base">
                A recovery link has been sent to
            </div>
            <Link to="/" className="text-sm font-medium	text-green-100">
                {`${isForgotPasswordData?.email as string}`}
            </Link>
            <div className="text-sm font-base">
                {' '}
                Please follow the link and reset your password
            </div>

            <PrimaryButton
                onClick={() => handleOnSubmit()}
                type="submit"
                name="Send recovery email again"
                color="#2E672F"
                variant="filled"
                className="mt-10 w-full p-1 font-medium"
            />
        </div>
    ) : (
        <div className="flex flex-col justify-center items-start w-[500px] mx-auto">
            <div className="flex items-center text-[32px] text-green-100 font-bold w-full">
                Forgot Password{' '}
            </div>
            <div className="text-[16px] font-medium w-full">
                Don't fret! We understand forgetting passwords happens
                occasionally. Enter your email address and we'll send you a link
                to recover your password.{' '}
            </div>
            <form className="w-full" onSubmit={onSubmit}>
                <div className="flex flex-col w-[100%] pt-12">
                    <RFTextField
                        control={control}
                        name="email"
                        fullWidth
                        label="Enter Email"
                        type="email"
                        asterisk
                    />
                    <PrimaryButton
                        type="submit"
                        name="Send recovery link"
                        color="#2E672F"
                        variant="filled"
                        className="mt-6 w-full p-1 font-medium"
                    />

                    <div className="flex justify-start items-center text-[12px] mt-1">
                        Already have an account?{' '}
                        <Link
                            to="/"
                            className="pl-1 font-extrabold text-green-200"
                        >
                            Log in
                        </Link>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
