import React, { useState } from 'react';
import { saveDisableEnableGroupRule } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGroupRuleDataType } from 'components/Admin/RuleEngine/RuleEngine.type';
import { AppActionsEnum } from 'context/Auth/AuthContextValues';
import { useAuthContext } from 'context/Auth/AuthContext';
import SvgIcon from '../Icon/SvgIcon';
import Switch from '../Switch/Switch';

const GroupCard: React.FC<any> = ({ item, dragHandleProps, commonProps }) => {
    const [active, setActive] = useState(item?.isActive);
    const { dispatch } = useAuthContext();
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const { setActiveGroup, activeGroup, groupRuleData, setGroupRuleData } =
        commonProps;

    const handleDisable = () => {
        if (!item?._id) return;
        const payload = {
            groupId: item?._id,
            isActive: !active,
        };
        saveDisableEnableGroupRule(payload)
            .then(() => {
                const newGroupRuleData = groupRuleData.map(
                    (data: IGroupRuleDataType) => {
                        if (item?._id === data._id) {
                            return {
                                ...data,
                                isActive: !active,
                            };
                        }
                        return data;
                    }
                );
                setGroupRuleData(newGroupRuleData);
                setActive(!active);
                showToast('Succesfully', 'success');
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };
    const handleSetActive = () => {
        setActiveGroup?.(item?._id);
        dispatch({
            type: AppActionsEnum.SET_ACTIVE_GROUP,
            payload: {
                activeGroup: item?._id,
            },
        });
    };

    return (
        <div
            onClick={handleSetActive}
            className={`${
                active ? 'bg-[#F8FBEA]' : 'bg-[#A4A4A4]'
            }  rounded-2xl ${
                activeGroup === item?._id && !active
                    ? 'text-black border-2 border-black border-solid'
                    : activeGroup === item?._id
                    ? 'text-[#8FB131] border-2 border-solid'
                    : 'text-black border-2 border-transparent border-solid'
            }`}
        >
            <div className="flex flex-wrap items-center justify-between h-14 px-5">
                <span className="font-bold flex text-xs items-center">
                    <span
                        className="cursor-grab"
                        onTouchStart={(e: any) => {
                            e.preventDefault();
                            document.body.style.overflow = 'hidden';
                            onTouchStart(e);
                        }}
                        onMouseDown={(e: any) => {
                            document.body.style.overflow = 'hidden';
                            onMouseDown(e);
                        }}
                        onTouchEnd={() => {
                            document.body.style.overflow = 'visible';
                        }}
                        onMouseUp={() => {
                            document.body.style.overflow = 'visible';
                        }}
                    >
                        <SvgIcon
                            icon="DRAG_ICON"
                            className={`pr-2 mt-1 ${
                                activeGroup === item?._id && !active
                                    ? 'stroke-[#000]'
                                    : activeGroup === item?._id
                                    ? 'stroke-[#8FB131]'
                                    : 'stroke-[#000]'
                            } `}
                        />
                    </span>
                    {item.name}
                </span>
                <Switch
                    className={`rounded-2xl ${
                        active ? 'bg-[#deecaa]' : 'bg-[#E3E3E3]'
                    } `}
                    isRuleEngine
                    isToggled={active}
                    handleCheck={handleDisable}
                />
            </div>
        </div>
    );
};
export default GroupCard;
