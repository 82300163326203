import React, { ChangeEvent } from 'react';
import {
    DatePicker,
    MultiSelectDropdown,
    TextField,
    Select,
} from 'components/Common';
import {
    ActionOptions,
    PaymentProvider,
} from 'data/constants/payment.constants';
import './index.css';
import {
    ConditionType,
    defaultData,
    IConditionDataProps,
} from '../../../RuleEngine.type';

export const getInputField = (
    v: IConditionDataProps,
    index: number,
    handleMultiSelect: (selectedLists: any, index: number) => void,
    handleChange: (index: number, value: string | number | undefined) => void
) => {
    switch (v.conditionType) {
        case ConditionType.MULTI_SELECTION:
            return (
                <MultiSelectDropdown
                    backgroundColor="#ffffff"
                    className="mb-3 custom-multiselect"
                    selectedValues={(v?.items as string[])?.map(
                        (list: string) => ({
                            name: list,
                            id: list,
                        })
                    )}
                    showArrow
                    placeholder={`Select ${v?.title?.toLowerCase()}...`}
                    options={defaultData[v?.title]?.map((list: string) => ({
                        name: list,
                        id: list,
                    }))}
                    displayValue="name"
                    onSelect={(selectedLists) => {
                        handleMultiSelect(selectedLists, index);
                    }}
                    onRemove={(selectedLists) => {
                        handleMultiSelect(selectedLists, index);
                    }}
                />
            );
        case ConditionType.SELECT:
            return (
                <Select
                    bordered
                    isCompanyAdmin
                    isGroup
                    fullWidth
                    options={[
                        {
                            id: '',
                            name: `Select ${v?.title?.toLowerCase()}...`,
                        },
                        ...(v?.title?.toLowerCase() !== 'action'
                            ? PaymentProvider
                            : ActionOptions),
                    ]}
                    value={(v?.items as string) || ''}
                    handleOnSelect={(e: ChangeEvent<HTMLSelectElement>) => {
                        handleChange(
                            index,
                            !e.target.value ||
                                e.target.value ===
                                    `Select ${v?.title?.toLowerCase()}...`
                                ? undefined
                                : e.target.value
                        );
                    }}
                    className="w-full text-gray-400"
                />
            );

        case ConditionType.DATEPICKER:
            return (
                <DatePicker
                    onChange={(selectedDates: any) => {
                        handleChange(index, selectedDates);
                    }}
                    dateTimePickerProps={{
                        options: {
                            minDate: new Date(),
                        },
                    }}
                    placeholder={`Select ${v?.title?.toLowerCase()}...`}
                    fullWidth
                    name={v.title}
                />
            );

        case ConditionType.TEXT_INPUT:
            return (
                <TextField
                    fullWidth
                    name={v.title}
                    value={v.items ?? ''}
                    type="text"
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100"
                    onChange={(e) => handleChange(index, e.target.value)}
                />
            );

        case ConditionType.NUMBER_INPUT:
            return (
                <TextField
                    fullWidth
                    type="number"
                    noArrows
                    name={v.title}
                    value={v.items ?? ''}
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                />
            );

        case ConditionType.TEXT_INPUT_REGEX:
            return (
                <TextField
                    fullWidth
                    type="text"
                    noArrows
                    name={v.title}
                    value={v.items ?? ''}
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                    pattern=""
                />
            );

        case ConditionType.PERCENTAGE_INPUT:
            return (
                <TextField
                    fullWidth
                    type="number"
                    noArrows
                    name={v.title}
                    value={v.items ?? ''}
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                    pattern=""
                />
            );
        case ConditionType.INPUT_JOIN:
            return (
                <TextField
                    fullWidth
                    type="text"
                    noArrows
                    name={v.title}
                    value={v.items ?? ''}
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-100 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                    pattern=""
                />
            );
        default:
            return (
                <TextField
                    fullWidth
                    name={v.title}
                    value={v.items ?? ''}
                    type="text"
                    placeholder={`Enter ${v?.title?.toLowerCase()}...`}
                    className="text-gray-400 !bg-white"
                    onChange={(e) => handleChange(index, e.target.value)}
                />
            );
    }
};
