import { SvgIcon, TextField } from 'components/Common';
import { getInputField } from './PreconditionUtill';
import {
    IConditionDataProps,
    IPreconditionDropDown,
    ISelectedValueType,
} from '../../../RuleEngine.type';

export const PreconditionDropdown = ({
    handleDelete,
    data,
    setData,
    getPreConditionDataList,
    setGetPreConditionDataList,
}: IPreconditionDropDown) => {
    const handleMultiSelect = (selectedLists: any, index: number) => {
        const list = (selectedLists || []).map(
            (value: ISelectedValueType) => value.name
        );
        const newArray = [...data];
        newArray[index].items = list;
        setData(newArray);
        const tempList = [...getPreConditionDataList];
        tempList[index].values = list;
        setGetPreConditionDataList(tempList);
    };

    const handleChange = (
        index: number,
        value: string | number | string[] | undefined
    ) => {
        const newArray = [...data];
        newArray[index].items = value;
        setData(newArray);
        const tempList = [...getPreConditionDataList];
        tempList[index].values = value;
        setGetPreConditionDataList(tempList);
    };

    return (
        <div className="col-span-4 pt-2.5">
            {data.length > 0 &&
                data?.map((v: IConditionDataProps, index) => {
                    return (
                        <div className="flex w-full items-start">
                            <div className="flex max-w-[324px] w-full">
                                <TextField
                                    key={v.title}
                                    fullWidth
                                    type="text"
                                    mb={0}
                                    className="text-gray-400 min-w-[80px] w-full !bg-white"
                                    value={v.title ?? ''}
                                    disabled
                                />
                            </div>
                            <div className="flex items-baseline w-full">
                                <p className="font-bold text-xs mx-3">=</p>
                                <div className="flex w-full">
                                    {getInputField(
                                        v,
                                        index,
                                        handleMultiSelect,
                                        handleChange
                                    )}
                                </div>
                                <span>
                                    <SvgIcon
                                        icon="DELETE_ICON_2"
                                        className="stroke-2 stroke-[#FFFFFF] mx-2.5 cursor-pointer"
                                        onClick={() => {
                                            handleDelete(v.title);
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
