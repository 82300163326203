import React, { useEffect, useRef, useState } from 'react';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import classNames from 'classnames';
import 'flatpickr/dist/themes/material_green.css';
import './datepicket.css';

const inputClasses = (
    error: boolean,
    isFocused: boolean,
    disabled: boolean,
    hasStartIcon: boolean,
    hasEndIcon: boolean,
    forFilters: boolean,
    selectedDropdownOptionIcon: boolean
) =>
    classNames('flex min-h-[46px]', {
        'pl-12': hasStartIcon,
        'pr-12': hasEndIcon,
        'pr-16': forFilters,
        'pl-[76px]': selectedDropdownOptionIcon,
        'border-neutral-300': !error && !isFocused,
        'border-primary-600 focus:outline-none': isFocused,
        'border-danger-300 !bg-red-300 border-0': error && !isFocused,
        'cursor-not-allowed border-neutral-200 bg-neutral-100 text-neutral-400':
            disabled,
    });

const labelClasses = (
    disabled: boolean,
    isHovered: boolean,
    isFocused: boolean,
    isError: boolean
) => {
    return classNames(['text-start mb-2 text-sm font-semibold block'], {
        'cursor-not-allowed text-neutral-400': disabled,
        '!text-black': isHovered || isFocused,
        'text-gray-500': !isFocused,
        '!text-red-200': isError,
    });
};
type FieldError = {
    message?: string;
};

interface IDatePicker extends DateTimePickerProps {
    dateTimePickerProps?: DateTimePickerProps;
    onChange: any;
    onBlur?: () => void;
    error?: FieldError;
    label?: string;
    hint?: string;
    dataTest?: string;
    placeholder?: string;
    name: string;
    errorMessage?: string | number;
    isError?: boolean;
    disabled?: boolean;
    value?: string;
    fullWidth?: boolean;
    asterisk?: boolean;
    options?: any;
}

const DatePicker: React.FunctionComponent<IDatePicker> = ({
    dateTimePickerProps,
    onChange,
    onBlur,
    error,
    disabled = false,
    isError = false,
    errorMessage,
    label,
    hint = '',
    dataTest,
    placeholder,
    name,
    options = {},
    value,
    fullWidth = false,
    asterisk = false,
}: IDatePicker) => {
    const fp = useRef<Flatpickr>(null);
    const [isFocused, setIsFocused] = useState(false);
    const datePickerRef = useRef<HTMLDivElement | null>(null);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (
                datePickerRef.current &&
                !datePickerRef.current.contains(e.target as Node)
            ) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                showOptions && setShowOptions(false);
                setIsFocused(false);
                onBlur?.();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showOptions, onBlur]);

    const inputClassNames = inputClasses(
        !!error,
        isFocused,
        disabled,
        false,
        false,
        false,
        false
    );

    const labelClassNames = labelClasses(
        disabled,
        isHovered,
        isFocused,
        isError
    );

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={classNames('flex flex-col mb-4', fullWidth && 'w-full')}
            data-test={dataTest}
        >
            {label && (
                <label
                    className={labelClassNames}
                    data-test="date-picker-label"
                    id={`${name}-datepicker-label`}
                >
                    {label}
                    {asterisk && (
                        <span className="!text-red-200 text-lg pl-[2px] pt-3">
                            *
                        </span>
                    )}
                </label>
            )}
            <Flatpickr
                {...dateTimePickerProps}
                ref={fp}
                options={options}
                onChange={onChange}
                onOpen={() => setIsFocused(true)}
                value={value}
                render={({ defaultValue }, ref) => (
                    <div
                        ref={datePickerRef}
                        className={`${inputClassNames} relative py-0`}
                        onMouseEnter={() => handleMouseEnter()}
                        onMouseLeave={() => handleMouseLeave()}
                    >
                        <input
                            defaultValue={defaultValue}
                            ref={ref}
                            placeholder={placeholder ?? 'Please select'}
                            className={classNames([
                                'w-full border-none focus:outline-none rounded-lg p-3',
                                {
                                    'cursor-not-allowed text-neutral-400':
                                        disabled,
                                },
                                {
                                    '!bg-red-300': isError,
                                },
                            ])}
                            data-test="date-picker-input"
                            disabled={disabled}
                        />
                    </div>
                )}
            />
            {!!hint && (
                <p
                    data-test="date-picker-hint"
                    className="text-sm font-medium text-neutral-300 mt-2"
                >
                    {hint}
                </p>
            )}

            {errorMessage && (
                <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default DatePicker;
