import React, { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    Drawer,
    PrimaryButton,
    SvgIcon,
    RFTextField,
    Select,
} from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { saveGroup } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGroupDrawer, IRuleEngineDrawerProps } from '../../RuleEngine.type';
import { formSchemaValidation } from '../../RuleEngine.schema';
import { ISaveGroupReq } from '../../../../../data/types/request';

const RuleEngineDrawer = ({
    openDrawer,
    isOpen,
    groupRuleData,
    getGroupRules,
}: IRuleEngineDrawerProps) => {
    const { auth } = useAuthContext();

    const { control, handleSubmit, reset } = useForm<IGroupDrawer>({
        resolver: zodResolver(formSchemaValidation),
        mode: 'onChange',
        defaultValues: {},
    });
    const companies = auth.companiesAndBrands || [];
    const [selectedData, setSelectedData] = useState({
        selectedCompany: '',
        selectedBrand: '',
    });
    const [brands, setBrands] = useState<any>([]);
    const [isCompanyError, setIsCompanyError] = useState('');
    const dataLength = Number(groupRuleData.length) + 1;

    const onSubmit = handleSubmit((formData) => {
        if (!formData) return;
        const payload: ISaveGroupReq = {
            name: formData?.name,
            companyId: auth?.authUser?.company || selectedData.selectedCompany,
            order: dataLength,
            brandId: selectedData.selectedBrand,
        };
        if (
            selectedData.selectedBrand === '' ||
            selectedData.selectedBrand === 'Select brand'
        ) {
            delete payload.brandId;
        }

        if (
            payload.companyId === '' ||
            payload.companyId === 'Select Company'
        ) {
            setIsCompanyError('Please select company first');
            return;
        }

        saveGroup(payload)
            .then(() => {
                reset({});
                showToast('Successfully created group', 'success');
                openDrawer();
                getGroupRules();
                setIsCompanyError('');
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    });

    useEffect(() => {
        if (auth.isAdmin) {
            const brand = companies.find(
                (e: any) => e._id === auth?.authUser?.company
            );
            setBrands(brand?.brandData || []);
        } else if (auth.isSuperAdmin) {
            const brand = companies.find(
                (e: any) => e._id === selectedData.selectedCompany
            );
            if (brand) setBrands(brand?.brandData);
        }
    }, [selectedData, auth, companies]);

    const handleCompanyChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const companyId = e.target.value;
        if (companyId === 'Select Company') {
            setSelectedData({
                selectedBrand: '',
                selectedCompany: '',
            });
            setIsCompanyError('Please select company first');
        } else {
            setIsCompanyError('');
            setSelectedData({
                ...selectedData,
                selectedCompany: companyId,
            });
        }
    };

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={openDrawer}
            drawerDirection="right"
            className={classNames('bg-pink-100  max-h-[100vh] relative')}
        >
            <form className="w-full" onSubmit={onSubmit}>
                <div className="p-5">
                    <div className="flex items-center justify-between">
                        <div className="my-0 text-xl font-black text-green-100">
                            New Group
                        </div>
                        <SvgIcon
                            className="cursor-pointer"
                            icon="CLOSE_BUTTON"
                            onClick={openDrawer}
                        />
                    </div>
                    <div className="mt-5">
                        <RFTextField
                            control={control}
                            name="name"
                            fullWidth
                            placeholder="Group Name"
                            label="Group Name"
                            type="text"
                            noArrows
                        />
                    </div>
                    <span>
                        {auth.isSuperAdmin && (
                            <div className="mt-5">
                                <Select
                                    bordered
                                    isGroup
                                    options={companies}
                                    value={selectedData.selectedCompany}
                                    handleOnSelect={handleCompanyChange}
                                    label="Select Company"
                                    className={classNames(
                                        'text-gray-400 h-[46px]'
                                    )}
                                    isError={!!isCompanyError}
                                    errorMessage={isCompanyError}
                                />
                            </div>
                        )}
                        {(auth.isAdmin || auth.isSuperAdmin) && (
                            <div className="my-5">
                                <Select
                                    bordered
                                    isGroup
                                    value={selectedData.selectedBrand}
                                    options={[
                                        {
                                            id: '',
                                            name: 'Select brand',
                                        },
                                        ...brands,
                                    ]}
                                    handleOnSelect={(e) => {
                                        setSelectedData({
                                            ...selectedData,
                                            selectedBrand: e.target.value,
                                        });
                                    }}
                                    label="Select Brand"
                                    className={classNames(
                                        'text-gray-400 h-[46px]'
                                    )}
                                />
                            </div>
                        )}
                    </span>
                </div>
                <div className="sticky bottom-0 flex bg-white px-5">
                    <PrimaryButton
                        type="submit"
                        isDrawerButton
                        className={classNames('w-[360px]')}
                        color="#2E672F"
                        variant="filled"
                        name="Create New Group"
                    />
                </div>
            </form>
        </Drawer>
    );
};
export default RuleEngineDrawer;
