import { IColumnType } from 'components/Common/Table/Table';
import { formatNumber, getDateFormats } from 'data/utils/common';
import { getTransactionStatus } from 'data/utils/transactions';
import { StatusBadge } from 'components/Common';
import SvgIcon from 'components/Common/Icon/SvgIcon';
import { IIcons } from 'data/common';
import { CardConstant } from 'data/constants/payment.constants';

import { IHeaderList } from '../Transaction.type';

const PaymentCardList = (cardName: string): keyof IIcons => {
    switch (cardName) {
        case CardConstant.VISA:
            return CardConstant.VISA;
        case CardConstant.MASTERCARD:
            return 'MASTER_CARD';
        default:
            return CardConstant.VISA;
    }
};
export const transactionTableColumn = (): IColumnType<any>[] => [
    {
        key: 'method',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Method</div>
            </div>
        ),
        width: 100,
        render: (_, i) => {
            return (
                <div className="flex justify-between items-center gap-5 ">
                    <div className="flex items-center text-center h-[24px] pt-[2px]">
                        {i.method || 'CARD_PAYIN'}
                    </div>

                    {(i.pspType === 'PAYMODUM' || i.paymenttypedescription) && (
                        <SvgIcon
                            className="h-[24px] text-center"
                            icon={PaymentCardList(
                                i.pspType === 'PAYMODUM'
                                    ? i?.userInfo?.paymentMethod
                                    : i.paymenttypedescription
                            )}
                        />
                    )}
                </div>
            );
        },
    },
    // {
    //     key: 'clientId',
    //     title: (
    //         <div className="flex justify-center">
    //             <div className="ml-2">Client ID</div>
    //         </div>
    //     ),
    //     width: 100,
    // },
    {
        key: '_id',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Transaction ID</div>
            </div>
        ),
        width: 100,
        render: (_, i) => <div className="flex justify-center">{i?._id}</div>,
    },
    {
        key: 'created',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Created</div>
            </div>
        ),
        render: (_, i) => (
            <div>
                {getDateFormats(
                    (i?.createdAt || i?.updateAt || i?.created) as string
                )}
            </div>
        ),
        width: 100,
    },
    {
        key: 'type',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Type</div>
            </div>
        ),
        render: (_, i) => (
            <div>{i.method === 'CLEO_PAYOUT' ? 'Withdrawal' : 'Deposit'}</div>
        ),
        width: 100,
    },
    {
        key: 'status',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Status</div>
            </div>
        ),
        render: (_, i) => (
            <div className="flex justify-center items-center">
                <StatusBadge status={getTransactionStatus(i)} />
            </div>
        ),
        width: 100,
    },
    {
        key: 'initial_psp',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Initial PSP</div>
            </div>
        ),
        width: 100,
        render: (_, i) => <div>{i?.initialPsp ?? '-'}</div>,
    },
    {
        key: 'final_psp',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Final PSP</div>
            </div>
        ),
        width: 100,
        render: (_, i) => <div>{i?.initialPsp ?? '-'}</div>,
    },
    {
        key: 'txAmount',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Tx Amount</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>{formatNumber(Number(i?.transactionBaseAmountConverted))}</div>
        ),
    },
    {
        key: 'txBase',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Tx Base</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? formatNumber(Number(i?.baseamount))
                    : formatNumber(Number(i.amount))}
            </div>
        ),
    },
    {
        key: 'balance',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">User Balance</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div className="flex justify-center items-center">
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.userBalance
                    : i?.userBalance}
            </div>
        ),
    },
    {
        key: 'updated',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Update Date</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {getDateFormats(
                    (i?.createdAt || i?.updateAt || i?.created) as string
                )}
            </div>
        ),
    },
    {
        key: 'currency',
        title: (
            <div className="flex justify-center">
                <div className="ml-3">Currency</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST'
                    ? i?.currencyiso3a
                    : i?.pspType === 'PAYMODUM'
                    ? i?.transactionBaseCurrencyConverted
                    : i.currency}
            </div>
        ),
    },
    {
        key: 'email',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">User Email</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.email
                    : i.email}
            </div>
        ),
    },
    {
        key: 'country',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">User Country</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.billing_address?.country
                    : i.country}
            </div>
        ),
    },
    {
        key: 'kyc_status',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">KYC Status</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.kycStatus
                        ? 'Verified'
                        : 'Not Verified'
                    : i.kycVerified
                    ? 'Verified'
                    : 'Not Verified'}
            </div>
        ),
    },
    {
        key: 'blocked',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Blocked</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.blocked
                        ? 'True'
                        : 'False'
                    : i.blocked
                    ? 'True'
                    : 'False'}
            </div>
        ),
    },
    {
        key: 'partnerName',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Partner Name</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.partnerName
                    : i.partnerName}
            </div>
        ),
    },
    {
        key: 'address',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Address</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.billing_address?.line1
                    : i.address}
            </div>
        ),
    },
    {
        key: 'city',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">City</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.billing_address?.city
                    : i.city}
            </div>
        ),
    },
    {
        key: 'state',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Status</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.billing_address?.state
                    : i.state}
            </div>
        ),
    },
    {
        key: 'zipcode',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Zip Code</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.billing_address?.postal_code
                    : i.zipcode}
            </div>
        ),
    },
    {
        key: 'userName',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">User Name</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? `${i?.userInfo?.firstName as string} ${
                          i?.userInfo?.lastName as string
                      }`
                    : `${i?.firstName as string} ${i?.lastName as string}`}
            </div>
        ),
    },
    {
        key: 'phone',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Phone Number</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.phone
                    : i?.phone}
            </div>
        ),
    },
    {
        key: 'gender',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">Gender</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.gender
                    : i?.gender}
            </div>
        ),
    },
    {
        key: 'userBalance',
        title: (
            <div className="flex justify-center">
                <div className="ml-2">User Balance</div>
            </div>
        ),
        width: 100,
        render: (_, i) => (
            <div>
                {i?.pspType === 'TRUST' || i?.pspType === 'PAYMODUM'
                    ? i?.userInfo?.userBalance
                    : i?.userBalance}
            </div>
        ),
    },
];

export const headerList: Array<IHeaderList> = [
    { value: 'All', key: 'all', isChecked: false },
    { value: 'Method', key: 'method', isChecked: true },
    { value: 'Transaction', key: '_id', isChecked: true },
    { value: 'Created', key: 'created', isChecked: true },
    { value: 'Type', key: 'type', isChecked: true },
    { value: 'Status', key: 'status', isChecked: true },
    { value: 'Initial PSP', key: 'initial_psp', isChecked: true },
    { value: 'Final PSP', key: 'final_psp', isChecked: true },
    { value: 'Tx Amount', key: 'txAmount', isChecked: true },
    { value: 'User Balance', key: 'balance', isChecked: true },
    { value: 'Update Date', key: 'updated', isChecked: true },
    { value: 'Currency', key: 'currency', isChecked: true },
    { value: 'User Email', key: 'email', isChecked: true },
    { value: 'User Country', key: 'country', isChecked: true },
    { value: 'KYC Status', key: 'kyc_status', isChecked: false },
    { value: 'Blocked', key: 'blocked', isChecked: false },
    { value: 'Partner Name', key: 'partnerName', isChecked: false },
    { value: 'Address', key: 'address', isChecked: false },
    { value: 'City', key: 'city', isChecked: false },
    { value: 'Status', key: 'state', isChecked: false },
    { value: 'Zip Code', key: 'zipcode', isChecked: false },
    { value: 'User Name', key: 'userName', isChecked: false },
    { value: 'Phone Number', key: 'phone', isChecked: false },
    { value: 'User Balance', key: 'userBalance', isChecked: false },
    { value: 'Gender', key: 'gender', isChecked: false },
];
