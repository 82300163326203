export enum CurrenciesConstant {
    SELECT_CURRENCY = 'Select Currency',
    CLP = 'CLP',
    USD = 'USD',
    EURO = 'EURO',
}

export const Currencies = [
    {
        _id: '',
        name: CurrenciesConstant.SELECT_CURRENCY,
    },
    { _id: CurrenciesConstant.CLP, name: CurrenciesConstant.CLP },
    { _id: CurrenciesConstant.USD, name: CurrenciesConstant.USD },
    {
        _id: 'EUR',
        name: CurrenciesConstant.EURO,
    },
];
