import { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import './modal.css';
import SvgIcon from '../Icon/SvgIcon';

interface IModalProps {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    headerButton?: JSX.Element;
    children: JSX.Element;
    title: string | JSX.Element;
    icon?: boolean;
    isTransactionModal?: boolean;
    classname?: string;
}

const Modal = ({
    title,
    setIsOpen,
    children,
    headerButton,
    icon = false,
    isTransactionModal,
    classname = '',
}: IModalProps) => (
    <div className="darkBG" style={{ position: 'absolute', zIndex: 1000 }}>
        <div className="centered">
            <div className={`${classname} modal`}>
                <div
                    className={classNames(
                        '',
                        typeof title !== 'string'
                            ? 'modalImageHeader'
                            : isTransactionModal
                            ? 'modalHeader !bg-[#F8FBEA] border-gray-300 border-t-0 border-x-0 border-b-2 border-solid p-5'
                            : 'modalHeader'
                    )}
                >
                    {typeof title !== 'string' ? (
                        <div className="flex justify-center">{title}</div>
                    ) : (
                        <div className="heading">{title}</div>
                    )}
                    <div className="flex items-center gap-5">
                        {headerButton}
                        {icon && (
                            <SvgIcon
                                onClick={() => setIsOpen(false)}
                                icon="CLOSE_BUTTON"
                                className="pr-2 cursor-pointer"
                            />
                        )}
                    </div>
                </div>
                <div className="modalContent">{children}</div>
            </div>
        </div>
    </div>
);

export default Modal;
