import './index.css';

interface StatusBadgeProps {
    status: string;
}

const getStatusColor = (status: string) => {
    switch (status) {
        case 'SUCCESS':
        case 'PAID':
            return '#B6FFB6';

        case 'PENDING':
            return '#FFF0BB';

        case 'SUSPENDED':
            return '#EDE7FB';

        case 'FAILED':
        case 'ERROR':
            return '#FCA2C2';

        case 'EXPIRED':
            return '#EDE7FB';
        default:
            return '#EDE7FB';
    }
};

const StatusBadge = ({ status }: StatusBadgeProps) => {
    const formattedStatus =
        (status ?? '').charAt(0).toUpperCase() + (status ?? '').slice(1);

    return (
        <div
            className="status-badge"
            style={{ backgroundColor: getStatusColor(status) }}
        >
            <div className="!font-bold text-xs">{formattedStatus}</div>
        </div>
    );
};

export default StatusBadge;
