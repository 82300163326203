import React, {
    useEffect,
    useRef,
    useState,
    ChangeEvent,
    DetailedHTMLProps,
    SelectHTMLAttributes,
} from 'react';
import classNames from 'classnames';
import '../Input/index.css';

export interface ISelectProps
    extends DetailedHTMLProps<
        SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    isGroup?: boolean;
    options: any[];
    label?: string;
    handleOnSelect?: (e: ChangeEvent<HTMLSelectElement>) => void;
    className?: string;
    value?: string;
    isCompanyAdmin?: boolean;
    isDisabled?: boolean;
    isError?: boolean;
    fullWidth?: boolean;
    bordered?: boolean;
    errorMessage?: string | number;
    asterisk?: boolean;
    name?: string;
    isPadding?: boolean | number;
    isRightMargin?: boolean | number;
}

const Select = (props: ISelectProps) => {
    const {
        isCompanyAdmin,
        isDisabled,
        value,
        isGroup,
        handleOnSelect,
        options,
        isError = false,
        bordered = false,
        errorMessage,
        fullWidth,
        asterisk,
        label,
        className,
        name,
        isPadding,
        isRightMargin,
        ...rest
    } = props;
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
    };

    useEffect(() => {
        // Add event listeners to track focus changes
        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);
        document.addEventListener('hover', handleFocusChange, true);

        // Clean up the event listeners when the component is unmounted
        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
            document.removeEventListener('hover', handleFocusChange, true);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={classNames(
                'flex flex-col',
                fullWidth && 'w-full',
                isRightMargin && 'mr-[36px]',
                isPadding && 'pl-[0.6rem] pr-7'
            )}
        >
            {label && (
                <label
                    className={classNames(
                        'font-semibold text-sm mb-2 text-start',
                        isError
                            ? 'text-red-200'
                            : isHovered
                            ? 'text-black'
                            : value
                            ? 'text-black'
                            : !isFocused
                            ? 'text-gray-500'
                            : 'text-black'
                    )}
                    htmlFor={label}
                >
                    {label}
                    {asterisk && (
                        <span className="!text-red-200 text-lg pl-[2px]">
                            *
                        </span>
                    )}
                </label>
            )}
            <select
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={inputRef}
                disabled={isDisabled}
                defaultValue={value}
                value={value ?? ''}
                className={classNames(
                    className,
                    'flex w-full rounded-md p-3 text-sm text-[#393838] cursor-pointer',
                    bordered ? 'border' : 'border-transparent'
                )}
                onChange={handleOnSelect}
                name={label}
                id={label}
                {...rest}
            >
                {options?.map((v, index) => {
                    const getValue = () => {
                        if (
                            index === 0 &&
                            !value &&
                            isGroup &&
                            !isCompanyAdmin
                        ) {
                            return '1';
                        }
                        if (
                            ((isGroup || value) &&
                                index === options.length - 1) ||
                            v?.name === 'No-Groups'
                        ) {
                            return v?._id as string;
                        }
                        return v?._id as string;
                    };

                    return (
                        <option
                            key={index}
                            selected={
                                value
                                    ? v?._id === value ||
                                      v?.permissionId === value
                                    : (index === 0 && !isCompanyAdmin) ||
                                      value === null
                            }
                            value={getValue()}
                        >
                            {isGroup ? v?.name : v?.title}
                        </option>
                    );
                })}
            </select>
            {errorMessage && (
                <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};
export default Select;
