import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import {
    PrimaryButton,
    Select,
    Switch,
    TagInput,
    DatePicker,
    RFTextField,
    RFSelectField,
} from 'components/Common';
import { cleoPaymentPayout, getAllBrands } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IBrandData } from 'data/common';
import { GenderList } from 'data/constants/gender.constants';
import { Currencies } from 'data/constants/cashier.constants';
import { ICleoType } from '../Merchant/Merchant.type';
import { cleoFormValidation } from '../Merchant/Merchant.schema';

const Cleo = () => {
    const [brands, setBrands] = useState<IBrandData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { control, handleSubmit, reset } = useForm<ICleoType>({
        resolver: zodResolver(cleoFormValidation),
        defaultValues: {
            blocked: false,
            kycStatus: false,
        },
    });

    const getAllBrandsData = () => {
        getAllBrands({})
            .then((res) => {
                setBrands(res.brands || []);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getAllBrandsData();
    }, []);

    const handleOnSubmit = handleSubmit((formData) => {
        if (!formData) return;
        cleoPaymentPayout({
            ...formData,
            amount: parseInt(formData.amount || ''),
            verificationDate: new Date(formData?.verificationDate),
        })
            .then((res) => {
                showToast(res?.message, 'success');
                if (res?.response?.url) {
                    window.open(res?.response?.url, '_blank');
                }
                reset({});
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                if (err?.message === 'AMOUNT_UNDER_LIMIT') {
                    showToast('Amount should be higher.', 'error');
                } else if (err?.message === 'EXTERNAL_KEY_NOT_UNIQUE') {
                    showToast('External key should be unique.', 'error');
                } else {
                    showToast(
                        err?.errors?.[0]?.message ||
                            err?.message ||
                            'something went wrong',
                        'error'
                    );
                }
            });
    });

    const brandList = [{ _id: '', name: 'Select Brand' }, ...brands];

    return (
        <form className="w-full" onSubmit={handleOnSubmit}>
            <div className="mt-3 mb-4 text-base font-bold text-black">
                User Information
            </div>
            <div className="grid grid-cols-2 items-center gap-x-6">
                <RFTextField
                    control={control}
                    name="firstName"
                    fullWidth
                    label="First Name"
                    type="text"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="lastName"
                    fullWidth
                    label="Last Name"
                    type="text"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="email"
                    fullWidth
                    label="Email"
                    type="email"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="emailVerification"
                    fullWidth
                    label="Email Verification"
                    type="text"
                    placeholder="Email Verification"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="phone"
                    fullWidth
                    label="Phone"
                    type="number"
                    asterisk
                    noArrows
                />

                <Controller
                    name="verificationDate"
                    control={control}
                    render={({ field: { onChange, ...field }, fieldState }) => (
                        <DatePicker
                            label="Verification Date"
                            placeholder="DD - MM - YYYY"
                            options={{
                                allowInput: true,
                                dateFormat: 'd-m-Y',
                            }}
                            onChange={(selectedDates: any, dateStr: any) => {
                                onChange(dateStr);
                            }}
                            asterisk
                            isError={!!fieldState.error?.message}
                            errorMessage={fieldState.error?.message ?? ''}
                            {...field}
                        />
                    )}
                />
                <RFTextField
                    control={control}
                    name="partnerName"
                    fullWidth
                    label="Partner Name"
                    type="text"
                    asterisk
                    placeholder="Partner Name"
                />
                <RFTextField
                    control={control}
                    name="userBalance"
                    fullWidth
                    label="User Balance"
                    type="number"
                    asterisk
                    noArrows
                    placeholder="User Balance"
                />

                <Controller
                    name="tags"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => (
                        <>
                            <TagInput
                                label="Categories"
                                placeholder="Press enter to add categories"
                                tags={value ?? []}
                                onTagsChange={(tags) => {
                                    field.onChange(tags);
                                }}
                            />
                            {fieldState.error?.message ? (
                                <div className="mt-2 flex self-baseline text-xs font-medium text-red-200">
                                    {fieldState.error?.message}
                                </div>
                            ) : null}
                        </>
                    )}
                />
                <RFTextField
                    control={control}
                    name="bonusCode"
                    fullWidth
                    label="Bonus Code"
                    type="number"
                    noArrows
                    placeholder="Bonus Code"
                />
                <RFTextField
                    control={control}
                    name="countryCode"
                    fullWidth
                    label="Country Code"
                    type="number"
                    noArrows
                    asterisk
                    placeholder="Country Code"
                />
                <RFTextField
                    control={control}
                    name="amount"
                    fullWidth
                    label="Amount"
                    type="number"
                    noArrows
                    asterisk
                    maxLength={3}
                />

                <div className="mb-4">
                    <RFSelectField
                        name="gender"
                        control={control}
                        fullWidth
                        asterisk
                        label="Gender"
                        options={GenderList}
                    />
                </div>
            </div>
            <div className="mt-3 mb-4 text-base font-bold text-black">
                Billing Address
            </div>
            <div className="grid grid-cols-2 items-center gap-x-6">
                <RFTextField
                    control={control}
                    name="address"
                    fullWidth
                    label="Address"
                    type="text"
                    asterisk
                    placeholder="Address"
                />
                <RFTextField
                    control={control}
                    name="city"
                    fullWidth
                    label="City"
                    type="text"
                    asterisk
                />
                <RFTextField
                    control={control}
                    name="state"
                    fullWidth
                    label="State"
                    type="text"
                    asterisk
                />

                <RFTextField
                    control={control}
                    name="country"
                    fullWidth
                    placeholder="Country"
                    label="Country"
                    type="text"
                    asterisk
                />

                <RFTextField
                    control={control}
                    name="zipcode"
                    fullWidth
                    placeholder="Zip Code"
                    label="Zip Code"
                    type="text"
                    asterisk
                />
            </div>
            <div className="mt-3 mb-4 text-base font-bold text-black">
                Other Information
            </div>
            <div className="grid grid-cols-2 items-center gap-x-6">
                <div className="mb-4">
                    <RFSelectField
                        name="currency"
                        control={control}
                        fullWidth
                        asterisk
                        label="Currency"
                        options={Currencies}
                    />
                </div>
                <div className="mb-4">
                    <Controller
                        name="brandId"
                        control={control}
                        render={({
                            field: { onChange, value, ...field },
                            fieldState,
                        }) => {
                            const brandName = brandList.find(
                                (v) => v._id === value
                            );
                            return (
                                <Select
                                    label="Brand"
                                    isGroup
                                    value={brandName?.name ?? ''}
                                    fullWidth
                                    options={brandList?.map((v) => ({
                                        name: v.name,
                                        id: v._id,
                                    }))}
                                    asterisk
                                    handleOnSelect={(e) => {
                                        const selectedOption = brandList?.find(
                                            (option) =>
                                                option.name === e.target.value
                                        );
                                        onChange(
                                            selectedOption?._id === ''
                                                ? undefined
                                                : selectedOption?._id
                                        );
                                    }}
                                    className={classNames(
                                        'text-gray-400',
                                        fieldState.error?.message
                                            ? 'bg-red-300 border-0 '
                                            : 'text-gray-400 bg-gray-200'
                                    )}
                                    isError={!!fieldState.error?.message}
                                    errorMessage={
                                        fieldState.error?.message ?? ''
                                    }
                                    {...field}
                                />
                            );
                        }}
                    />
                </div>
                <RFTextField
                    control={control}
                    name="comment"
                    fullWidth
                    placeholder="Comment"
                    label="Comment"
                    type="text"
                />

                <div className="flex items-center justify-between">
                    <div className="text-sm font-semibold text-gray-500">
                        Blocked
                    </div>
                    <Controller
                        name="blocked"
                        control={control}
                        render={({
                            field: { value, onChange },
                            fieldState,
                        }) => (
                            <>
                                <Switch
                                    onChange={(e) => onChange(e.target.checked)}
                                    isToggled={value}
                                    className="rounded-2xl bg-gray-200"
                                />
                                {fieldState.error?.message ? (
                                    <div className="mt-2 flex self-baseline text-xs font-medium text-red-200">
                                        {fieldState.error?.message}
                                    </div>
                                ) : null}
                            </>
                        )}
                    />
                </div>
                <div className="flex items-center justify-between">
                    <div className="text-sm font-semibold text-gray-500">
                        Kyc Status
                    </div>
                    <Controller
                        name="kycStatus"
                        control={control}
                        render={({
                            field: { value, onChange },
                            fieldState,
                        }) => (
                            <>
                                <Switch
                                    onChange={(e) => onChange(e.target.checked)}
                                    isToggled={value}
                                    className="rounded-2xl bg-gray-200"
                                />
                                {fieldState.error?.message ? (
                                    <div className="mt-2 flex self-baseline text-xs font-medium text-red-200">
                                        {fieldState.error?.message}
                                    </div>
                                ) : null}
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="mt-5 flex items-center justify-center">
                <PrimaryButton
                    type="submit"
                    isDrawerButton
                    className={classNames('w-full max-w-[300px]')}
                    color="#2E672F"
                    variant="filled"
                    name="Submit"
                    loading={loading}
                />
            </div>
        </form>
    );
};
export default Cleo;
