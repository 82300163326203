import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ITransactions } from 'data/types/response';
import { approveTransactions, getPaymentApproval } from 'services/api/api';
import { PrimaryButton, Dropdown, TextField } from 'components/Common';
import {
    ActionOptionsConstant,
    ReasonOptionsConstent,
} from 'data/constants/approve.constants';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import { ActionModal } from '../../Modal';
import { ApprovalTable } from './ApprovalTable';
import { PaymentApprovalsStatus } from '../Transactions/Transaction.type';
import { exportFiles } from './ApprovalsUtill';
import { ITransactionsId } from './Approval.type';

const PaymentApprovals = () => {
    const { auth } = useAuthContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdown, setDropdown] = useState(false);
    const [exportType, setExportType] = useState<string | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [approvalData, setApprovalData] = useState<ITransactions[]>([]);
    const [checkedApprovals, setCheckedApprovals] = useState<ITransactionsId[]>(
        []
    );
    const [actionModalData, setActionModalData] = useState({
        status: ActionOptionsConstant.APPROVE,
        reason: ReasonOptionsConstent.TECHNICAL_ISUUE,
        comment: '',
    });
    const [filterData, setFilterData] = useState<{
        status: string;
        search: string;
        brandIds: string[];
        companyId: string;
    }>({
        status: '',
        brandIds: [],
        search: '',
        companyId: '',
    });
    const dropdownRef = useRef<HTMLDivElement>(null);

    const fetchApprovalData = () => {
        setCurrentPage(1);
        setIsLoading(true);
        if (auth.selectedBrand?._id) {
            filterData.brandIds = [auth?.selectedBrand?._id];
        } else if (auth.selectedCompany?._id) {
            const selectedCompany = (auth?.companiesAndBrands || []).find(
                (company: any) => company._id === auth.selectedCompany?._id
            );
            filterData.brandIds =
                (selectedCompany.brandData || []).map(
                    ({ _id }: { _id: string }) => _id
                ) || [];
        }
        getPaymentApproval(filterData)
            .then((res) => {
                setApprovalData(res.transactions || []);
                setIsLoading(!auth.isSuperAdmin);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        fetchApprovalData();
    }, [filterData, auth?.selectedBrand, auth?.selectedCompany]);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            // If the dropdown is open and the click is outside the dropdown or on the dropdown itself, close it
            if (
                dropdown &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setDropdown(false);
            }
        };

        // Add event listener when dropdown is open
        if (dropdown) {
            document.addEventListener('mousedown', handleDocumentClick);
        }

        // Remove event listener when component is unmounted or dropdown is closed
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [dropdown]);

    const handleAction = () => {
        setIsLoading(true);
        if (checkedApprovals.length === 0) {
            return null;
        }
        const payload = {
            status: actionModalData?.status.toUpperCase(),
            reason:
                actionModalData?.status.toUpperCase() === 'APPROVE'
                    ? ReasonOptionsConstent.NULL
                    : actionModalData?.reason,
            comment: actionModalData?.comment,
            transactions: checkedApprovals,
        };
        approveTransactions(payload)
            .then(() => {
                setActionModalData({
                    status: ActionOptionsConstant.APPROVE,
                    reason: ReasonOptionsConstent.TECHNICAL_ISUUE,
                    comment: '',
                });
                setCheckedApprovals([]);
                setIsLoading(false);
                fetchApprovalData();
                setOpen(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    const handleExportApprovals = () => {
        if (!exportType && approvalData.length === 0) {
            return;
        }

        exportFiles(exportType as string, approvalData);
        setExportType(null);
    };

    useEffect(() => {
        handleExportApprovals();
    }, [exportType]);

    return (
        <div className="px-5 pt-10">
            <div className="mr-6 ml-1 flex flex-wrap items-center justify-between">
                <div className="flex flex-wrap items-center gap-5">
                    <div className="font-extrabold text-center text-2xl text-[#131119]">
                        Approvals
                    </div>
                    <TextField
                        iconPosition="left"
                        icon="SEARCH_ICON"
                        className="bg-white	border border-solid border-[#C8C8C8] min-[1480px]:!w-[400px] min-w-1/2 !pl-9"
                        placeholder="Search by Tags, filters..."
                        onChange={(e) => {
                            setFilterData({
                                ...filterData,
                                search: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="flex flex-wrap items-center gap-5">
                    <PrimaryButton
                        className=" !font-extrabold !h-[40px] !px-[10px]"
                        color="#8FB131"
                        variant="filled"
                        disabled={checkedApprovals.length === 0}
                        name="Action"
                        onClick={() => setOpen((prev) => !prev)}
                    />
                    {open && (
                        <ActionModal
                            actionModalData={actionModalData}
                            setActionModalData={setActionModalData}
                            handleAction={handleAction}
                            setIsOpen={setOpen}
                        />
                    )}
                    <div>
                        <PrimaryButton
                            className=" !font-extrabold !h-[40px] !px-[10px]"
                            color="#8FB131"
                            variant="filled"
                            name="Export"
                            aria-expanded={dropdown ? 'true' : 'false'}
                            onClick={() => setDropdown((prev) => !prev)}
                        />
                        {dropdown && (
                            <div ref={dropdownRef}>
                                <Dropdown
                                    className="right-1 z-[100]"
                                    submenus={[
                                        { title: 'Microsoft (.xlsx)', id: 1 },
                                        { title: 'CSV (.csv)', id: 2 },
                                        { title: 'PDF (.pdf)', id: 3 },
                                        {
                                            title: 'Open Document (.ods)',
                                            id: 4,
                                        },
                                        {
                                            title: 'Tab Seperated Values (.tsv)',
                                            id: 5,
                                        },
                                        { title: 'Web Page (HTML)', id: 6 },
                                    ]}
                                    handleClick={(e: any) => {
                                        setExportType(e);
                                        setDropdown(false);
                                    }}
                                    dropdown={dropdown}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex gap-4 pt-4">
                {PaymentApprovalsStatus.map((v) => (
                    <PrimaryButton
                        key={`keys_${v.value}`}
                        className={classNames(
                            '!text-black !text-xs px-3 !font-extrabold !rounded-xl',
                            {
                                '!bg-[#DEECAA]':
                                    v.name === 'All Payments'
                                        ? !filterData.status
                                        : filterData.status === v.value,
                                '!bg-white': filterData.status !== v.value,
                            }
                        )}
                        color="#8FB131"
                        variant="filled"
                        name={v.name}
                        onClick={() =>
                            setFilterData({
                                ...filterData,
                                status: v.value,
                            })
                        }
                    />
                ))}
            </div>
            <ApprovalTable
                setCheckedApprovals={setCheckedApprovals}
                checkedApprovals={checkedApprovals}
                loading={loading}
                currentPage={currentPage}
                approvalData={approvalData}
                setCurrentPage={setCurrentPage}
            />
        </div>
    );
};
export default PaymentApprovals;
