import { useCallback } from 'react';
import {
    useStore,
    Position,
    internalsSymbol,
    Node,
    XYPosition,
    EdgeProps,
} from 'reactflow';
import './SimpleFloatingEdges.css';
import { EdgeParams, Handle } from '../RuleBuilder.type';

function getParams(nodeA: Node, nodeB: Node): [number, number, Position] {
    const centerA = getNodeCenter(nodeA);
    const centerB = getNodeCenter(nodeB);

    const horizontalDiff = Math.abs(centerA.x - centerB.x);
    const verticalDiff = Math.abs(centerA.y - centerB.y);

    let position: Position;

    if (horizontalDiff > verticalDiff) {
        position = centerA.x > centerB.x ? Position.Left : Position.Right;
    } else {
        position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
    }

    const [x, y] = getHandleCoordsByPosition(nodeA, position);
    return [x, y, position];
}

function getHandleCoordsByPosition(
    node: Node,
    handlePosition: Position
): [number, number] {
    const handle = (node as any)[internalsSymbol].handleBounds.source.find(
        (h: Handle) => h.position === handlePosition
    );
    if (!handle) {
        return [0, 0];
    }

    let offsetX = handle.width ? handle.width / 2 : 0;
    let offsetY = handle.height ? handle.height / 2 : 0;

    switch (handlePosition) {
        case Position.Left:
            offsetX = 0;
            break;
        case Position.Right:
            offsetX = handle.width;
            break;
        case Position.Top:
            offsetY = 0;
            break;
        case Position.Bottom:
            offsetY = handle.height;
            break;
        default:
            break;
    }

    const x =
        (node.positionAbsolute as XYPosition).x + Number(handle.x) + offsetX;
    const y =
        (node.positionAbsolute as XYPosition).y + Number(handle.y) + offsetY;

    return [x, y];
}

function getNodeCenter(node: Node): { x: number; y: number } {
    return {
        x: (node.positionAbsolute as XYPosition).x + (node.width as number) / 2,
        y:
            (node.positionAbsolute as XYPosition).y +
            (node.height as number) / 2,
    };
}

export function getEdgeParams(source: Node, target: Node): EdgeParams {
    const [sx, sy, sourcePos] = getParams(source, target);
    const [tx, ty, targetPos] = getParams(target, source);

    return {
        sx,
        sy,
        tx,
        ty,
        sourcePos,
        targetPos,
    };
}

interface SimpleFloatingEdgeProps extends EdgeProps {
    markerEnd?: string;
    markerStart?: string;
    style?: React.CSSProperties;
}

function SimpleFloatingEdge({
    id,
    source,
    target,
    markerEnd,
    style,
    markerStart,
}: SimpleFloatingEdgeProps) {
    const sourceNode = useStore(
        useCallback((store) => store.nodeInternals.get(source), [source])
    );
    const targetNode = useStore(
        useCallback((store) => store.nodeInternals.get(target), [target])
    );

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

    return (
        <line
            key={id}
            id={id}
            className="react-flow__edge-path"
            x1={sx}
            y1={sy}
            x2={tx}
            y2={ty}
            strokeWidth={5}
            markerEnd={markerEnd}
            markerStart={markerStart}
            style={style}
        />
    );
}

export default SimpleFloatingEdge;
