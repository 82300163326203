import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { IMenuItem } from 'data/common';
import SvgIcon from '../Icon/SvgIcon';

interface IMenubarProps {
    menuItem: IMenuItem[] | null;
}
const Menubar = ({ menuItem }: IMenubarProps) => {
    const location = useLocation();

    const [isActiveIndex, setIsActiveIndex] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [path, setPath] = useState<string | undefined>(location.pathname);

    useEffect(() => {
        if (location.pathname.includes('admin')) {
            setIsActiveIndex('admin');
        }
        if (location.pathname.includes('payment')) {
            setIsActiveIndex('payment');
        }
        if (location.pathname.includes('dashboard')) {
            setIsActiveIndex('dashboard');
        }
        if (location.pathname.includes('audit')) {
            setIsActiveIndex('audit');
        }
        if (location.pathname.includes('cashier')) {
            setIsActiveIndex('cashier');
        }
    }, [location.pathname]);

    return (
        <div className="flex h-full flex-col justify-between px-2">
            <div>
                {(menuItem || []).map((v: IMenuItem) => (
                    <div
                        key={v.title}
                        className={
                            !v?.path ? 'pointer-events-none opacity-50' : ''
                        }
                    >
                        <Link
                            to={v?.path as string}
                            className="!decoration-0 text-green-800 !border-b-0 hover:text-white !h-[40px]"
                        >
                            <div
                                key={v.id}
                                className={classNames(
                                    'flex flex-col hover:bg-green-100 rounded-lg focus:bg-green-100 mt-1',
                                    (location.pathname === v.path ||
                                        location.pathname.includes(
                                            v?.path as string
                                        )) &&
                                        isActiveIndex === v.path?.split('/')[1]
                                        ? 'bg-green-100'
                                        : ''
                                )}
                            >
                                <div
                                    className={classNames(
                                        'flex items-center justify-between hover:!fill-white hover:!stroke-white hover:!stroke-2'
                                    )}
                                    key={v.title}
                                    onClick={() => {
                                        if (!v.subMenu) return;

                                        if (path === v.path) {
                                            setIsOpen(!isOpen);
                                        } else {
                                            setIsOpen(true);
                                        }
                                        setPath(v.path);
                                        setIsActiveIndex(
                                            v.path?.split('/')[1] as string
                                        );
                                    }}
                                >
                                    <div className="ml-3 flex items-center">
                                        <SvgIcon
                                            icon={v.icon}
                                            className={classNames(
                                                'fill-green-100 flex justify-center text-center h-6',

                                                location.pathname === v.path ||
                                                    location.pathname.includes(
                                                        v?.path as string
                                                    )
                                                    ? '!fill-white stroke-white stroke-2'
                                                    : ''
                                            )}
                                        />

                                        <div
                                            className={classNames(
                                                'ml-4 text-base font-extrabold py-2 ',
                                                (location.pathname === v.path ||
                                                    location.pathname.includes(
                                                        v?.path as string
                                                    )) &&
                                                    isActiveIndex ===
                                                        v.path?.split('/')[1]
                                                    ? 'text-white '
                                                    : ''
                                            )}
                                        >
                                            {v?.title}
                                        </div>
                                    </div>
                                    <div className="mr-4">
                                        {v.subMenu && (
                                            <SvgIcon
                                                className={classNames(
                                                    'stroke-2 stroke-[#2E672F] ',
                                                    !location.pathname.includes(
                                                        v?.path as string
                                                    )
                                                        ? 'hover:!stroke-2 hover:!stroke-white'
                                                        : ''
                                                )}
                                                icon="ARROW_DOWN"
                                            />
                                        )}
                                        {v.subMenu &&
                                            isOpen &&
                                            ((location.pathname.includes(
                                                v?.path as string
                                            ) &&
                                                isActiveIndex ===
                                                    v.path?.split('/')[1]) ||
                                                (location.pathname === v.path &&
                                                    isActiveIndex ===
                                                        v.path?.split(
                                                            '/'
                                                        )[1])) && (
                                                <SvgIcon icon="ARROW_UP" />
                                            )}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {isActiveIndex === v.path?.split('/')[1] &&
                            isOpen &&
                            v.subMenu && (
                                <div className="mx-4 mt-1 ml-4 border border-y-0 border-r-0 border-solid border-green-100">
                                    {(v?.subMenu || []).map((d, index) => (
                                        <div
                                            className="py-1 pl-2"
                                            key={d?.title}
                                        >
                                            <Link to={d?.path}>
                                                <div
                                                    className={classNames(
                                                        'ml-1 text-green-100 text-xs font-extrabold',
                                                        location.pathname ===
                                                            d.path ||
                                                            (index === 0 &&
                                                                (location.pathname ===
                                                                    '/admin' ||
                                                                    location.pathname ===
                                                                        '/payment' ||
                                                                    location.pathname ===
                                                                        '/cashier'))
                                                            ? 'text-green-200'
                                                            : ''
                                                    )}
                                                >
                                                    {d?.title}
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Menubar;
