import { ChangeEvent, useMemo, useState } from 'react';
import { Pagination, Table } from 'components/Common';
import { GroupsTableColumn } from './GroupsTableUtill';
import { IGroupTableProps } from '../Groups.type';

export const GroupsTable = ({
    allGroups,
    setAllGroups,
    checkedGroups,
    setCheckedGroups,
    setEditGroupDetails,
    setUserCompany,
    setIsEditGroupDrawer,
    setIsOpen,
}: IGroupTableProps) => {
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState(1);

    const isAllChecked =
        allGroups?.length > 0 && allGroups?.length === checkedGroups?.length;

    const currentTableData = useMemo(
        () =>
            allGroups?.slice(
                currentPage * pageLimit - pageLimit,
                currentPage * pageLimit
            ),
        [allGroups, currentPage, pageLimit]
    );

    const updatedGroupStatus = (id: string, status: boolean) => {
        const filter = allGroups.map((v) => {
            if (v._id === id) {
                return {
                    ...v,
                    isDeleted: status,
                };
            }
            return v;
        });
        setAllGroups([...filter]);
    };

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (currentTableData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
    };

    return (
        <div>
            <div className="max-h-[calc(100vh-261px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl">
                <div className="flex w-full max-w-full items-center">
                    <Table
                        data={currentTableData}
                        columns={GroupsTableColumn(
                            isAllChecked,
                            setCheckedGroups,
                            allGroups,
                            checkedGroups,
                            setEditGroupDetails,
                            updatedGroupStatus,
                            setUserCompany,
                            setIsEditGroupDrawer,
                            setIsOpen
                        )}
                        className="w-full"
                        colSpan={5}
                    />
                </div>
            </div>

            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={allGroups?.length}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => setCurrentPage(page)}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Groups"
                />
            </div>
        </div>
    );
};
