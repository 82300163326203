import { IOptionProps } from '../common';

export enum ActionOptionsConstant {
    APPROVE = 'Approve',
    DELAY_APPROVAL = 'DELAY APPROVAL',
    DENY = 'DENY',
    HOLD = 'HOLD',
    UNHOLD = 'UNHOLD',
}

export enum ReasonOptionsConstent {
    TECHNICAL_ISUUE = 'Technical Issue',
    CHARGEBACK = 'Chargeback',
    REQUEST_BY_PROVIDER = 'Request by Provider',
    REFUND = 'Refund',
    OTHER = 'Other',
    NULL = '',
}

export const ActionOptions: IOptionProps[] = [
    { key: ActionOptionsConstant.APPROVE, title: 'Approve' },
    { key: ActionOptionsConstant.DELAY_APPROVAL, title: 'Delay Approval' },
    { key: ActionOptionsConstant.DENY, title: 'Deny' },
    { key: ActionOptionsConstant.HOLD, title: 'Hold' },
    { key: ActionOptionsConstant.UNHOLD, title: 'Unhold' },
];

export const ReasonOptions: IOptionProps[] = [
    {
        key: ReasonOptionsConstent.TECHNICAL_ISUUE,
        title: ReasonOptionsConstent.TECHNICAL_ISUUE,
    },
    {
        key: ReasonOptionsConstent.CHARGEBACK,
        title: ReasonOptionsConstent.CHARGEBACK,
    },
    {
        key: ReasonOptionsConstent.REQUEST_BY_PROVIDER,
        title: ReasonOptionsConstent.REQUEST_BY_PROVIDER,
    },
    { key: ReasonOptionsConstent.REFUND, title: ReasonOptionsConstent.REFUND },
    { key: ReasonOptionsConstent.OTHER, title: ReasonOptionsConstent.OTHER },
];
