export enum CountryConstant {
    SELECT_COUNTRY = 'Select Country',
    INDIA = 'India',
    US = 'US',
    CANADA = 'canada',
}

export const Countries = [
    {
        _id: '',
        name: CountryConstant.SELECT_COUNTRY,
    },
    { _id: CountryConstant.INDIA, name: CountryConstant.INDIA },
    { _id: CountryConstant.US, name: CountryConstant.US },
    {
        _id: CountryConstant.CANADA,
        name: CountryConstant.CANADA,
    },
];
