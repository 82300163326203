export enum PaymentProviderConstant {
    TRUST = 'TRUST',
    CLEO = 'CLEO',
    PAYMODUM = 'PAYMODUM',
    TRIPLE000 = 'TRIPLEZERO',
}

export const PaymentProvider = [
    {
        _id: PaymentProviderConstant.TRUST,
        name: PaymentProviderConstant.TRUST,
    },
    { _id: PaymentProviderConstant.CLEO, name: PaymentProviderConstant.CLEO },
    {
        _id: PaymentProviderConstant.PAYMODUM,
        name: PaymentProviderConstant.PAYMODUM,
    },
];

export enum ActionOptionsConstant {
    APPROVED = 'Approved',
    DENIED = 'Denied',
}
export const ActionOptions = [
    {
        _id: ActionOptionsConstant.APPROVED,
        name: ActionOptionsConstant.APPROVED,
    },
    {
        _id: ActionOptionsConstant.DENIED,
        name: ActionOptionsConstant.DENIED,
    },
];

export enum CardConstant {
    MASTERCARD = 'MASTERCARD',
    VISA = 'VISA',
}

export const CardType = [
    { _id: CardConstant.MASTERCARD, name: CardConstant.MASTERCARD },
    {
        _id: CardConstant.VISA,
        name: CardConstant.VISA,
    },
];
