import React, { useState } from 'react';
import { getOutgoers, Handle, Position, useReactFlow } from 'reactflow';
import { SvgIcon } from 'components/Common';
import { CustomNodeProps } from '../../RuleEngine.type';

export const CustomNode = ({ data, isConnectable }: CustomNodeProps) => {
    const { getEdges, getNodes, deleteElements } = useReactFlow();
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const removeTreeOfChildNode = (id: string) => {
        const nodes = getNodes();
        const edges = getEdges();
        // we get all outgoers of this node
        const outgoers = getOutgoers(
            { data: undefined, position: { x: 0, y: 0 }, type: '', id },
            getNodes(),
            getEdges()
        );
        const outgoersList = nodes.filter((node) =>
            edges.some((edge) => edge.source === id && edge.target === node.id)
        );

        // if there are outgoers we proceed
        if (outgoers.length) {
            // we remove the outgoer nodes
            deleteElements({ nodes: outgoers });

            // delete connected edges
            const connectedEdges = edges.filter((edge) =>
                outgoersList.some(
                    (outgoer) =>
                        edge.source === outgoer.id || edge.target === outgoer.id
                )
            );
            deleteElements({ edges: connectedEdges });

            // we loop through the outgoers and try to remove any outgoers of our outgoers
            outgoers.forEach((outgoer) => {
                removeTreeOfChildNode(outgoer.id);
            });
        }
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            key={data.id}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {data.label}{' '}
            {data?.items &&
                `${data?.operation || '='} ${data?.items as string}`}
            <Handle
                key={`${data.id}-right`}
                type="source"
                position={Position.Right}
                id={data?.id}
                isConnectable={isConnectable}
            >
                <div>
                    <SvgIcon
                        icon="ADD_ICON"
                        className="cursor-pointer pointer-events-none relative"
                    />
                    {isHovered && (
                        <div
                            id={data.id}
                            className="!cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                removeTreeOfChildNode(data.id);
                                if (data.onClick) {
                                    data.onClick?.(data.id);
                                } else {
                                    removeTreeOfChildNode(
                                        String(Number(data.id) - 1)
                                    );
                                }
                            }}
                        >
                            <SvgIcon
                                icon="CROSS_DELETE_ICON"
                                className="absolute right-8"
                            />
                        </div>
                    )}
                </div>
            </Handle>
            <Handle
                key={`${data.id}-left`}
                type="source"
                position={Position.Left}
                id={data?.id}
                isConnectable={isConnectable}
            >
                <div>
                    <SvgIcon
                        icon="ADD_ICON"
                        className="cursor-pointer pointer-events-none relative"
                    />
                    <div id="draggable" className="!cursor-pointer">
                        <SvgIcon
                            icon="BIG_DRAG_ICON"
                            className="stroke-[#383838] absolute left-5"
                        />
                    </div>
                </div>
            </Handle>
        </div>
    );
};
