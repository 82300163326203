export const getTransactionStatus = (transaction: any): string => {
    if (transaction.pspType === 'CLEO' || transaction.pspType === 'PAYMODUM') {
        return transaction.status as string;
    }
    if (transaction.pspType === 'TRUST') {
        switch (transaction.settlestatus) {
            case '0':
                return 'PENDING';
            case '1':
                return 'MANUAL';
            case '2':
                return 'SUSPENDED';
            case '3':
                return 'CANCELLED';
            case '10':
                return 'SETTING';
            case '100':
                return 'SUCCESS';
            default:
                return 'ERROR';
        }
    }
    return '';
};
