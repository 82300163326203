import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IBrandsPermissions, IPagePermissions } from '../common';
import { UserRoles } from '../constants/auth.constants';

export const validate = (
    key: string,
    value: string | null | IPagePermissions[] | IBrandsPermissions[] | string[]
) => {
    const emailRegx = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

    switch (key) {
        case 'email':
            if (!value) {
                return 'Email is required';
            }
            if (!new RegExp(emailRegx).test(value as string)) {
                return 'Please check the email address';
            }
            return '';
        case 'companyAdminEmail':
            if (!value) {
                return 'Please enter Company Email-address';
            }
            if (!new RegExp(emailRegx).test(value as string)) {
                return 'Please check the Email address';
            }
            return '';
        case 'role':
            if (!value) {
                return 'Please select user role';
            }
            return '';
        case 'paymentMethod':
            if (!value) {
                return 'Please select payment method';
            }
            return '';
        case 'companyTelephone':
            if (!value) {
                return 'Please enter company Phone number';
            }
            return '';

        case 'password':
            if (!value) {
                return 'Password is required';
            }
            return '';
        case 'job':
            if (!value) {
                return 'job is required';
            }
            return '';

        case 'confirmPassword':
            if (!value) {
                return 'confirmPassword is required';
            }
            return '';

        case 'name':
            if (!value) {
                return 'Please enter  name';
            }
            return '';
        case 'emailVerification':
            if (!value) {
                return 'Email Verification is required';
            }
            return '';
        case 'verificationDate':
            if (!value) {
                return 'Verification Date is required';
            }
            return '';

        case 'partnerName':
            if (!value) {
                return 'Partner Name is required';
            }
            return '';

        case 'userBalance':
            if (!value) {
                return 'User Balance is required';
            }
            return '';
        case 'firstName':
            if (!value) {
                return 'Please enter  first name';
            }
            return '';
        case 'lastName':
            if (!value) {
                return 'Please enter  last name';
            }
            return '';
        case 'groupName':
            if (value === '') {
                return 'Please enter your group name';
            }
            return '';

        case 'phone':
            if (!value) {
                return 'Please enter your phone number';
            }
            return '';
        case 'dateOfBirth':
            if (!value) {
                return 'Please enter your birthDate';
            }
            return '';
        case 'line1':
            if (!value) {
                return 'Please enter your line1';
            }
            return '';
        case 'postalcode':
            if (!value) {
                return 'Please enter your postalcode';
            }
            return '';
        case 'zipcode':
            if (!value) {
                return 'Please enter your zipcode';
            }
            return '';
        case 'countryCode':
            if (!value) {
                return 'Please enter your country code';
            }
            return '';
        case 'address':
            if (!value) {
                return 'Please enter your address';
            }
            return '';
        case 'city':
            if (!value) {
                return 'Please enter your city';
            }
            return '';
        case 'state':
            if (!value) {
                return 'Please enter your state';
            }
            return '';
        case 'region':
            if (!value) {
                return 'Please enter your region';
            }
            return '';
        case 'country':
            if (!value) {
                return 'Please enter your country';
            }
            return '';
        case 'gender':
            if (!value) {
                return 'Please enter your gender';
            }
            return '';

        case 'brandsPermissions':
            if ((value as IBrandsPermissions[])?.length < 1) {
                return 'Please select your Brands';
            }
            return '';

        case 'brandId':
            if (!value) {
                return 'Please select your Brand';
            }
            return '';

        case 'company':
            if (!value) {
                return 'Please select your company';
            }
            return '';
        case 'group':
            if (value === '') {
                return 'Please select your group';
            }
            return '';
        case 'superAdminCompany':
            if (value === '' || value === undefined) {
                return 'Please select your admin Company';
            }
            return '';

        case 'adminUser':
            if (value === '') {
                return 'Please select your Company admin';
            }
            return '';

        case 'pageAreasPermissions':
            if ((value as IPagePermissions[])?.length < 1) {
                return 'Please select your pageAreas';
            }
            return '';

        case 'companyName':
            if (!value) {
                return 'Please enter company name';
            }
            return '';
        case 'companyAdminFirstName':
            if (!value) {
                return 'Please enter company Admin-First Name';
            }
            return '';
        case 'companyAdminLastName':
            if (!value) {
                return 'Please enter company Admin-Last Name';
            }
            return '';

        case 'description':
            if (!value) {
                return 'Please enter company description';
            }
            return '';
        case 'brandName':
            if (!value) {
                return 'Please enter Brand name';
            }
            return '';

        case 'pspType':
            if (!value) {
                return 'Please enter pspType';
            }
            return '';

        case 'locals':
            if (!value) {
                return 'Please enter locals';
            }
            return '';
        case 'expiry':
            if (!value) {
                return 'Please enter expiry';
            }
            return '';
        case 'amount':
            if (!value) {
                return 'Please enter amount';
            }
            return '';
        case 'total':
            if (!value) {
                return 'Please enter total';
            }
            return '';
        case 'card_number':
            if (!value) {
                if (value?.length === 12) {
                    return 'Please enter 12 card number';
                }
                return 'Please enter card number';
            }
            return '';
        case 'cvv':
            if (!value) {
                if (value?.length === 12) {
                    return 'Please enter 3 cvv number';
                }
                return 'Please enter cvv';
            }
            return '';

        case 'providerTransactionId':
            if (!value) {
                return 'Please select your providerTransactionId';
            }
            return '';

        case 'currencies':
            if (!value || value.length === 0) {
                return 'Please enter currency';
            }
            return '';
        case 'currency':
            if (!value) {
                return 'Please enter currency';
            }
            return '';
        case 'merchant':
            if (!value) {
                return 'Please enter merchant';
            }
            return '';
        case 'prefix':
            if (!value) {
                return 'Please enter prefix';
            }
            return '';
        case 'countries':
            if (!value || value.length === 0) {
                return 'Please enter country';
            }
            return '';
        case 'comment':
            if (!value || value.length === 0) {
                return 'Please enter comment';
            }
            return '';
        default:
            return '';
    }
};

export const get = (obj: Record<string, any>, path: string, def?: any): any => {
    const fullPath = path
        .replace(/\[/g, '.')
        .replace(/]/g, '')
        .split('.')
        .filter(Boolean);

    if (obj == null) {
        return def || null;
    }

    function everyFunc(step: string): boolean | null {
        if (obj[step] !== null) {
            // eslint-disable-next-line no-param-reassign
            const resultEveryFunc = !(step && (obj = obj[step]) === undefined);
            return resultEveryFunc;
        }
        return null;
    }

    return fullPath.every(everyFunc) ? obj : def;
};

export const getDateFormat = (dateValue: string) => {
    const date = new Date(dateValue).getDate();
    const month = new Date(dateValue).getMonth() + 1;
    const year = new Date(dateValue).getFullYear();

    if (month.toString().length === 1 && date.toString().length !== 1) {
        return [`0${month}`, date, year].join('/');
    }
    if (date.toString().length === 1 && month.toString().length !== 1) {
        return [month, `0${date}`, year].join('/');
    }
    if (date.toString().length === 1 && month.toString().length === 1) {
        return [`0${month}`, `0${date}`, year].join('/');
    }
    return [month, date, year].join('/');
};

dayjs.extend(utc);

export const getDateFormats = (dateValue: string) => {
    const utcDate = dayjs.utc(dateValue);
    const utcDay = utcDate.format('DD');
    const utcMonth = utcDate.format('MM');
    const utcYear = utcDate.format('YYYY');

    return `${utcMonth}/${utcDay}/${utcYear}`;
};

export const getFirstDateFormat = (dateValue: string) => {
    const utcDate = dayjs.utc(dateValue);
    const utcDay = utcDate.format('DD');
    const utcMonth = utcDate.format('MM');
    const utcYear = utcDate.format('YYYY');

    return `${utcDay}/${utcMonth}/${utcYear}`;
};

export const switchDate = (val: string, dateType?: string): number => {
    const dateOfBirth = {
        day: +val.slice(8, 10),
        month: +val.slice(5, 7),
        year: +val.slice(0, 4),
    };

    const dateTime = {
        day: new Date(val).getUTCDate(),
        month: new Date(val).getUTCMonth() + 1, // Months are zero-based, so add 1
        year: new Date(val).getUTCFullYear(),
        hour: new Date(val).getUTCHours(),
        minute: new Date(val).getUTCMinutes(),
    };

    switch (dateType) {
        case 'dateOfBirth': {
            const { year, month, day } = dateOfBirth;

            return new Date(year, month, day).getTime();
        }

        case 'date': {
            const { day, month, year, hour, minute } = dateTime;
            return new Date(year, month, day, hour, minute).getTime();
        }

        default: {
            const { day, month, year, hour, minute } = dateTime;

            return new Date(year, month, day, hour, minute).getTime();
        }
    }
};

export const formatNumber = (number: number, round?: number): string => {
    if (!number) {
        return '0.00';
    }
    const roundDecimal = round ?? 2;
    return number.toFixed(roundDecimal);
};

export const isAdmin = (role: UserRoles | null): boolean =>
    role === UserRoles.ADMIN;
export const isUser = (role: UserRoles | null): boolean =>
    role === UserRoles.USER;
export const isSuperAdmin = (role: UserRoles | null): boolean =>
    role === UserRoles.SUPER_ADMIN;
