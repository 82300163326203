import { ModalConstants } from 'data/constants/modal.constants';
import { getDateFormat, formatNumber } from 'data/utils/common';
import { getTransactionStatus } from 'data/utils/transactions';

// a² === \u00B2 for the non-ASCII characters, consider encoding them.
// a¹ === \u00B9 for the non-ASCII characters, consider encoding them.

const getPaymentFields = (key: string, transaction: any) => {
    const userCurrency: string =
        key === 'PAYMODUM'
            ? transaction?.transactionBaseCurrencyConverted || ''
            : (transaction?.currencyiso3a as string);
    const baseAmount = formatNumber(Number(transaction?.baseamount));
    const amountWithCurrency = baseAmount
        ? `${baseAmount} ${userCurrency}`
        : null;
    switch (key) {
        case 'CLEO':
            return [
                {
                    'method\u00B9': transaction?.method || '-',
                    'MerchantID\u00B9': transaction?.brandId || '-',
                    'transactionID\u00B2': transaction?.sessionId || '-',
                    'created\u00B9':
                        getDateFormat(
                            (transaction?.createdAt ||
                                transaction?.updateAt ||
                                transaction?.created) as string
                        ) || '-',
                    'brandID\u00B9': transaction.brandId || '-',
                    'type\u00B9':
                        transaction.method === 'CLEO_PAYOUT'
                            ? 'Withdrawal'
                            : 'Deposit',
                    'status\u00B9': transaction?.status || '-',
                    'initial_PSP\u00B9': transaction?.initialPsp || '-',
                    'final_PSP\u00B9': transaction?.finalPsp || '-',
                    'transaction_amount\u00B9': `${formatNumber(
                        Number(transaction.amount),
                        2
                    )} CLP`,
                    'transaction_Base_Amount\u00B9':
                        transaction.transactionBaseAmountConverted
                            ? `${formatNumber(
                                  transaction.transactionBaseAmountConverted
                              )} ${
                                  transaction.transactionBaseCurrencyConverted as string
                              }`
                            : '-',
                    'update_Date\u00B9':
                        getDateFormat(
                            (transaction?.updateAt ||
                                transaction?.createdAt ||
                                transaction?.created) as string
                        ) || '-',
                    'currency\u00B9': transaction?.currency || 'CLP',
                    'user_email\u00B9': transaction?.email || '-',
                    'user_country\u00B9': transaction?.country || '-',
                },
            ];
        case 'TRUST':
            return [
                {
                    'method\u00B9': transaction?.method || 'CARD_PAYIN',
                    'MerchantID\u00B9': transaction?.merchantnumber || '-',
                    'transactionID\u00B9': transaction?._id || '-',
                    'created\u00B9':
                        getDateFormat(
                            transaction?.createdAt || transaction?.updatedAt
                        ) || '-',
                    'brandID\u00B9': transaction?.brandId || '-',
                    'type\u00B9': transaction?.paymenttypedescription || '-',
                    'status\u00B2': getTransactionStatus(transaction),
                    'initial_PSP\u00B9': transaction?.initialPsp || '-',
                    'final_PSP\u00B9': transaction?.finalPsp || '-',
                    'transaction_amount\u00B9': amountWithCurrency,
                    'transaction_Base_Amount\u00B9':
                        transaction.transactionBaseAmountConverted
                            ? `${formatNumber(
                                  transaction.transactionBaseAmountConverted
                              )} ${
                                  transaction.transactionBaseCurrencyConverted as string
                              }`
                            : '-',
                    'update_Date\u00B9':
                        getDateFormat(
                            transaction?.updatedAt || transaction?.createdAt
                        ) || '-',
                    'currency\u00B9': transaction?.userInfo?.currency || '-',
                    'user_email\u00B9': transaction?.userInfo?.email || '-',
                    'user_country\u00B9':
                        transaction?.userInfo?.country ||
                        transaction?.userInfo?.billing_address?.country ||
                        '-',
                },
            ];
        case 'PAYMODUM':
            return [
                {
                    'method\u00B9': transaction?.method || 'CARD_PAYIN',
                    'MerchantID\u00B9': transaction?.merchantnumber || '-',
                    'transactionID\u00B9': transaction?._id || '-',
                    'created\u00B9':
                        getDateFormat(
                            transaction?.createdAt || transaction?.updatedAt
                        ) || '-',
                    'brandID\u00B9': transaction?.brandId || '-',
                    'type\u00B9': transaction?.paymenttypedescription || '-',
                    'status\u00B2': getTransactionStatus(transaction),
                    'initial_PSP\u00B9': transaction?.initialPsp || '-',
                    'final_PSP\u00B9': transaction?.finalPsp || '-',
                    'transaction_amount\u00B9': amountWithCurrency,
                    'transaction_Base_Amount\u00B9':
                        transaction.transactionBaseAmountConverted
                            ? `${formatNumber(
                                  transaction.transactionBaseAmountConverted
                              )} ${
                                  transaction.transactionBaseCurrencyConverted as string
                              }`
                            : '-',
                    'update_Date\u00B9':
                        getDateFormat(
                            transaction?.updatedAt || transaction?.createdAt
                        ) || '-',
                    'currency\u00B9': transaction?.userInfo?.currency || '-',
                    'user_email\u00B9': transaction?.userInfo?.email || '-',
                    'user_country\u00B9':
                        transaction?.userInfo?.country ||
                        transaction?.userInfo?.billing_address?.country ||
                        '-',
                },
            ];
        default:
            return [];
    }
};

const getUserInformationFields = (key: string, transaction: any) => {
    const { userInfo } = transaction || {};
    const userCurrency: string =
        key === 'PAYMODUM'
            ? transaction?.transactionBaseCurrencyConverted || ''
            : (transaction?.currencyiso3a as string);
    const baseAmount = formatNumber(Number(userInfo?.userBalance));
    const amountWithCurrency = baseAmount
        ? `${baseAmount} ${userCurrency || ''}`
        : null;
    switch (key) {
        case 'CLEO':
            return [
                {
                    'playerID\u00B9': transaction?._id || '-',
                    'firstname\u00B9': transaction?.firstName ?? '-',
                    'lastName\u00B9': transaction?.lastName ?? '-',
                    'tag\u00B9':
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ') || '-',
                    'email_address\u00B9': transaction?.email ?? '-',
                    'phone_number\u00B9': transaction?.phone ?? '-',
                    'country_code\u00B9': transaction?.countryCode ?? '-',
                    'marital_status\u00B2': transaction?.maritalStatus ?? '-',
                    'is_foreigner\u00B2':
                        transaction?.isForeigner === true ? 'true' : 'false',
                    'Address\u00B9': transaction?.address ?? '-',
                    'city\u00B9': transaction?.city ?? '-',
                    'state\u00B9': transaction?.state ?? '-',
                    'country\u00B9': transaction?.country ?? '-',
                    'zip_code\u00B9': transaction?.zipcode ?? '-',
                    'register_date\u00B9':
                        getDateFormat(
                            (transaction?.createdAt ||
                                transaction?.updateAt ||
                                transaction?.created) as string
                        ) || '-',
                    'gender\u00B9': transaction?.gender ?? '-',
                    'email_verification\u00B9':
                        transaction?.emailVerification || '-',
                    'verification_date\u00B9': transaction?.verificationDate
                        ? getDateFormat(transaction?.verificationDate as string)
                        : '-',
                    'partner_name\u00B9': transaction?.partnerName || '-',
                    'user_balance\u00B9':
                        formatNumber(transaction?.userBalance) || '-',
                },
            ];
        case 'TRUST':
            return [
                {
                    'playerID\u00B9': transaction?._id || '-',
                    'firstname\u00B9': userInfo?.firstName ?? '-',
                    'lastName\u00B9': userInfo?.lastName ?? '-',
                    'tag\u00B9':
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ') || '-',
                    'email_address\u00B9': userInfo?.email ?? '-',
                    'phone_number\u00B9': userInfo?.phone ?? '-',
                    // country_code: userInfo?.countryCode ?? '-',
                    'Address\u00B9': userInfo?.billing_address?.line1 ?? '-',
                    'city\u00B9': userInfo?.billing_address?.city ?? '-',
                    'state\u00B9': userInfo?.billing_address?.state ?? '-',
                    'country\u00B9': userInfo?.billing_address?.country ?? '-',
                    'zip_code\u00B9':
                        userInfo?.billing_address?.postal_code ?? '-',
                    'register_date\u00B9': getDateFormat(
                        transaction?.transactionstartedtimestamp as string
                    ),
                    'gender\u00B9': userInfo?.gender ?? '-',
                    'email_verification\u00B9':
                        userInfo?.emailVerification || '-',
                    'verification_date\u00B9': userInfo?.verificationDate
                        ? getDateFormat(userInfo?.verificationDate as string)
                        : '-',
                    'partner_name\u00B9': userInfo?.partnerName || '-',
                    'user_balance\u00B9': amountWithCurrency || '-',
                },
            ];
        case 'PAYMODUM':
            return [
                {
                    'playerID\u00B9': transaction?._id || '-',
                    'firstname\u00B9': userInfo?.firstName ?? '-',
                    'lastName\u00B9': userInfo?.lastName ?? '-',
                    'tag\u00B9':
                        transaction?.tags
                            ?.map((item: string) => item)
                            ?.join(', ') || '-',
                    'email_address\u00B9': userInfo?.email ?? '-',
                    'phone_number\u00B9': userInfo?.phone ?? '-',
                    // country_code: userInfo?.countryCode ?? '-',
                    'Address\u00B9': userInfo?.billing_address?.line1 ?? '-',
                    'city\u00B9': userInfo?.billing_address?.city ?? '-',
                    'state\u00B9': userInfo?.billing_address?.state ?? '-',
                    'country\u00B9': userInfo?.billing_address?.country ?? '-',
                    'zip_code\u00B9':
                        userInfo?.billing_address?.postal_code ?? '-',
                    'register_date\u00B9':
                        transaction?.transactionstartedtimestamp
                            ? getDateFormat(
                                  transaction?.transactionstartedtimestamp as string
                              )
                            : '-',
                    'gender\u00B9': userInfo?.gender ?? '-',
                    'email_verification\u00B9':
                        userInfo?.emailVerification || '-',
                    'verification_date\u00B9': userInfo?.verificationDate
                        ? getDateFormat(userInfo?.verificationDate as string)
                        : '-',
                    'partner_name\u00B9': userInfo?.partnerName || '-',
                    'user_balance\u00B9': amountWithCurrency || '-',
                },
            ];
        default:
            return [];
    }
};

const getTransactionInformationFields = (key: string, transaction: any) => {
    const { userInfo } = transaction || {};
    switch (key) {
        case 'CLEO':
            return [
                {
                    'psp_ref\u00B2': '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': transaction?.sessionId || '-',
                    'tr_ref_id\u00B2': '-',
                    'tx.type\u00B9': transaction?.transactionType || '-',
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction.amount)
                    )} CLP`,
                    'psp_status\u00B2': transaction?.status || '-',
                    'account Number\u00B2': transaction?.accountNumber || '-',
                    'account Type\u00B2': transaction?.accountType || '-',
                    'bank\u00B2': transaction?.bank || '-',
                    'psp_user_red_id\u00B2': transaction?.externalKey || '-',
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': transaction?.blocked?.toString() || '-',
                    'updated_by\u00B9': transaction?.updatedByUsername || '-',
                    'ip_city\u00B9': transaction?.ipCity || '-',
                    'ip_region\u00B9': transaction?.ipRegion || '-',
                    'card_issuer_country\u00B2': '-',
                    'ip_country\u00B9': transaction?.ipCountry || '-',
                },
            ];
        case 'TRUST':
            return [
                {
                    'psp_ref\u00B2': transaction?.transactionreference || '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': transaction?._id || '-',
                    'tax_ref_id\u00B2': '-',
                    'state\u00B9': transaction?.status || '-',
                    'authCode\u00B2': transaction?.authcode || '-',
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} ${transaction?.currencyiso3a as string}`,
                    'psp_status\u00B2': getTransactionStatus(transaction),
                    'psp_account\u00B2':
                        transaction?.accounttypedescription || '-',
                    'psp_user_red_id\u00B2': transaction?.rut || '-',
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': userInfo?.blocked?.toString() || '-',
                    'updated_by\u00B9': transaction?.updatedByUsername || '-',
                    'ip_city\u00B9': userInfo?.ipCity || '-',
                    'ip_region\u00B9': userInfo?.ipRegion || '-',
                    'card_issuer_country\u00B2': '-',
                    'ip_country\u00B9': userInfo?.ipCountry || '-',
                },
            ];
        case 'PAYMODUM':
            return [
                {
                    'psp_ref\u00B2': transaction?.transactionreference || '-',
                    'addition_info\u00B9': '-',
                    'origin_tx\u00B2': transaction?._id || '-',
                    'tax_ref_id\u00B2': '-',
                    'state\u00B9': transaction?.status || '-',
                    'authCode\u00B2': transaction?.authcode || '-',
                    'initial_psp_amount\u00B9': `${formatNumber(
                        Number(transaction?.baseamount)
                    )} ${
                        (transaction?.transactionBaseCurrencyConverted as string) ||
                        ''
                    }`,
                    'psp_status\u00B2': getTransactionStatus(transaction),
                    'psp_account\u00B2':
                        transaction?.accounttypedescription || '-',
                    'psp_user_red_id\u00B2': transaction?.rut || '-',
                    'psp_fraud_score\u00B2': '-',
                    'blocked\u00B9': transaction?.blocked?.toString() || '-',
                    'updated_by\u00B9': transaction?.updatedByUsername || '-',
                    'ip_city\u00B9': userInfo?.ipCity || '-',
                    'ip_region\u00B9': userInfo?.ipRegion || '-',
                    'card_issuer_country\u00B2': '-',
                    'ip_country\u00B9': userInfo?.ipCountry || '-',
                },
            ];
        default:
            return [];
    }
};

const getKycStatusFields = (key: string, transaction: any) => {
    switch (key) {
        case 'CLEO':
            return [
                {
                    'abuse\u00B2': '-',
                    'status\u00B9': transaction?.kycVerified
                        ? 'VERIFIED'
                        : 'NOT_VERIFIED',
                },
            ];
        case 'TRUST':
            return [
                {
                    'abuse\u00B2': '-',
                    'status\u00B9': transaction?.kycVerified
                        ? 'VERIFIED'
                        : 'NOT_VERIFIED',
                },
            ];
        case 'PAYMODUM':
            return [
                {
                    'abuse\u00B2': '-',
                    'status\u00B9': transaction?.kycVerified
                        ? 'VERIFIED'
                        : 'NOT_VERIFIED',
                },
            ];
        default:
            return [];
    }
};

const getOtherFields = (key: string) => {
    switch (key) {
        case 'CLEO':
            return [
                {
                    'rules\u00B9': '-',
                    'bonus_code\u00B9': '-',
                },
            ];
        case 'TRUST':
            return [
                {
                    'rules\u00B9': '-',
                    'bonus_code\u00B9': '-',
                },
            ];
        case 'PAYMODUM':
            return [
                {
                    'rules\u00B9': '-',
                    'bonus_code\u00B9': '-',
                },
            ];
        default:
            return [];
    }
};

const getFinancialFields = (key: string, transaction: any) => {
    const transactionCurrency = transaction?.currencyiso3a as string;
    const baseAmount = formatNumber(Number(transaction?.baseamount || 0)) || 0;
    const amountWithCurrency = baseAmount
        ? `${baseAmount} ${transactionCurrency}`
        : null;
    const baseFee: string =
        transaction?.transactionBaseAmountConverted !== undefined &&
        transaction.amountFee !== undefined
            ? (
                  (formatNumber(
                      transaction.transactionBaseAmountConverted
                  ) as unknown as number) -
                  (formatNumber(
                      Number(transaction.amountFee)
                  ) as unknown as number)
              ).toFixed(2)
            : '0.00';
    switch (key) {
        case 'CLEO':
            return [
                {
                    'fee\u00B2': `${formatNumber(
                        Number(transaction?.amountFee)
                    )} ${transaction?.currency as string}`,
                    'amount_base\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B9': transaction?.accountType || '-',
                    'account_number\u00B9': transaction?.accountNumber || '-',
                    'bank\u00B9': transaction?.bank || '-',
                    'pSP_fee\u00B2': transaction?.amountFee
                        ? `${formatNumber(Number(transaction.amountFee))} ${
                              transaction?.currency as string
                          }`
                        : '-',
                    'pSP_base_fee\u00B9': `${formatNumber(Number(baseFee))} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'auth_amount\u00B9': `${formatNumber(
                        Number(transaction?.amountFee)
                    )} ${transaction?.currency as string}`,
                    'auth_base_amount\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                },
            ];
        case 'TRUST':
            return [
                {
                    'fee\u00B9': '-',
                    'amount_base\u00B9': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                    'account_type\u00B2':
                        transaction.accounttypedescription || '-',
                    'account_number\u00B9': transaction?.accountNumber || '-',
                    'Card\u00B2': transaction?.maskedpan || '-',
                    'Card_Holder_Name\u00B2': transaction?.issuer || '-',
                    'bank\u00B2': transaction?.bank || '-',
                    'pSP_fee\u00B9': `-`,
                    'pSP_base_fee\u00B9': `-`,
                    'auth_amount\u00B2': amountWithCurrency || '-',
                    'auth_base_amount\u00B2': `${formatNumber(
                        transaction?.transactionBaseAmountConverted
                    )} ${
                        transaction?.transactionBaseCurrencyConverted as string
                    }`,
                },
            ];
        case 'PAYMODUM':
            return [
                {
                    'fee\u00B9': '-',
                    'amount_base\u00B9':
                        `${formatNumber(Number(transaction?.baseamount))} ${
                            transaction?.transactionBaseCurrencyConverted as string
                        }` || '-',
                    'account_type\u00B2':
                        transaction?.accounttypedescription || '-',
                    'account_number\u00B9': transaction?.accountNumber || '-',
                    'Card\u00B2': transaction?.maskedpan || '-',
                    'Card_Holder_Name\u00B2': transaction?.issuer || '-',
                    'bank\u00B2': transaction?.bank || '-',
                    'pSP_fee\u00B9': `-`,
                    'pSP_base_fee\u00B9': `-`,
                    'auth_amount\u00B2': '-',
                    'auth_base_amount\u00B2':
                        `${formatNumber(Number(transaction?.baseamount))} ${
                            transaction?.transactionBaseCurrencyConverted as string
                        }` || '-',
                },
            ];
        default:
            return [];
    }
};

const getTransactionTabData = (key: string, transaction: any) => {
    switch (key) {
        case ModalConstants.PAYMENT:
            return getPaymentFields(transaction?.pspType, transaction);
        case ModalConstants.USER_INFORMATION:
            return getUserInformationFields(transaction?.pspType, transaction);
        case ModalConstants.TRANSACTION_INFORMATION:
            return getTransactionInformationFields(
                transaction?.pspType,
                transaction
            );
        case ModalConstants.KYC_STATUS:
            return getKycStatusFields(transaction?.pspType, transaction);
        case ModalConstants.OTHER:
            return getOtherFields(transaction?.pspType);
        case ModalConstants.FINANCIAL:
            return getFinancialFields(transaction?.pspType, transaction);
        default:
            return [];
    }
};

export { getTransactionTabData, getPaymentFields };
