import { ChangeEvent, useMemo, useState } from 'react';
import { Pagination, Table } from 'components/Common';
import AuditHistoryModal from 'components/Modal/AuditHistoryModal';
import { AuditTableColumn } from './AuditTableColumnUtill';
import { AuditTableProps } from '../Audit.type';

export const AuditTable = ({
    isOpen,
    setIsOpen,
    auditDetails,
}: AuditTableProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState<number>(10);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageLimit;
        const lastPageIndex = firstPageIndex + pageLimit;
        return auditDetails?.slice(firstPageIndex, lastPageIndex);
    }, [auditDetails, currentPage, pageLimit]);

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        setPageLimit(parseInt(e.target.value));
    };

    return (
        <div>
            <div className="max-h-[calc(100vh-261px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl">
                <div className="flex w-full max-w-full items-center">
                    <Table
                        data={currentTableData}
                        columns={AuditTableColumn()}
                        className="w-full"
                        colSpan={11}
                    />
                </div>
            </div>
            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={auditDetails?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                    siblingCount={1}
                    text="Show Audit"
                    handleOnSelect={handleOnSelect}
                />
            </div>
            {isOpen && <AuditHistoryModal setIsOpen={setIsOpen} />}
        </div>
    );
};
