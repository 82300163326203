import { ChangeEvent, useMemo, useState } from 'react';
import { Pagination, Table } from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { CompaniesTableColumn } from './CompaniesTableUtill';
import { CompaniesTableProps } from '../Companies.type';

export const CompaniesTable = ({
    setCompany,
    setIsEditCompany,
    setIsOpen,
    allCompanies,
    setAllCompanies,
    checkedCompanies,
    setCheckedCompanies,
}: CompaniesTableProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState<number>(10);
    const { auth } = useAuthContext();
    const isAllChecked =
        allCompanies?.length > 0 &&
        allCompanies?.length === checkedCompanies?.length;

    const updatedCompanyStatus = (id: string, status: boolean) => {
        const filter = allCompanies.map((v) => {
            if (v._id === id) {
                return {
                    ...v,
                    isDeleted: status,
                };
            }
            return v;
        });
        setAllCompanies([...filter]);
    };

    const currentTableData = useMemo(
        () =>
            (allCompanies || [])?.slice(
                currentPage * pageLimit - pageLimit,
                currentPage * pageLimit
            ) || [],
        [allCompanies, currentPage, pageLimit]
    );

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (currentTableData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
    };

    return (
        <div>
            <div className="max-h-[calc(100vh-261px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl	">
                <div className="flex w-full max-w-full items-center">
                    <Table
                        data={currentTableData}
                        columns={CompaniesTableColumn(
                            isAllChecked,
                            setCheckedCompanies,
                            checkedCompanies,
                            updatedCompanyStatus,
                            allCompanies,
                            setCompany,
                            setIsEditCompany,
                            setIsOpen
                        )}
                        className="w-full"
                        colSpan={7}
                    />
                </div>
            </div>

            {auth.isSuperAdmin && (
                <div className="mt-2">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={allCompanies?.length}
                        pageSize={pageLimit}
                        onPageChange={(page: number) => setCurrentPage(page)}
                        siblingCount={1}
                        handleOnSelect={handleOnSelect}
                        text="Show Companies"
                    />
                </div>
            )}
        </div>
    );
};
