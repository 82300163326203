import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer } from 'components/Common';
import { IAdminUserData } from 'data/common';
import { useAuthContext } from 'context/Auth/AuthContext';
import { updateBrand } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IBrandDrawerProps, IBrands } from '../Brads.type';
import { formSchemaValidation } from '../Brands.schema';
import './index.css';
import { BrandForm } from './BrandForm/BrandForm';

export const EditBrandsDrawer = ({
    openDrawer,
    isOpen,
    loading,
    editSubBrandData,
    adminUsers,
    isEditBrandsDrawer,
    companyOptions,
    setBrandLoading,
    setIsOpen,
    getAllBrandsData,
    getUsers,
    brandData,
    brand,
}: IBrandDrawerProps) => {
    const { auth } = useAuthContext();
    const isSuperAdmin = auth.isSuperAdmin;
    const [isAdminUser, setIsAdminUser] = useState<boolean>(false);

    const { control, handleSubmit, watch, reset } = useForm<IBrands>({
        resolver: zodResolver(formSchemaValidation(isSuperAdmin)),
        defaultValues: {},
    });

    const filteredAdminUsers: IAdminUserData[] = useMemo(
        () =>
            adminUsers.filter(
                (value: any) => value.name === watch('adminUser')
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [adminUsers, isAdminUser]
    );

    useEffect(() => {
        if (!brand) return;
        reset(brandData);
    }, [reset, brand]);

    const companyOptionList = useMemo(() => {
        if (companyOptions) {
            return [{ _id: '', name: 'Select Company' }, ...companyOptions];
        }
        return companyOptions;
    }, [companyOptions]);

    const onSubmit = handleSubmit((formData: IBrands) => {
        if (!formData) return;
        const firstAdminUser: IAdminUserData = filteredAdminUsers[0];
        const payload = {
            name: formData?.name,
            adminUser: firstAdminUser?.adminUserId, // TODO change to adminUser String value backend change after change
            companyId: isSuperAdmin
                ? formData?.superAdminCompany
                : auth?.authUser?.company,
            logo: 'string',
            merchantId: '',
            pspType: formData?.pspType,
            locals: formData?.locals,
            currencies: formData?.currencies,
            countries: formData?.countries,
            description: formData?.description,
        };
        if (!brand) return;
        updateBrand(brand._id, payload)
            .then((res) => {
                getAllBrandsData();
                setBrandLoading(false);
                reset({});
                setIsOpen(false);
                if (res?.errors?.length) {
                    showToast(res.errors[0].msg);
                }
                showToast(res.message);
            })
            .catch((err: any) => {
                setBrandLoading(false);
                showToast(
                    err?.errors?.[0]?.msg || 'something went wrong',
                    'error'
                );
            });
    });

    useEffect(() => {
        if (!isOpen) {
            // Reset the form data when the drawer is closed
            reset();
        }
    }, [isOpen, reset]);

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={() => openDrawer()}
            drawerDirection="right"
            className={classNames(
                'bg-pink-100  max-h-[100vh] relative',
                loading ? '' : 'overflow-y-scroll'
            )}
        >
            <BrandForm
                onSubmit={onSubmit}
                control={control}
                openDrawer={() => openDrawer()}
                companyOptionList={companyOptionList}
                isSuperAdmin={isSuperAdmin}
                brand={brand}
                loading={loading}
                editSubBrandData={editSubBrandData}
                getUsers={getUsers}
                adminUsers={adminUsers}
                setIsAdminUser={setIsAdminUser}
                isEditBrandsDrawer={isEditBrandsDrawer}
            />
        </Drawer>
    );
};
