import { Dispatch, SetStateAction } from 'react';
import {
    Modal,
    PrimaryButton,
    PrimaryTextArea,
    Select,
} from 'components/Common';
import { useAuthContext } from 'context/Auth/AuthContext';
import {
    ActionOptions,
    ReasonOptions,
    ActionOptionsConstant,
    ReasonOptionsConstent,
} from 'data/constants/approve.constants';

interface ActionModalProps {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setActionModalData?: Dispatch<SetStateAction<any>>;
    handleAction?: () => void;
    className?: string;
    actionModalData?: any;
}

const ActionModal = ({
    setIsOpen,
    className,
    handleAction,
    actionModalData,
    setActionModalData,
}: ActionModalProps) => {
    const { auth } = useAuthContext();

    return (
        <Modal title="Action" classname={className} setIsOpen={setIsOpen} icon>
            <div className="flex flex-col gap-6	p-2 mt-6 pt-0 w-[528px] ">
                <Select
                    value={actionModalData?.status || ''}
                    bordered
                    className="!bg-white border border-solid border-[#C8C8C8]"
                    options={ActionOptions}
                    label="Status"
                    handleOnSelect={(e) =>
                        setActionModalData &&
                        setActionModalData({
                            ...actionModalData,
                            status: e.target.value,
                        })
                    }
                />

                {actionModalData?.status !== ActionOptionsConstant.APPROVE && (
                    <>
                        <Select
                            value={actionModalData?.reason || ''}
                            bordered
                            className="!bg-white border border-solid border-[#C8C8C8]"
                            options={ReasonOptions}
                            label="Reason"
                            handleOnSelect={(e) =>
                                setActionModalData &&
                                setActionModalData({
                                    ...actionModalData,
                                    reason: e.target.value,
                                })
                            }
                        />
                        <PrimaryTextArea
                            value={actionModalData?.comment || ''}
                            className="max-w-[513px]"
                            onChange={(e) =>
                                setActionModalData &&
                                setActionModalData({
                                    ...actionModalData,
                                    comment: e.target.value,
                                })
                            }
                            cols={5}
                            rows={4}
                            placeholder="Comment.."
                        />
                    </>
                )}
                <div className="flex items-start text-sm font-medium">
                    <div>Updated by: </div>{' '}
                    <div className="text-[#8FB131]">{auth.authUser?.name}</div>
                </div>
                <div className="flex flex-col gap-3">
                    <PrimaryButton
                        type="submit"
                        color="#2E672F"
                        variant="filled"
                        name="Save and Update"
                        onClick={() => handleAction && handleAction()}
                    />
                    <PrimaryButton
                        type="submit"
                        color="#8FB131"
                        variant="filled"
                        name="Clear"
                        onClick={() => {
                            if (setActionModalData) {
                                setActionModalData({
                                    status: ActionOptionsConstant.APPROVE,
                                    reason: ReasonOptionsConstent.TECHNICAL_ISUUE,
                                    comment: '',
                                });
                            }
                            setIsOpen(false);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default ActionModal;
