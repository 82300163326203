import { Dispatch, SetStateAction } from 'react';
import cc from 'currency-codes';
import countryList from 'react-select-country-list';

export interface IRuleEngineDrawerProps {
    openDrawer: () => void;
    isOpen: boolean;
    groupRuleData: any;
    getGroupRules: any;
}

export interface IGroupRuleEngineProps {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setActiveGroup: Dispatch<SetStateAction<string>>;
    activeGroup: string;
    groupRuleData: any;
    setGroupRuleData: Dispatch<SetStateAction<any>>;
    loading: boolean;
}

export interface IGroupRuleDataType {
    isActive: boolean;
    _id: string;
}
export interface IDefaultData {
    [key: string]: Array<string>;
}

export interface IPreConditionData {
    key: string;
    conditionType?: string;
    values: Array<string> | string | number | undefined;
}

export interface IConditionDataProps {
    title: string;
    conditionType: string | undefined;
    items: Array<string> | string | number | undefined;
}

export interface IPreconditionPros {
    showPreconditions: boolean;
    getPreConditionDataList: IPreConditionData[];
    setGetPreConditionDataList: Dispatch<SetStateAction<IPreConditionData[]>>;
}
export const defaultData: IDefaultData = {
    Country: ['Spain', 'Portugal'],
    Currency: cc.data.map((data) => data?.code),
    'User category': ['Beginner', 'Mid', 'High'],
    'Bank Country': countryList()
        .getData()
        .map((data) => data.label),
    'Tx Currency': cc.data.map((data) => data?.code),
    PSP: ['TRUST', 'CLEO', 'PAYMODUM', 'TRIPLEZERO'],
};

export interface IPreconditionDropDown {
    handleDelete: (e: any) => void;
    data: IConditionDataProps[];
    setData: any;
    preConditionDataList?: IPreConditionData[];
    getPreConditionDataList: IPreConditionData[];
    setGetPreConditionDataList: Dispatch<SetStateAction<IPreConditionData[]>>;
}

export interface CustomNodeProps {
    data: {
        id: string;
        label: string;
        type: string;
        items: string | string[] | number;
        operation: string;
        onClick?: (event: any) => void;
    };
    isConnectable?: boolean;
}

export interface IGroupDrawer {
    name: string;
}

export interface ICardPaymentRuleEngine {
    groupId: string;
    handleDeleteGroupRule: () => void;
    isActiveGroup?: boolean;
    groupRuleData?: any;
}

export interface ISelectedValueType {
    _id?: string | undefined;
    name: string;
    isSelected?: boolean | undefined;
}

// Regex free text 🛈
export enum ConditionType {
    SELECT = 'SELECT',
    MULTI_SELECTION = 'MULTI_SELECT',
    TEXT_INPUT_REGEX = 'TEXT_REGEX',
    DATEPICKER = 'DATE',
    TEXT_INPUT = 'INPUT',
    NUMBER_INPUT = 'NUMBER',
    PERCENTAGE_INPUT = 'PERCENTAGE',
    INPUT_JOIN = 'MONEY_LIST',
    NONE = 'NONE',
}

// Operator for the rule build
export const operators = ['=', '!=', '<', '<=', '>', '>='];
