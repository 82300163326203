import { IMenuItem } from '../common';

export const SIDE_MENU_LIST: IMenuItem[] = [
    // {
    //     id: 1,
    //     icon: 'DASHBOARD_ICON',
    //     // path: '/dashboard',
    //     key: 'DASHBOARD',
    //     title: 'Dashboard',
    // },
    {
        id: 2,
        icon: 'PAYMENT_ICON',
        path: '/payment',
        key: 'PAYMENTS',
        title: 'Payments',
        subMenu: [
            {
                id: 1,
                title: 'Transactions',
                key: 'TRANSACTIONS',
                path: '/payment/transactions',
            },
            {
                id: 2,
                title: 'Approvals',
                key: 'APPROVALS',
                path: '/payment/approvals',
            },
        ],
    },
    // {
    //     id: 3,
    //     icon: 'CUSTOMERS_ICON',
    //     // path: '/customers',
    //     key: 'CUSTOMERS',
    //     title: 'Customers',
    //     subMenu: [],
    // },
    // {
    //     id: 4,
    //     icon: 'REPORTS_ICON',
    //     // path: '/reports',
    //     key: 'REPORTS',
    //     title: 'Reports',
    // },
    {
        id: 5,
        icon: 'CASHIER_ICON',
        path: '/cashier',
        key: 'CASHIER',
        title: 'Cashier',
        subMenu: [
            {
                id: 1,
                title: 'Merchant PayIn',
                key: 'MERCHANT_PAYIN',
                path: '/cashier/merchant-payin',
            },
            {
                id: 2,
                title: 'Merchant Payout',
                key: 'MERCHANT_PAYOUT',
                path: '/cashier/merchant-payout',
            },
        ],
    },
    {
        id: 4,
        icon: 'AUDIT_ICON',
        key: 'AUDIT',
        path: '/audit',
        title: 'Audit',
        subMenu: [],
    },
    {
        id: 6,
        icon: 'ADMIN_ICON',
        path: '/admin',
        key: 'ADMIN',
        title: 'Admin',
        subMenu: [
            {
                id: 1,
                title: 'Users',
                key: 'USERS',
                path: '/admin/existing-users',
            },
            {
                id: 2,
                title: 'Companies',
                key: 'COMPANIES',
                path: '/admin/companies',
            },
            {
                id: 3,
                title: 'Groups',
                key: 'GROUPS',
                path: '/admin/groups',
            },
            {
                id: 4,
                title: 'Sub Brands',
                key: 'SUB_BRANDS',
                path: '/admin/brands',
            },
            {
                id: 5,
                title: 'Api Keys',
                key: 'API_KEYS',
                path: '/admin/api-keys',
            },
            {
                id: 6,
                title: 'Configuration',
                key: 'CONFIGURATION',
                path: '/admin/configuration',
            },
            {
                id: 7,
                title: 'Rule Engine',
                key: 'RULE_ENGINE',
                path: '/admin/rule-engine',
            },
        ],
    },
    // {
    //     id: 7,
    //     icon: 'SUPPORT_ICON',
    //     // path: '/support',
    //     key: 'SUPPORT',
    //     title: 'Support',
    //     subMenu: []
    // },
    // {
    //     id: 8,
    //     icon: 'DEVELOPERS_ICON',
    //     // path: '/developers',
    //     key: 'DEVELOPERS',
    //     title: 'Developers',
    //     subMenu: [],
    // },
    // {
    //     id: 9,
    //     icon: 'PROFILE_ICON',
    //     // path: '/profiles',
    //     key: 'PROFILES',
    //     title: 'Profiles',
    //     subMenu: []
    // },
];
