import { useEffect, useState } from 'react';
import LoadingSpinner from 'components/Common/Loader/Loader';
import {
    IBrandData,
    IBrandUserData,
    ICompanyData,
    IAdminUserData,
} from 'data/common';
import { getAllBrands, getCompanies, getUserByCompany } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useAuthContext } from 'context/Auth/AuthContext';
import { BrandsHeader } from './BrandsHeader';
import { BrandsTable } from './BrandTable';
import { EditBrandsDrawer } from './BrandsDrawer/EditBrandDrawer';
import { AddBrandsDrawer } from './BrandsDrawer/AddBrandDrawer';
import { IBrands } from './Brads.type';

const Brands = () => {
    const { auth } = useAuthContext();
    const isSuperAdmin = auth.isSuperAdmin;
    const isAdmin = auth.isAdmin;
    const [brands, setBrands] = useState<IBrandData[]>([]);
    const [allCompanies, setAllCompanies] = useState<ICompanyData[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [brandLoading, setBrandLoading] = useState<boolean>(false);
    const [brand, setBrand] = useState<IBrandUserData | null>(null);
    const [checkedBrands, setCheckedBrands] = useState<string[]>([]);
    const [adminUsers, setAdminUsers] = useState<IAdminUserData[]>([
        { _id: '', name: 'Select', adminUserId: '' },
    ]);
    const [isEditBrandsDrawer, setIsEditBrandsDrawer] =
        useState<boolean>(false);

    const getAllBrandsData = () => {
        const payload: any = {};
        if (auth.selectedBrand) {
            payload.filter = { brandId: auth?.selectedBrand?._id };
        } else if (auth.selectedCompany) {
            payload.filter = { companyId: auth?.selectedCompany?._id };
        }
        setIsLoading(true);
        getAllBrands(payload)
            .then((res) => {
                setBrands(res.brands || []);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    const getAllCompaniesList = () => {
        const companyId: string = auth.selectedCompany?._id || '';
        const query: string = companyId ? `id=${companyId}&key=Company` : '';
        getCompanies(query)
            .then((res) => {
                setAllCompanies(res?.companies || []);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        getAllCompaniesList();
        getAllBrandsData();
    }, [auth.role, isSuperAdmin, auth?.selectedBrand, auth?.selectedCompany]);

    const getUsers = (Id: string) => {
        getUserByCompany({
            companyId: Id,
        })
            .then((res) => {
                const adminUserData = res?.users?.map(
                    (value: IAdminUserData) => ({
                        ...value,
                        _id: value.name,
                        adminUserId: value._id,
                    })
                );
                adminUserData.unshift(
                    { _id: '', name: 'Select' },
                    {
                        adminUserId: auth?.authUser?._id,
                        name: auth?.authUser?.name,
                        _id: auth?.authUser?.name,
                    }
                );
                setAdminUsers(adminUserData);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    const closeDrawer = () => {
        setAdminUsers([{ _id: '', name: 'Select', adminUserId: '' }]);
    };

    const openDrawer = () => {
        if (isAdmin) {
            getUsers(auth?.authUser?.company || '');
        }
        if (isOpen) {
            closeDrawer();
        }
        setIsEditBrandsDrawer(false);
        setBrand(null);
        setIsOpen(!isOpen);
    };

    const brandData: IBrands = {
        name: brand?.name,
        adminUser: brand?.adminUser, // TODO change to adminUser String value backend change after change
        superAdminCompany: isSuperAdmin
            ? brand?.companyId
            : auth?.authUser?.company,
        pspType: brand?.pspType,
        locals: brand?.locals,
        currencies: brand?.currencies,
        countries: brand?.countries,
        description: brand?.description,
    };

    return (
        <div className="px-8 py-5">
            <BrandsHeader
                getAllBrandsData={getAllBrandsData}
                openDrawer={openDrawer}
                setCheckedBrands={setCheckedBrands}
                checkedBrands={checkedBrands}
            />
            {loading ? (
                <LoadingSpinner />
            ) : (
                <BrandsTable
                    brands={brands}
                    setBrands={setBrands}
                    setBrand={setBrand}
                    checkedBrands={checkedBrands}
                    setCheckedBrands={setCheckedBrands}
                    setIsOpen={setIsOpen}
                    setIsEditBrandsDrawer={setIsEditBrandsDrawer}
                    getUsers={getUsers}
                />
            )}
            {isEditBrandsDrawer ? (
                <EditBrandsDrawer
                    loading={brandLoading}
                    companyOptions={allCompanies || []}
                    isCreateBrandModal
                    editSubBrandData={brand}
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    getUsers={getUsers}
                    adminUsers={adminUsers}
                    isEditBrandsDrawer={isEditBrandsDrawer}
                    getAllBrandsData={getAllBrandsData}
                    setIsOpen={setIsOpen}
                    setBrandLoading={setBrandLoading}
                    brand={brand}
                    brandData={brandData}
                    setBrand={setBrand}
                />
            ) : (
                <AddBrandsDrawer
                    loading={brandLoading}
                    companyOptions={allCompanies || []}
                    isCreateBrandModal
                    editSubBrandData={brand}
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    getUsers={getUsers}
                    adminUsers={adminUsers}
                    isEditBrandsDrawer={isEditBrandsDrawer}
                    getAllBrandsData={getAllBrandsData}
                    setIsOpen={setIsOpen}
                    setBrandLoading={setBrandLoading}
                    brand={brand}
                    brandData={brandData}
                    setBrand={setBrand}
                />
            )}
        </div>
    );
};
export default Brands;
