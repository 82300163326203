import { ConditionType } from './RuleEngine.type';

export const PreconditionList: { [key: string]: any }[] = [
    {
        Condition: '3DS Enrollment',
        Description:
            "If this user's credit card has been marked as enrolled or not from a previous enrolment check",
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Action',
        Description: 'Type of action',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Account',
        Description: 'Saved masked account of the merchant user',
        Type: ConditionType.NUMBER_INPUT,
    },
    {
        Condition: 'Account holder name match',
        Description:
            'Option to use name matching for providers supporting it. See Configure Name Matching for details.',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Amount',
        Description: 'Amount of the transaction before fees etc.',
        Type: ConditionType.NUMBER_INPUT,
    },
    {
        Condition: 'API Source',
        Description:
            'From which API the request has been made, Admin API or Front API',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Approval method',
        Description: 'Type of approval method',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'BIC',
        Description: 'Bank Identifier Code',
        Type: ConditionType.TEXT_INPUT_REGEX,
        Column7: '(Only BankIBANWithdrawal)',
    },
    {
        Condition: 'Bank Country',
        Description:
            'ISO 3166-1 alpha-3 code of the bank used in a BankIBANWithdrawal, based on the BIC value',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Bin',
        Description:
            'Bank identification number of a credit card, first 6 digits',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Bin card brand',
        Description:
            'Description: Brand of a credit card, based on the BIN information fetched from the issuer info service',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Bin card category',
        Description:
            'Description: Category of a credit card, based on the BIN information fetched from the issuer info service',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Bin issuer',
        Description:
            'Description: Issuer of a credit card, based on the BIN information fetched from the issuer info service',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Bin issuer country',
        Description:
            'Description: ISO 3166-1 alpha-3 country code of the issuer country, based on the BIN information fetched from the issuer info service',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Bin type',
        Description:
            'Description: Type of credit card, based on the BIN information fetched from the issuer info service',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Capture method',
        Description:
            'Description: With what type of method an auth transaction should be captured with',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Channel',
        Description:
            'Description: Which type of channel the merchant user has, based on values configured in the MerchantConfig',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Currency',
        Description:
            'Description: Currency of the transaction. This is based on the currency before a potential forex exchange has been made.',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Tx Currency',
        Description:
            'Description: Tx Currency of the transaction. This is based on the currency after a potential forex exchange has been made.',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Email',
        Description: 'Description: Email address of the merchant end user',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Excluded account',
        Description:
            'Description: Merchant end user accounts to exclude in the rule',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Exclude PSP status code',
        Description: 'None given',
        Type: ConditionType.NONE,
    },
    {
        Condition: 'Excluded user id',
        Description:
            'Description: Merchant end user identification(s) to exclude in the rule',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'FeatureSpace decision',
        Description: 'None given',
        Type: ConditionType.NONE,
    },
    {
        Condition: 'First deposit',
        Description:
            'Description: Whether or not the merchant end user has made a successful deposit before',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'First deposit attemp',
        Description:
            'Description: Whether or not the merchant end user has made a deposit before',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'First time method used',
        Description:
            'Description: Whether or not the merchant end user has made a successful deposit before with the current payment method(e.g CreditcardDeposit)',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'First time user account',
        Description:
            'Description: Whether or not the merchant end user has made a successful deposit before with this user account',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'First withdrawal',
        Description:
            'Description: Whether or not the merchant end user has made a successful withdrawal before',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'First withdrawal attempt',
        Description:
            'Description: Whether or not the merchant end user has made a withdrawal before',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Fraud score max',
        Description:
            'Description: Fraud score maximum. Fraud score is provided by a specific fraud provider e.g RiskGuardian',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Fraud score min',
        Description:
            'Description: Fraud score minimum. Fraud score is provided by a specific fraud provider e.g RiskGuardian',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'From date',
        Description: 'Description: From which date the rule should be applied',
        Type: ConditionType.DATEPICKER,
    },
    {
        Condition: 'Has any scheme token',
        Description: 'Description: Matches if user has any stored scheme token',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Has scheme token',
        Description:
            'Description: Matches if user has scheme token from the specific provider',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'IP address',
        Description: 'Description: IP address of the merchant end user',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'IP country',
        Description:
            'Description: ISO 3166-1 alpha-3 country code based on the IP address geolocation of the merchant end user',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'IP region',
        Description: 'Description: IP region of the merchant end user',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Info',
        Description:
            "Description: TEXT_REGEX based on the 'Info'column in transaction view",
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Initiated PSP',
        Description: 'Description: The initial PSP for the transaction',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Initiated PSP account',
        Description: 'Description: The initial PSP account for the transaction',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Internal KYC status',
        Description:
            'Description: KYC status in PIQ, based on a specific KYC provider',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'KYC Age status',
        Description:
            'Description: KYC age status in PIQ, based on a specific KYC provider',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'KYC ID status',
        Description:
            'Description: KYC ID status in PIQ, based on a specific KYC provider',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'KYC Provider',
        Description: 'Description: Specific KYC provider(s) used',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Last deposit method match',
        Description:
            'Description: If the current provider type (e.g CREDITCARD) is the same as the last successful deposit the merchant end user used',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Locale',
        Description: "Description: Merchant end user's language and region",
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Location match',
        Description:
            'Description: If there is a match between the values in this condition based on geolocation',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Location mismatch',
        Description:
            'Description: If there is a mismatch between the values in this condition based on geolocation',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Market',
        Description:
            'Description: Market of the merchant end user, based on extra attributes value provided by merchant',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Max amount',
        Description:
            'Description: Maximum amount as a money object (amount and currency separated with space) of the transaction before fees etc. Separate with a comma for several amounts',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Max balance',
        Description:
            'Description: Maximum balance amount as a money object (amount and currency separated with space) of the merchant end user. Separate with a comma for several amounts',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Merchant Id',
        Description:
            'Description: The identification number of the merchant, consists of 9 digits',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Merchant KYC status',
        Description:
            'Description: The Know Your Customer status provided by the merchant',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Method',
        Description: 'Description: Type of payment method',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Methods',
        Description: 'Description: Type of payment method(s)',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Min amount',
        Description:
            'Description: Minimum amount as a money object (amount and currency separated with space) of the transaction before fees etc. Separate with a comma for several amounts',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Min balance',
        Description:
            'Description: Minimum balance amount as a money object (amount and currency separated with space) of the merchant end user. Separate with a comma for several amounts',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Min unique users',
        Description:
            'Description: Minimum amount of unique users that the Alert job should match on',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Minutes back',
        Description:
            'Description: Minutes back in time for which the Alert job shall check for irregularities',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'PSP',
        Description: 'Description: Payment service provider',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'PSP account used before',
        Description:
            'Description: If the merchant end user has done any transactions with this PSP account successfully before',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'PSP Used before',
        Description:
            'Description: If the merchant end user has done any transactions with this PSP successfully before',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'PSP last used',
        Description:
            "Description: If the merchant end user has done a transactions with this PSP before and has matched PSP account, tx type, or payment method. Then, we compare last tx state with the condition's state",
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Most recent tx with PSP',
        Description:
            'Description: If the most recent tx that has been done by the merchant end user matches a specific PSP, Tx Type, Tx state, PSP account, and Payment method.',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'PSP account',
        Description:
            'Description: The account string that has been configured in the specific provider configuration',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Has blocked PSP account',
        Description:
            'Description: If the user has any PSP user accounts with the status BLOCKED',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'PSP service',
        Description:
            'Description: Eventual service string provided by merchant in the initial request',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'PSP status code',
        Description:
            'Description: Status code returned to PIQ from the provider',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Payment method',
        Description: 'Description: Type of payment method',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Payment type',
        Description: 'Description: Type of payment, new or recurring',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Player routing',
        Description:
            "Description: Matching value when using the extra attribute parameter 'playerRouting' to decide provider to route with",
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'SSN validation',
        Description:
            "Description: Compares verifyUser attribute 'nationalIdentificationNumber' with a transformed value on the ptx",
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Suspected Abuse',
        Description:
            'Description: If the merchant user is suspected for abuse provided by merchant in verifyuser',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Threshold %',
        Description:
            'Description: Threshold percentage used in Alert rules to decide at which point the alert should trigger based the amount of non successful transactions',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Time interval',
        Description:
            'Description: Cron expression in which time interval the Alert job should update',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'To date',
        Description: 'Description: To which date the rule should be applied',
        Type: ConditionType.DATEPICKER,
    },
    {
        Condition: 'Transaction count on PSP account in history',
        Description:
            'Description: Allows you to check if a user has made an X amount of successful transactions with a provider',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Tx state',
        Description: 'Description: Transaction state in PIQ',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Tx status code',
        Description: 'Description: Transaction status code in PIQ',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Tx type',
        Description: 'Description: Transaction type in PIQ',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'Tx type is set',
        Description:
            'Description: If the transaction type is set on the current transaction',
        Type: ConditionType.SELECT,
    },
    {
        Condition: 'Type',
        Description:
            'Description: Type of transaction defined by the merchant in the process request inside the attributes object and the type parameter. Also needs to be defined in the MerchantConfig in the types tag to be choosable in the rules',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'User category',
        Description:
            "Description: Type of end user category defined by the merchant in the 'userCategories' value",
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'User Category Regex',
        Description:
            "Description: Type of end user category defined by the merchant in the 'userCategories' value. Used for conditions with multiple user categories",
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'User country',
        Description:
            'Description: ISO 3166-1 alpha-3 code of the merchant user country',
        Type: ConditionType.MULTI_SELECTION,
    },
    {
        Condition: 'User id',
        Description: 'Description: Unique merchant end user identifications(s)',
        Type: ConditionType.TEXT_INPUT_REGEX,
    },
    {
        Condition: 'Min Total deposit amount',
        Description:
            'Description: If the accumulated amount of previous deposit transactions is greater than or equals a specific value. This condition takes into consideration the values of velocityDaysBack and velocityNoOfHistoricalTx in MerchantConfig',
        Type: ConditionType.INPUT_JOIN,
    },
    {
        Condition: 'Max Total deposit amount',
        Description:
            'Description: If the accumulated amount of previous deposit transactions is less than or equals a specific value. This condition takes into consideration the values of velocityDaysBack and velocityNoOfHistoricalTx in MerchantConfig',
        Type: ConditionType.INPUT_JOIN,
    },
    {
        Condition: 'Min Total withdrawal amount',
        Description:
            'Description: If the accumulated amount of previous withdrawal transactions is greater than or equals a specific value. This condition takes into consideration the values of velocityDaysBack and velocityNoOfHistoricalTx in MerchantConfig',
        Type: ConditionType.INPUT_JOIN,
    },
    {
        Condition: 'Max Total withdrawal amount',
        Description:
            'Description: If the accumulated amount of previous withdrawal transactions is greater than or equals a specific value. This condition takes into consideration the values of velocityDaysBack and velocityNoOfHistoricalTx in MerchantConfig Type: Money list 🛈',
        Type: ConditionType.INPUT_JOIN,
    },
    {
        Condition: 'SCA required',
        Description:
            "Description: If this user's credit card got a Soft Decline and needs to be routed to an account that does a SCA attempt",
        Type: ConditionType.SELECT,
    },
];
