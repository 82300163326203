import React, {
    DetailedHTMLProps,
    FC,
    InputHTMLAttributes,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import './TextArea.css';

interface Props
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
    > {
    placeHolder?: string;
    rows: number;
    cols: number;
    label?: ReactNode;
    isError?: boolean;
    errorMessage?: string | number;
    asterisk?: boolean;
}

const TextArea: FC<Props> = ({
    label,
    rows,
    cols,
    className = '',
    placeHolder,
    isError = false,
    asterisk = false,
    errorMessage,
    ...props
}: Props) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
    };

    useEffect(() => {
        // Add event listeners to track focus changes
        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);
        document.addEventListener('hover', handleFocusChange, true);

        // Clean up the event listeners when the component is unmounted
        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
            document.removeEventListener('hover', handleFocusChange, true);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className="flex flex-col">
            <label
                className={classNames(
                    'font-semibold text-sm mb-2 text-start',
                    isError
                        ? 'text-red-200'
                        : isHovered
                        ? 'text-black'
                        : props.value
                        ? 'text-black'
                        : !isFocused
                        ? 'text-gray-500'
                        : 'text-black'
                )}
                htmlFor={props?.id}
            >
                {label}
                {asterisk && (
                    <span className="!text-red-200 text-lg pl-[2px] pt-3">
                        *
                    </span>
                )}
            </label>
            <textarea
                style={{
                    display: 'flex',
                    fontWeight: 400,
                    color: '#393838',
                    padding: '10px 8px',
                    border: 'none !important',
                    borderColor: '#393838 !important',
                    borderRadius: 6,
                    resize: 'none',
                }}
                className={`font-medium text-gray-300 text-sm mb-1 ${className}`}
                cols={cols}
                placeholder={placeHolder}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                rows={rows}
                {...props}
            />
            {errorMessage && (
                <div className="flex mt-2 self-baseline text-xs font-medium text-red-200">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};
export default TextArea;
