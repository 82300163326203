import React from 'react';
import { Control } from 'react-hook-form';
import { IPasswordData } from 'data/types/auth';
import { PrimaryButton } from 'components/Common';
import RFTextField from '../../Common/RFTextField/RFTextField';

interface IResetPassword {
    isNewAdmin?: boolean;
    handleSubmit: () => void;
    control: Control<IPasswordData>;
}

export const ResetPassword = ({
    isNewAdmin,
    handleSubmit,
    control,
}: IResetPassword) => {
    return (
        <div className="flex flex-col justify-center items-start w-[500px] mx-auto">
            <div className="flex items-center text-[32px] text-green-100 font-bold w-full">
                {isNewAdmin ? 'Create your Password' : 'New Password'}
            </div>
            <div className="text-[16px] font-normal">
                {isNewAdmin
                    ? 'Enter your password to complete the onboarding process.'
                    : 'Enter your new password to begin again.'}{' '}
            </div>
            <form onSubmit={handleSubmit} className="w-full">
                <div className="flex flex-col w-[100%] pt-12">
                    <RFTextField
                        control={control}
                        name="password"
                        fullWidth
                        label="Password"
                        type="password"
                        asterisk
                    />
                    <div>
                        <RFTextField
                            control={control}
                            name="confirmPassword"
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            asterisk
                        />
                    </div>
                    <PrimaryButton
                        type="submit"
                        name="Submit"
                        color="#2E672F"
                        variant="filled"
                        className="hover:bg-[#2E672F] hover:border-green-600 focus:border-green-600 mt-10 w-full font-medium"
                    />
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
