import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, PrimaryButton } from 'components/Common';
import { IAuditHeader } from '../Audit.type';

export const AuditHeader = ({
    setExportType,
    setOpenExportModal,
}: IAuditHeader) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            // If the dropdown is open and the click is outside the dropdown or on the dropdown itself, close it
            if (
                open &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpen(false);
            }
        };
        // Add event listener when dropdown is open
        if (open) {
            document.addEventListener('mousedown', handleDocumentClick);
        }
        // Remove event listener when component is unmounted or dropdown is closed
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [open]);

    return (
        <div className="mb-5 flex w-full items-center justify-between max-[970px]:flex-col">
            <div className="font-extrabold text-center text-2xl text-[#131119]">
                Audit Logs
            </div>
            <div className="flex items-center gap-4">
                <PrimaryButton
                    onClick={() => {
                        setOpenExportModal(true);
                    }}
                    type="button"
                    name="Filter"
                    color="#8FB131"
                    variant="filled"
                    isDrawerButton
                    className="w-[88px] font-medium p-4 items-center"
                />
                <div className="relative">
                    <PrimaryButton
                        type="button"
                        name="Export"
                        color="#8FB131"
                        variant="filled"
                        isDrawerButton
                        className="w-[88px] font-medium p-4 items-center"
                        onClick={() => setOpen((prev) => !prev)}
                    />
                    {open && (
                        <div ref={dropdownRef}>
                            <Dropdown
                                className="right-1 z-[100]"
                                submenus={[
                                    { title: 'Microsoft (.xlsx)', id: 1 },
                                    { title: 'CSV (.csv)', id: 2 },
                                    { title: 'PDF (.pdf)', id: 3 },
                                    { title: 'Open Document (.ods)', id: 4 },
                                    {
                                        title: 'Tab Seperated Values (.tsv)',
                                        id: 5,
                                    },
                                    { title: 'Web Page (HTML)', id: 6 },
                                ]}
                                handleClick={(e) => {
                                    setExportType(e);
                                    setOpen(false);
                                }}
                                dropdown={open}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
