import './Dropdown.css';

interface DropdownProps {
    className?: string;
    submenus: any[];
    dropdown: boolean;
    handleClick: (value: string) => void;
}

const Dropdown = ({
    submenus,
    dropdown,
    handleClick,
    className,
}: DropdownProps) => (
    <div
        className={` ${
            dropdown
                ? `flex flex-col absolute rounded border Dropdown ${
                      className as string
                  }`
                : 'none'
        }`}
    >
        {submenus.map((submenu: any, index: number) => (
            <div
                key={index}
                className="menu-items"
                onClick={() => handleClick(submenu.title)}
            >
                <div className="sub-menu cursor-pointer">{submenu.title}</div>
            </div>
        ))}
    </div>
);

export default Dropdown;
