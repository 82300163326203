import React, { useEffect, useState } from 'react';
import {
    Drawer,
    PrimaryButton,
    PrimaryInput,
    Select,
    SvgIcon,
} from 'components/Common';
import { useReactFlow } from 'reactflow';
import { conditionList } from '../RuleBuilderUtills';
import { IRuleBuilderDrawer } from '../RuleBuilder.type';
import './ruleBuilderDrawer.css';
import { PreconditionList } from '../../condition';
import { getInputField } from '../../CardPaymentRuleEngine/Precondition/PreconditionDropdown/PreconditionUtill';
import { ISelectedValueType, operators } from '../../RuleEngine.type';

const RuleBuilderDrawer = ({
    isOpen,
    openDrawer,
    onAdd,
    isCondition,
    captureElementNode,
    setIsOpenDrawer,
    setCaptureElementNode,
    setIsConditionOpen,
}: IRuleBuilderDrawer) => {
    const { getNodes } = useReactFlow();
    const nodes = getNodes();
    const [query, setQuery] = useState('');
    const [dataIndex, setDataIndex] = useState<any>(null);
    const [inptVal, setInptVal] = useState<any>(null);
    const [selectedOperator, setSelectedOperator] = useState('=');

    const handleQueryChange = (event: any) => {
        setQuery(event.target.value);
    };
    const keyName = PreconditionList.map((item) => {
        return { condition: item?.Condition || item?.name, type: item?.Type };
    });

    useEffect(() => {
        conditionList.map((item) => {
            return (item.conditionDataList = keyName);
        });
    }, []);

    const filteredProvidersList = conditionList.map((list) => {
        let maxLength = Infinity; // Default value
        if (list.key === 'Recently Used') {
            maxLength = 10;
        } else if (list.key === 'Most Popular') {
            maxLength = 14;
        }

        const filterValue = list.conditionDataList
            .slice(0, maxLength) // Limit the length of conditionDataList
            .filter((v) =>
                v.condition?.toLowerCase().includes(query?.toLowerCase())
            );
        return { ...list, conditionDataList: filterValue };
    });

    const handleMultiSelect = (selectedLists: any, index: number) => {
        const list = (selectedLists || []).map(
            (value: ISelectedValueType) => value.name
        );
        setDataIndex(index);
        setInptVal(list);
    };

    useEffect(() => {
        if (isCondition) {
            if (!captureElementNode) return;
            const val = captureElementNode?.data?.items;
            setInptVal(val);
            setSelectedOperator(captureElementNode?.data?.operation);
        }
    }, [setIsOpenDrawer]);

    const handleChange = (
        index: number,
        value: string | number | string[] | undefined
    ) => {
        const valueString = value !== undefined ? value?.toString() : ''; // Convert value to string if not undefined
        setDataIndex(index);
        setInptVal(valueString);
    };

    const handleSave = () => {
        if (inptVal === null) return;
        const newArray = [...nodes];
        const node = newArray[dataIndex || captureElementNode?.id];
        if (node?.data) {
            if (Array.isArray(inptVal)) {
                node.data.items = inptVal;
            } else {
                node.data.items = inptVal;
            }
            node.data.operation = selectedOperator;
        }
        setIsOpenDrawer(false);
        setInptVal(null);
        setDataIndex(null);
        setSelectedOperator('');
        setCaptureElementNode(null);
        setIsConditionOpen(false);
    };

    return (
        <Drawer
            drawerSize={400}
            isOpen={isOpen}
            toggleDrawer={openDrawer}
            drawerDirection="right"
            className="overflow-y-scroll rounded-r-md bg-pink-100 ruleContainer"
        >
            <div className="flex flex-col gap-3 p-6">
                <div className="flex items-center justify-between">
                    <div className="my-0 text-xl font-black text-green-100">
                        Build Rule
                    </div>
                    <SvgIcon
                        className="cursor-pointer"
                        icon="CLOSE_BUTTON"
                        onClick={openDrawer}
                    />
                </div>

                <PrimaryInput
                    type="search"
                    iconPosition="left"
                    icon="SEARCH_ICON"
                    className="min-[1480px]:!w-[400px] min-w-1/2 !pl-9"
                    placeholder="Search for conditions..."
                    value={query}
                    onChange={handleQueryChange}
                />
            </div>

            <div className="p-6">
                {isCondition ? (
                    <>
                        {!['Action'].includes(
                            captureElementNode?.data?.label
                        ) && (
                            <Select
                                label="Operator"
                                isCompanyAdmin
                                isGroup
                                fullWidth
                                bordered
                                options={operators.map((operator) => ({
                                    name: operator,
                                }))}
                                value={selectedOperator}
                                handleOnSelect={(e) => {
                                    setSelectedOperator(e.target.value);
                                }}
                                className="w-full text-black mb-5"
                            />
                        )}

                        <div className="pb-4 text-base leading-5	font-extrabold">
                            {`Condition > ${
                                captureElementNode?.data?.label as string
                            }`}
                        </div>
                        {getInputField(
                            {
                                title: captureElementNode?.data?.label,
                                conditionType: captureElementNode?.data?.type,
                                items: inptVal,
                            },
                            captureElementNode?.id,
                            handleMultiSelect,
                            handleChange
                        )}
                        <PrimaryButton
                            className="!h-[40px] !px-[10px] w-full my-2"
                            color="#8FB131"
                            variant="filled"
                            name="Save"
                            onClick={handleSave}
                        />
                    </>
                ) : (
                    filteredProvidersList.map((v, id) => {
                        return (
                            <div className="flex flex-col gap-6" key={id}>
                                <div className="text-base font-extrabold leading-5">
                                    {v.key}
                                </div>
                                <div className="grid grid-cols-3 gap-2 pb-5">
                                    {v.conditionDataList.map((li, index) => {
                                        return (
                                            <div
                                                className="truncate rounded border-2 border-solid border-black px-4 py-2 text-xs font-bold hover:h-auto hover:border-green-200 focus:border-green-200"
                                                key={index}
                                                title={li.condition}
                                                onClick={() =>
                                                    onAdd(
                                                        li.condition,
                                                        li?.type
                                                    )
                                                }
                                            >
                                                {li.condition}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </Drawer>
    );
};

export default RuleBuilderDrawer;
