import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import VisibleLoader from 'components/Common/Loader/VisibleLoader';
import {
    PrimaryButton,
    Table,
    SvgIcon,
    Loader,
    RFSelectField,
    RFTextField,
} from 'components/Common';
import { getAllBrands, getAllPageAreas, getCompanies } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { IGetAllBrandData, IGetAllPageAreasData } from 'data/types/response';
import { ICompanyData } from 'data/common';
import { useAuthContext } from 'context/Auth/AuthContext';
import {
    GroupDrawerAccessTableColumns,
    GroupDrawerPageAreaTableColumns,
} from '../GroupsDrawerUtill';
import { IGroupFormProps } from '../../Groups.type';

export const GroupForm = ({
    onSubmit,
    loading,
    control,
    isEditGroupDrawer,
    openDrawer,
    isSuperAdmin,
    isOpen,
    watch,
}: IGroupFormProps) => {
    const { auth } = useAuthContext();
    const [allBrandData, setAllBrandData] = useState<IGetAllBrandData[] | null>(
        null
    );
    const [allPageAreaData, setAllPageAreaData] = useState<
        IGetAllPageAreasData[] | null
    >(null);
    const [pageAreaLoading, setPageAreaLoading] = useState<boolean>(false);
    const [brandLoading, setBrandLoading] = useState<boolean>(false);

    const [allCompanies, setAllCompanies] = useState<ICompanyData[]>([]);

    const getAllCompanies = () => {
        const companyId: string = auth.selectedCompany?._id || '';
        const query: string = companyId ? `id=${companyId}&key=Company` : '';
        getCompanies(query)
            .then((res) => {
                setAllCompanies(res?.companies || []);
            })
            .catch((err) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    useEffect(() => {
        if (isSuperAdmin) {
            getAllCompanies();
        }
    }, [auth.role, isSuperAdmin, auth?.selectedBrand, auth?.selectedCompany]);

    const companyOptionList = useMemo(() => {
        if (allCompanies) {
            return [{ _id: '', name: 'Select Company' }, ...allCompanies];
        }
        return allCompanies;
    }, [allCompanies]);

    useEffect(() => {
        setPageAreaLoading(true);
        setBrandLoading(true);
        if (isOpen) {
            getAllBrands(
                !watch('companyId')
                    ? {}
                    : {
                          filter: {
                              companyId: watch('companyId'),
                          },
                      }
            )
                .then((result) => {
                    setAllBrandData(result?.brands);
                    setBrandLoading(false);
                })
                .catch((err) => {
                    setBrandLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });

            getAllPageAreas()
                .then((result) => {
                    setAllPageAreaData(result?.pageAreas);
                    setPageAreaLoading(false);
                })
                .catch((err) => {
                    setPageAreaLoading(false);
                    showToast(
                        err?.errors?.[0]?.message || 'something went wrong',
                        'error'
                    );
                });
        }
    }, [isOpen, watch('companyId')]);

    return (
        <form onSubmit={onSubmit}>
            <div className="p-5">
                {loading && <VisibleLoader />}
                <div className="flex items-center justify-between">
                    <div className="my-0 text-xl font-black text-green-100">
                        {isEditGroupDrawer ? 'Update Group' : 'Add New Group'}
                    </div>
                    <SvgIcon
                        className="cursor-pointer"
                        icon="CLOSE_BUTTON"
                        onClick={openDrawer}
                    />
                </div>
                <div className="mt-5">
                    <RFTextField
                        control={control}
                        name="groupName"
                        fullWidth
                        label="Name of Group"
                        type="text"
                        asterisk
                        placeholder="Customer Service.."
                    />

                    {isSuperAdmin && (
                        <div className="mt-5">
                            <RFSelectField
                                name="companyId"
                                control={control}
                                fullWidth
                                label="Select Your Company"
                                asterisk
                                options={companyOptionList || []}
                            />
                        </div>
                    )}
                </div>
                <div className="mt-3">
                    <div>
                        {brandLoading ? (
                            <Loader />
                        ) : (
                            <div>
                                <Controller
                                    name="brandsPermissions"
                                    control={control}
                                    render={({
                                        field: { onChange, value, ...field },
                                        fieldState,
                                    }) => (
                                        <>
                                            <Table
                                                colSpan={3}
                                                isSidebarTable
                                                data={allBrandData || []}
                                                columns={GroupDrawerAccessTableColumns(
                                                    false,
                                                    onChange,
                                                    value
                                                )}
                                                className="w-full"
                                                {...field}
                                            />
                                            <div className="mb-1 text-sm font-medium text-red-200">
                                                {fieldState.error?.message
                                                    ? fieldState.error?.message
                                                    : ''}
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        {pageAreaLoading ? (
                            <Loader />
                        ) : (
                            <div>
                                <Controller
                                    name="pageAreasPermissions"
                                    control={control}
                                    render={({
                                        field: { onChange, value, ...field },
                                        fieldState,
                                    }) => (
                                        <>
                                            <Table
                                                isSidebarTable
                                                data={allPageAreaData || []}
                                                columns={GroupDrawerPageAreaTableColumns(
                                                    false,
                                                    onChange,
                                                    value
                                                )}
                                                className="w-full"
                                                colSpan={3}
                                                {...field}
                                            />
                                            <div className="mb-1 text-sm font-medium text-red-200">
                                                {fieldState.error?.message
                                                    ? fieldState.error?.message
                                                    : ''}
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex mt-2 p-5 sticky bottom-0 bg-white z-10">
                <PrimaryButton
                    disabled={loading}
                    isDrawerButton
                    type="submit"
                    className={classNames('w-full')}
                    color="#2E672F"
                    variant="filled"
                    name={
                        isEditGroupDrawer ? 'Update Group' : 'Create New Group'
                    }
                />
            </div>
        </form>
    );
};
