import { ChangeEvent, useMemo, useState } from 'react';
import LoadingSpinner from '../../../Common/Loader/Loader';
import { Pagination, Table } from '../../../Common';
import TransactionDetailModal from '../../../Modal/TransactionDetailsModal';
import { ITransactions } from '../../../../data/types/response';
import { ITransactionTable } from '../Transaction.type';
import { transactionTableColumn } from './TransactionTableUtill';

export const TransactionTable = ({
    loading,
    currentPage,
    transactionData,
    setCurrentPage,
    tableFilterColumn,
}: ITransactionTable) => {
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] =
        useState<ITransactions | null>(null);

    const currentTableData = useMemo(
        () =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            (transactionData || [])?.slice(
                currentPage * pageLimit - pageLimit,
                currentPage * pageLimit
            ) || [],
        [transactionData, currentPage, pageLimit]
    );

    const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        if (currentTableData?.length < parseInt(e.target.value)) {
            setCurrentPage(1);
        }
        setPageLimit(parseInt(e.target.value));
    };

    const filteredArray = transactionTableColumn().filter((item) => {
        const correspondingItem = tableFilterColumn.find(
            (secondItem) => secondItem.key === item.key
        );
        return correspondingItem && correspondingItem.isChecked;
    });

    return (
        <div>
            {isOpen && (
                <TransactionDetailModal
                    transaction={transactionDetails}
                    isTransactionModal
                    setIsOpen={setIsOpen}
                />
            )}

            <div className="max-h-[calc(100vh-314px)] !overflow-y-scroll !overflow-x-scroll dataTable rounded-xl mt-5">
                <div className="flex h-full w-full items-center">
                    {loading ? (
                        <div className="flex h-full w-full items-center justify-center">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <Table
                            isTransactionDetailsTable
                            handleRowClick={(details) => {
                                // setUserInfo(details);
                                setTransactionDetails(details);
                                setIsOpen(true);
                            }}
                            data={currentTableData}
                            columns={filteredArray}
                            className="w-full"
                            colSpan={14}
                        />
                    )}
                </div>
            </div>
            <div className="mt-2">
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={transactionData?.length || 0}
                    pageSize={pageLimit}
                    onPageChange={(page: number) => setCurrentPage(page)}
                    siblingCount={1}
                    handleOnSelect={handleOnSelect}
                    text="Show Transactions"
                />
            </div>
        </div>
    );
};
