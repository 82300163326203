import React, { useState } from 'react';
import { deleteRule, duplicateRule, updateRule } from 'services/api/api';
import { showToast } from 'data/utils/toast';
import { useNavigate } from 'react-router-dom';
import SvgIcon from '../Icon/SvgIcon';
import Switch from '../Switch/Switch';

export const GroupRuleCard: React.FC<any> = ({
    item,
    dragHandleProps,
    commonProps,
}) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const [active, setIsActive] = useState(item?.isActive);
    const { getRuleData } = commonProps;

    const navigate = useNavigate();
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
    };
    const handleActive = () => {
        setIsActive(!active);
        updateRule({ isActive: !active }, item?._id)
            .then((res: any) => {
                showToast(res?.message, 'success');
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };
    const handleRuleDuplicate = () => {
        duplicateRule(item?._id)
            .then((res: any) => {
                showToast(res?.message, 'success');
                getRuleData();
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    const handleRuleDelete = () => {
        deleteRule(item?._id)
            .then((res: any) => {
                showToast(res?.message, 'success');
                getRuleData();
            })
            .catch((err: any) => {
                showToast(
                    err?.errors?.[0]?.message || 'something went wrong',
                    'error'
                );
            });
    };

    return (
        <div
            className={`border-solid transition-transform ${
                active ? 'border-[#BDD864]' : 'border-[#A4A4A4]'
            } rounded-2xl m-4`}
        >
            <div
                className={` ${
                    active ? 'bg-[#BDD864]' : 'bg-[#A4A4A4]'
                } rounded-t-xl h-14 flex items-center justify-between px-5`}
            >
                <span className="flex items-center">
                    <div
                        className="cursor-grab"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <span
                            className="cursor-grab"
                            onTouchStart={(e: any) => {
                                e.preventDefault();
                                document.body.style.overflow = 'hidden';
                                onTouchStart(e);
                            }}
                            onMouseDown={(e: any) => {
                                document.body.style.overflow = 'hidden';
                                onMouseDown(e);
                            }}
                            onTouchEnd={() => {
                                document.body.style.overflow = 'visible';
                            }}
                            onMouseUp={() => {
                                document.body.style.overflow = 'visible';
                            }}
                        >
                            <SvgIcon
                                icon="BIG_DRAG_ICON"
                                className={`pr-2 mt-1 ${
                                    active
                                        ? 'stroke-[#2E672F]'
                                        : 'stroke-[#383838]'
                                } `}
                            />
                        </span>
                    </div>
                    <p
                        className={`font-bold text-sm ps-2 ${
                            active ? 'text-black' : 'text-[#383838]'
                        }`}
                    >
                        {item?.name}
                    </p>
                </span>
                <div
                    className="flex items-center gap-2 font-bold cursor-pointer"
                    onClick={() => {
                        navigate(
                            `/admin/rule-engine/rule-builder?dataId=${
                                item?._id as string
                            }`
                        );
                    }}
                >
                    <div
                        className={`text-xs ${
                            active ? 'text-[#2E672F]' : 'text-[#383838]'
                        }  `}
                    >
                        View Rule
                    </div>
                    <SvgIcon
                        icon="ARROW_LEFT"
                        className={`stroke-2 ${
                            active ? 'stroke-[#2E672F]' : 'stroke-[#383838]'
                        }  -mt-0.5`}
                    />
                </div>
            </div>
            <div className="bg-white rounded-b-2xl h-14 flex items-center justify-between">
                <span className="flex flex-wrap items-center text-xs gap-x-3 pl-5">
                    <div>
                        No. of conditions{' '}
                        <span
                            className={`${
                                active ? 'text-[#8FB131]' : 'text-[#C8C8C8]'
                            }`}
                        >
                            {item?.conditions.length}
                        </span>
                    </div>
                    <div>
                        Creator:{' '}
                        <span
                            className={`${
                                active ? 'text-[#8FB131]' : 'text-[#C8C8C8]'
                            }`}
                        >
                            {item?.createdBy?.name}
                        </span>
                    </div>
                    <div>
                        Created:{' '}
                        <span
                            className={`${
                                active ? 'text-[#8FB131]' : 'text-[#C8C8C8]'
                            }`}
                        >
                            {formatDate(item?.createdAt)}
                        </span>
                    </div>
                    <div>
                        Last Updated:{' '}
                        <span
                            className={`${
                                active ? 'text-[#8FB131]' : 'text-[#C8C8C8]'
                            }`}
                        >
                            {formatDate(item?.updatedAt)}
                        </span>
                    </div>
                </span>
                <span className="text-xs flex flex-wrap items-center pr-5 gap-x-3">
                    <span
                        className="flex items-center gap-1.5 cursor-pointer"
                        onClick={() => setIsActive(!active)}
                    >
                        <div>Active </div>
                        <Switch
                            className={`rounded-2xl ${
                                active ? 'bg-[#deecaa]' : 'bg-[#E3E3E3]'
                            } `}
                            isRuleEngine
                            isToggled={active}
                            handleCheck={handleActive}
                        />
                    </span>
                    <span onClick={handleRuleDelete} className="cursor-pointer">
                        <SvgIcon
                            icon="DELETE_ICON_2"
                            className={`stroke-2 ${
                                active
                                    ? 'stroke-[#8FB131]'
                                    : ' stroke-[#C8C8C8]'
                            }  ps-1`}
                        />
                    </span>
                    <span
                        onClick={handleRuleDuplicate}
                        className="cursor-pointer flex items-center gap-1"
                    >
                        <div>Duplicate</div>
                        <SvgIcon
                            icon="COPY_ICON"
                            className={`stroke-2 ${
                                active
                                    ? 'stroke-[#8FB131]'
                                    : ' stroke-[#C8C8C8]'
                            }`}
                        />
                    </span>
                </span>
            </div>
        </div>
    );
};
